// ------------- import external dependencies -----------
import React from "react";
import PropTypes from "prop-types";

// ----------- import internal dependencies -----------
import "./skiptocontent.css";

const SkipToContent = ({ content = "main" }) => (
  <>
    <a className="skip" href={`#${content}`} aria-label="Skip to Navigation">
      Skip to Navigation
    </a>
  </>
);

export default SkipToContent;

SkipToContent.propTypes = {
  content: PropTypes.string,
};

import {FormattedMessage} from 'react-intl';
import authLayoutStyles from './index.module.css';
import React from 'react'
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import {
  BrowserRouter as Router,
  NavLink,
  Switch
} from "react-router-dom";

function AuthLayout({children}) {
  return (
    <>
      <div className={authLayoutStyles.authLayout}>
        <div className={authLayoutStyles.authLayout__wrapper}>
          <div className={authLayoutStyles.authLayout__content}>
              <AuthNavbar />

              <div className={authLayoutStyles.authFormContainer}>
                  <div className={authLayoutStyles.tabLinks}>
                    <ul>
                      <li>
                        <NavLink className={({ isActive }) => (isActive ? authLayoutStyles.active : 'inactive')} to={'/register'} >{<FormattedMessage id="auth.createAccount"/>}</NavLink>
                      </li>
                      <li>
                        <NavLink className={({ isActive }) => (isActive ? authLayoutStyles.active : 'inactive')} to={'/login'} >{<FormattedMessage id="auth.login"/>}</NavLink>
                      </li>
                    </ul>
                  </div>

                  <div className={authLayoutStyles.tabContent}>
                    {children}
                  </div>
              </div>
          </div>
          <div className={authLayoutStyles.authLayout__bg}></div>
        </div>

      </div>
    </>
  )
}

export default AuthLayout

export const getValueObject = (arr, value) => {
  const valObj = arr ? arr.find((obj) => obj.value === value) : "";
  return valObj;
};

export const formatCurrency = (value) => {
  if (value >= 0) {
    const formatter = new Intl.NumberFormat("en-Us", {
      style: "currency",
      currency: "USD"
    });

    return formatter.format(value);
  }
};

export const formatTaxId = (value) => {
  let formatted = value;
  const dash = value.slice(2, 3);
  if (dash && dash !== "-") {
    formatted = `${value.slice(0, 2)}-${value.slice(2)}`;
  }
  return formatted;
};

export const formatSSN = (value) => {
  let formatted = value;
  const dash = value.slice(3, 4);
  if (dash && dash !== "-") {
    formatted = `${value.slice(0, 3)}-${value.slice(3)}`;
  }

  const secondDash = value.slice(6, 7);
  if (secondDash && secondDash !== "-") {
    formatted = `${formatted.slice(0, 6)}-${formatted.slice(6)}`;
  }
  return formatted;
};

// ------------- google autocomplet location helpers -----------
/**
 * Handle load google maps api script and works for other third party script loading
 * @param {string} url
 * @param {function} callback
 */
export const loadScript = (url, callback) => {
  let script = document.createElement("script");
  script.type = "text/javascript";

  if (script.readyState) {
    script.onreadystatechange = function () {
      if (script.readyState === "loaded" || script.readyState === "complete") {
        script.onreadystatechange = null;
        callback();
      }
    };
  } else {
    script.onload = () => callback();
  }

  script.src = url;
  document.getElementsByTagName("head")[0].appendChild(script);
};

/**
 * Intialize google place Autocomplete feature
 * @param {globalVar} object
 * @param {function} updateQuery
 * @param {HTMLInputElement} autoCompleteRef
 */
export const handleScriptLoad = (globalVar, updateQuery, autoCompleteRef) => {
  globalVar = new window.google.maps.places.Autocomplete(autoCompleteRef, {
    // types: ["(cities)", "(regions)"],
    componentRestrictions: { country: "us" }
  });

  globalVar.setFields(["address_components", "formatted_address"]);
  globalVar.addListener("place_changed", () =>
    handlePlaceSelect(globalVar, updateQuery)
    
  );
};

/**
 * Get selected place information
 * @param {string} globalVar
 * @param {function} updateQuery
 */
export const handlePlaceSelect = async (globalVar, updateQuery) => {
  const addressObject = globalVar.getPlace();
  const query = addressObject.formatted_address;
  updateQuery(query);
  return addressObject;
  // console.log(addressObject);
};


export const loadGMapsScript = (autoComplete, updateAddressField, autoCompleteRef) => {
    const gmapsScriptURL = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API}&libraries=places`;
    // if (document.querySelectorAll(`[src="${gmapsScriptURL}"]`).lengh < 1) {
      loadScript(
      gmapsScriptURL,
      () =>
        handleScriptLoad(
          autoComplete,
          updateAddressField,
          autoCompleteRef.current.input
        )
      );
    // }
  }

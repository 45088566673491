import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getAllConsumersStart = () => ({
  type: types.GET_ALL_CONSUMERS_START
});

export const getAllConsumersSuccess = payload => ({
  type: types.GET_ALL_CONSUMERS_SUCCESS,
  payload
});

export const getAllConsumersFail = payload => ({
  type: types.GET_ALL_CONSUMERS_FAIL,
  payload
});

export const getAllConsumersCleanup = () =>({
  type: types.GET_ALL_CONSUMERS_CLEANUP
});

export const getAllConsumers = () => async dispatch => {
  try {

    dispatch(getAllConsumersStart());
    const requestObj = {
      path: "consumers",
      method: "GET",
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(getAllConsumersSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getAllConsumersFail(error));
  }
}

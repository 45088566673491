import React, { useEffect, useState } from "react";
import ApplicationDesktop from './applicationDesktop';
import ApplicationMobile from './applicationMobile';

function ApplicationLayout({children}) {

  const [width, setWidth] = useState(window.innerWidth);
  const MOBILE_MIN_SCREEN = 768;

  function handleWindowSizeChange() {
      setWidth(window.innerWidth);
  }
  useEffect(() => {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
          window.removeEventListener('resize', handleWindowSizeChange);
      }
  }, []);

  let appScreen;
  if(width <= MOBILE_MIN_SCREEN) {
    appScreen = <ApplicationMobile>{children}</ApplicationMobile>
  } else {
    appScreen = <ApplicationDesktop>{children}</ApplicationDesktop>
  }

  return (
    <>
      {appScreen}
    </>
  )
}

export default ApplicationLayout

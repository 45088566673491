import React, { useEffect, useState } from "react";
import logoImage from "../../assets/img/logo.svg";
import { Menu, Dropdown } from "antd";
import {
  CaretDownFilled,
  MoreOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import appStyles from "./ApplicationNavbar.module.css";
import applicationLayoutStyles from "../Layouts/AuthLayout/application.module.css";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

//actions
import {
  getPersonalData,
  getPersonalDataCleanup,
} from "../../store/actions/get-personal-data";
import { getAllNavMenus } from '../../store/actions/get-all-nav-menus';

const menu = (
  <Menu>
    <Menu.Item>
      <NavLink to={"/logout"}>Log Out</NavLink>
    </Menu.Item>
  </Menu>
);

function AuthNavbar() {
  const [isToggled, setMenu] = useState(false);
  const getPersonalDataState = useSelector((s) => s.getPersonalData);
  const dispatch = useDispatch();
  const [data, setData] = useState(null)
  function toggleMenu() {
    setMenu(!isToggled);
  }

  useEffect(() => {

    dispatch(getPersonalData());

}, []);
useEffect(() => {
     if (getPersonalDataState.isSuccessful) {
        // toggleMenu();
        // setData(getAllNavMenus(getPersonalDataState.data))
        setData(getPersonalDataState.data)
        console.log(data);
        console.log(document?.getElementById("list_sm")?.childElementCount)
        dispatch(getAllNavMenus(document?.getElementById("list_sm")?.childElementCount));
        dispatch(getPersonalDataCleanup());
    }

}, [getPersonalDataState])

  const mobileSidebar = () => {
    if (isToggled) {
      return (
        <div className={applicationLayoutStyles.appSidebarMobileWrapper}>
          <div className={applicationLayoutStyles.appSidebar}>
            <div className={applicationLayoutStyles.appSidebarBox}>
              <div className={applicationLayoutStyles.appSidebarBoxList}>
                <ul id="list_sm">
                  <li>
                        <NavLink className={({ isActive }) => (isActive ? applicationLayoutStyles.listLinkActive : 'inactive')} to='/register/personal' state={{ applicantType : data&&data.Applicant_Type__c }}><span>Personal Information</span></NavLink>
                      </li>
                      <li>
                        <NavLink className={({ isActive }) => (isActive ? applicationLayoutStyles.listLinkActive : 'inactive')}  to='/register/emergency' state={{ applicantType : data&&data.Applicant_Type__c }}><span>Emergency Contact</span></NavLink>
                      </li>
                      {data&&data.Applicant_Type__c==="HHA"? <> 
                      <li>
                        <NavLink className={({ isActive }) => (isActive ? applicationLayoutStyles.listLinkActive : 'inactive')}  to='/register/education' state={{ applicantType : data&&data.Applicant_Type__c }}><span>Education Information</span></NavLink>
                      </li>
                      <li>
                        <NavLink className={({ isActive }) => (isActive ? applicationLayoutStyles.listLinkActive : 'inactive')}  to='/register/employment' state={{ applicantType : data&&data.Applicant_Type__c }}><span>Employment Information</span></NavLink>
                      </li>
                      </>:null}
                      {data&&data.Applicant_Type__c==="CDPAP"? <> 
                      <li>
                        <NavLink className={({ isActive }) => (isActive ? applicationLayoutStyles.listLinkActive : 'inactive')}  to='/register/consumer' state={{ applicantType : data&&data.Applicant_Type__c }}><span>Consumer Information</span></NavLink>
                      </li>
                      </>:null}
                     {data&&data.Applicant_Type__c==="HHA"? <> 
                      <li>
                        <NavLink className={({ isActive }) => (isActive ? applicationLayoutStyles.listLinkActive : 'inactive')}  to='/register/test' state={{ applicantType : data&&data.Applicant_Type__c }}><span>Employment Test</span></NavLink>
                      </li> 
                      <li>
                        <NavLink className={({ isActive }) => (isActive ? applicationLayoutStyles.listLinkActive : 'inactive')}  to={true  ? '/register/caregiver' : ''} state={{ applicantType : data&&data.Applicant_Type__c }}>
                          <span>Application for Employment</span>
                        </NavLink>
                      </li> 
                      </>:null}
                      <li>
                        <NavLink className={({ isActive }) => (isActive ? applicationLayoutStyles.listLinkActive : 'inactive')}  to='/register/documents' state={{ applicantType : data&&data.Applicant_Type__c }}><span>Document Uploads</span></NavLink>
                      </li>
                  <li
                    onClick={toggleMenu}
                    className={applicationLayoutStyles.appSidebarMobileClose}
                  >
                    <CloseOutlined />
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      );
    }
  };

  return (
    <>
      <div className={appStyles.topNav}>
        <nav>
          <ul>
            <li>
              <img src={logoImage} />
            </li>
          </ul>
          <ul>
            <li>
              <Dropdown overlay={menu}>
                <a
                  className="ant-dropdown-link"
                  onClick={(e) => e.preventDefault()}
                >
                  <MoreOutlined style={{ fontSize: "24px", color: "#08c" }} />
                </a>
              </Dropdown>
            </li>
          </ul>
        </nav>
      </div>
      <div className={appStyles.bottomNav}>
        <nav>
          <ul>
            <li onClick={toggleMenu}>
              <b>
                {"Application Menu"} <CaretDownFilled />
              </b>
            </li>
          </ul>
        </nav>
        {mobileSidebar()}
      </div>
    </>
  );
}

export default AuthNavbar;

import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getAllRequiredDocumentsStart = () => ({
  type: types.GET_ALL_REQUIRED_DOCUMENTS_START
});

export const getAllRequiredDocumentsSuccess = payload => ({
  type: types.GET_ALL_REQUIRED_DOCUMENTS_SUCCESS,
  payload
});

export const getAllRequiredDocumentsFail = payload => ({
  type: types.GET_ALL_REQUIRED_DOCUMENTS_FAIL,
  payload
});

export const getAllRequiredDocumentsCleanup = () =>({
  type: types.GET_ALL_REQUIRED_DOCUMENTS_CLEANUP
});

export const getAllRequiredDocuments = () => async dispatch => {
  try {

    dispatch(getAllRequiredDocumentsStart());
    const requestObj = {
      path: "requireddocument",
      method: "GET",
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(getAllRequiredDocumentsSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getAllRequiredDocumentsFail(error));
  }
}

import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getSingleEmergencyContactStart = () => ({
  type: types.GET_SINGLE_EMERGENCY_CONTACT_START
});

export const getSingleEmergencyContactSuccess = payload => ({
  type: types.GET_SINGLE_EMERGENCY_CONTACT_SUCCESS,
  payload
});

export const getSingleEmergencyContactFail = payload => ({
  type: types.GET_SINGLE_EMERGENCY_CONTACT_FAIL,
  payload
});

export const getSingleEmergencyContactCleanup = () =>({
  type: types.GET_SINGLE_EMERGENCY_CONTACT_CLEANUP
});

export const getSingleEmergencyContact = emergencycontactId => async dispatch => {
  try {

    dispatch(getSingleEmergencyContactStart());
    const requestObj = {
      path: `emergencycontacts/${emergencycontactId}`,
      method: "GET",
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(getSingleEmergencyContactSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getSingleEmergencyContactFail(error));
  }
}

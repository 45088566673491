import * as types from '../../action-types'
import { verify_otp as initialState } from 'store/initialState'

const verify_otp = (state=initialState, action)=>{
    switch (action.type){
        case types.VERIFY_OTP_START:
            return {
                ...state,
                isLoading: true
            };
        case types.VERIFY_OTP_SUCCESS:
           
            return {
                ...state,
                isLoading: false,
                isSuccessful: true,
                data: action.payload
            };
        case types.VERIFY_OTP_FAIL:
         
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case types.VERIFY_OTP_CLEANUP:
            return {
                ...state,
                isLoading: false,
                isSuccessful: false,
                error: null
            };
        default:
            return state;
    }
}

export default verify_otp
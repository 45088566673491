import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const updateEmploymentStart = () => ({
  type: types.UPDATE_EMPLOYMENT_START
});

export const updateEmploymentSuccess = payload => ({
  type: types.UPDATE_EMPLOYMENT_SUCCESS,
  payload
});

export const updateEmploymentFail = payload => ({
  type: types.UPDATE_EMPLOYMENT_FAIL,
  payload
});

export const updateEmploymentCleanup = () =>({
  type: types.UPDATE_EMPLOYMENT_CLEANUP
});

export const updateEmployment = (employmentId, payload) => async dispatch => {
  try {

    dispatch(updateEmploymentStart());
    const requestObj = {
      path: `experiences/${employmentId}`,
      method: "PATCH",
      data: payload,
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(updateEmploymentSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(updateEmploymentFail(error));
  }
}

// ------------ import external dependencies -------------
import React, { useEffect, useState } from "react";
import { Form, Button, Row, Col, Modal, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { UnorderedListOutlined, AppstoreOutlined, CommentOutlined } from "@ant-design/icons";
import { FileUploader } from "react-drag-drop-files";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { Table, Tooltip } from "antd";
import Notification from "../../../../utils/notification";

// ------------ import internal dependencies ------------
import ApplicationLayout from "components/Layouts/AuthLayout/application";
// ------ style files ------
import applicationLayoutStyles from "components/Layouts/AuthLayout/application.module.css";
// import  "../applicant-test/testapplication.scoped.css"
import "./style.scoped.css"

// ------ actions ------
import { FormattedMessage, useIntl } from "react-intl";

//actions
import {
  getAllRequiredDocuments,
  getAllRequiredDocumentsCleanup,
} from "../../../../store/actions/get-all-required-documents";
import {
  uploadFile,
  uploadFileCleanup,
} from "../../../../store/actions/upload-file";
import {
  getSingleRequiredDocument,
  getSingleRequiredDocumentCleanup,
} from "../../../../store/actions/get-single-required_document";
import {
  getSingleUpload,
  getSingleUploadCleanup,
} from "../../../../store/actions/get-single-upload";

//------ utils -----
import { navDict } from "../../../../utils/helpers/data";
import Loader from "../../../../components/Loader";
const fileTypes = ["JPEG", "JPG", "PNG", "GIF", "PDF", "HEIC"];


const RegisterDocuments = () => {
  const location = useLocation();
  const appType = location.state?.applicantType;
  const intl = useIntl()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const navAccessState = useSelector(s=>s.navAccess)
  const getAllRequiredDocumentsState = useSelector(
    (s) => s.getAllRequiredDocuments
  );
  const getSingleRequiredDocumentsState = useSelector(
    (s) => s.getSingleRequiredDocument
  );
  const getSingleUploadsState = useSelector((s) => s.getSingleUpload);
  const uploadFileState = useSelector((s) => s.uploadFile);
  const user = useSelector((s) => s.getPersonalData.data);
  // ------- component state managers --------
  const [docs, setDocs] = useState([]);
  const [singleDoc, setSingleDoc] = useState({});
  const [visible, setVisible] = useState(false);
  const [files, setFiles] = useState([]);
  const [display, setDisplay] = useState("grid");

  const handleChange = (file, docId) => {
    var formData = new FormData();
    let reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = function () {
      for (let x = 0; x < file.length; x++) {
        formData.append("doc", file[x]);
      }
      dispatch(uploadFile(formData, docId));
    };
  };
  const openRequiredDocument = (docId) => {
    dispatch(getSingleRequiredDocument(docId));
  };

  const onDrop = (file, docId) => {
    var formData = new FormData();
    let reader = new FileReader();
    reader.readAsDataURL(file[0]);
    reader.onload = function () {
      for (let x = 0; x < file.length; x++) {
        formData.append("doc", file[x]);
      }
      dispatch(uploadFile(formData, docId));
    };
  };
  const onSelect = (fileOrFiles) => {
  };

  const onSizeError = (err = 1) => console.log(err);

  const statusList = {
    "Waiting for review by Royal Care": ["uploads.status.choices.waitingForReviewByRC", "pending"],
    "Approved": ["uploads.status.choices.approved", "approved"],
    "Approved - Uploaded on other Record": ["uploads.status.choices.approvedUploadedOnOtherRecord", "approved"],
    "Upload Needed": ["uploads.status.choices.uploadNeeded", "pending"],
    "Rejected-New Upload Needed": ["uploads.status.choices.rejectedNewUploadNeeded", "rejected"]
  }



  useEffect(() => {
    if(user?.Can_Go_To_Documents_Uploads__c===false && navAccessState?.Can_Go_To_Documents_Uploads__c){
      Notification(
        'Access denied',
        "warning",
        `You are not yet allowed to access this section. Please make sure to complete all previous sections`
      );
      return navigate('/')
    }
    document.title = `${intl.formatMessage({id:"web.websiteTitle"})}-${intl.formatMessage({id:'employmentPage.uploads'})}`
    window.scrollTo(0,0)
    dispatch(getAllRequiredDocuments());
  }, [intl, user]);

  useEffect(() => {
    if (uploadFileState.isSuccessful) {
      setVisible(false);
      dispatch(getAllRequiredDocuments());
      dispatch(uploadFileCleanup());
    }
    else if (uploadFileState.error) {
      message.error(intl.formatMessage({id:'notification.uploads.failed'}));
      dispatch(uploadFileCleanup());
    }
  }, [uploadFileState]);

  // ------- get all requiried documents state --------
  // TODO: Refactor get all required document with Promise.all instead of foreach
  useEffect(() => {
    if (getAllRequiredDocumentsState.isSuccessful) {
      getAllRequiredDocumentsState.data.forEach((element) => {
        dispatch(getSingleUpload(element.Id));
      });
      setDocs(getAllRequiredDocumentsState.data);
      dispatch(getAllRequiredDocumentsCleanup());
    } else if (getAllRequiredDocumentsState.error) {
      dispatch(getAllRequiredDocumentsCleanup());
    }
  }, [getAllRequiredDocumentsState]);

  useEffect(() => {
    if (getSingleRequiredDocumentsState.isSuccessful) {
      setSingleDoc(getSingleRequiredDocumentsState.data);
      setVisible(true);
      dispatch(getSingleRequiredDocumentCleanup());
    } else if (getSingleRequiredDocumentsState.error) {
      dispatch(getSingleRequiredDocumentCleanup());
    }
  }, [getSingleRequiredDocumentsState]);
  useEffect(() => {
    if (getSingleUploadsState.isSuccessful) {
      setFiles([...files, getSingleUploadsState.data]);
      dispatch(getSingleUploadCleanup());
    } else if (getSingleUploadsState.error) {
      dispatch(getSingleUploadCleanup());
    }
  }, [getSingleUploadsState]);

  const columns = [
    {
      title: <FormattedMessage id="uploads.tableHeaders.documentTitle" />,
      dataIndex: "Name",
      key: "Name"
    },
    {
      title: <FormattedMessage id="uploads.tableHeaders.type" />,
      dataIndex: "Type__c",
      key: "Type__c"
    },
    {
      title: "FILES",
      dataIndex: "Number_of_Attachments__c",
      key: "Number_of_Attachments__c",
      render: (row, record) => (
        <span>{record?.Number_of_Attachments__c ? 0 : record.Number_of_Attachments__c}</span>
      )
    },
    {
      title:  <FormattedMessage id="uploads.tableHeaders.status" />,
      dataIndex: "Status_for_Portal_Display__c",
      key: "Status_for_Portal_Display__c",
      render: (row, record) => (
        <span className={`file-status file-status--${statusList[record.Status_for_Portal_Display__c][1]}`}>{<FormattedMessage id={statusList[record.Status_for_Portal_Display__c][0]} />}</span>
      )
    },
    {
      title:  <FormattedMessage id="uploads.tableHeaders.rccomments" />,
      dataIndex: "Comments__c",
      key: "Comments__c",
      render: (row, record) => (
        record?.Comments__c && <span style={{ textAlign: "center", display: "block" }}>
          <Tooltip title={record?.Comments__c}>
            <CommentOutlined style={{ color: '#f00' }}/>
          </Tooltip>
        </span>
      )
    },
    {
      title:   <FormattedMessage id="uploads.table.actions" />,
      dataIndex: "action",
      key: "action",
      render: (row, record) => (
        <div className="document-actions">
          <Button
            disabled={getSingleRequiredDocumentsState.isLoading}
            type="text" size="sm"
            onClick={() => {
              // console.log(row, record);
              // setVisible(true)
              openRequiredDocument(record.Id)
            }} color="" className="upload-btn"> <FormattedMessage id="nav.upload" />
          </Button>
          {record.Number_of_Attachments__c >= 1 && (
            <Button type="text" size="sm" className="view-btn" onClick={() => navigate(`/register/preview/${record.Id}`)}>
              <FormattedMessage id="nav.view" />
            </Button>)}
        </div>
      )
    }

  ]

  let stepNum = navDict[appType]?.['steps']?.indexOf(location.pathname) + 1;
  let totalStepsCount = navDict[appType]?.['totalStepsCount'];

  return (
    <div>
      <ApplicationLayout>
        <div className={applicationLayoutStyles.mainContent}>
          <span className="upload-guide">Please select image from your library</span>
          {appType ? <span>
              <FormattedMessage id="nav.step" values={{stepNum,totalStepsCount}} /> :
            </span>
            : null } <span>{<FormattedMessage id="profile.pageHeader" />}
          </span>
          <div className="display-type">
            <h2>{<FormattedMessage id="uploads.pagePrompt" />}</h2>

            {/* -------- document view format -------- */}
            <div className="display-wrap">
              <div className={display === "list" ? "active-display" : "format-type"
              } onClick={() => setDisplay("list")}>
                <UnorderedListOutlined className={display === "list" ? "icon-active" : "icon-display"} />
              </div>
              <div className={display === "grid" ? "active-display" : "format-type"
              } onClick={() => setDisplay("grid")}>
                <AppstoreOutlined className={display === "grid" ? "icon-active" : "icon-display"} />
              </div>
            </div>
          </div>

          {/* ------- Handle table grid display ------- */}
          {display === "grid" && <Form
            name="line"
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
          >
            <Loader
              style={{ minHight: "100vh" }}
              text="loading.."
              isLoading={getAllRequiredDocumentsState.isLoading}
            >
              {/* ------- document list boxes ------- */}
              <Row gutter={[16, 16]}>
                {docs?.map((doc) => (
                  <Col key={doc.Id} xs={12} sm={24} xl={6}>

                    {/* -------- document upload  boxes -------- */}
                    <Form.Item>
                      {/* <Loader
                        isLoading={getSingleRequiredDocumentsState.isLoading}
                      > */}
                      <Form.Item name="dragger" noStyle>
                        <div
                          className={applicationLayoutStyles.imgSpace}
                        >
                          {/* <p className="ant-upload-drag-icon">
                              <PlusCircleOutlined
                                className={applicationLayoutStyles.uploadIcon}
                              />
                            </p> */}
                          {doc.Comments__c && <div>
                            <span style={{ textAlign: "right", display: "block", fontSize: "1.65rem", paddingRight: "1rem" }}>
                              <Tooltip title={doc.Comments__c}>
                                <CommentOutlined style={{ color: '#f00' }}/>
                              </Tooltip>
                            </span>
                          </div>}
                          <div 
                          className="document-content" 
                          style={{marginTop: doc.Comments__c ? "0rem" : "2.5rem"}}
                          >
                          <div style={{height: "6.75rem"}}>
                            <b className="ant-ubload-text">{doc.Name_in_Applicant_Language__c ?? doc.Name}</b>
                            <p className="ant-upload-hint upload-count" >(
                              {doc.Number_of_Attachments__c ? doc.Number_of_Attachments__c : 0 + " "}
                              {doc.Number_of_Attachments__c && doc.Number_of_Attachments__c > 1 ? "files" : "file"})
                            </p>
                          </div>
                            <p className={`file-status file-status--${statusList[doc.Status_for_Portal_Display__c][1]}`}>
                              {/*{statusList[doc.Status_for_Portal_Display__c][0]}*/}
                              {<FormattedMessage id={statusList[doc.Status_for_Portal_Display__c][0]} />}
                            </p>
                          </div>
                          <div className="document-actions">
                            <Button
                              disabled={getSingleRequiredDocumentsState.isLoading}
                              type="text" size="sm"
                              onClick={() => openRequiredDocument(doc.Id)} color="" className="upload-btn">
                              {<FormattedMessage id="nav.upload" />}
                            </Button>
                            {doc.Number_of_Attachments__c >= 1 && (
                              <Button type="text" size="sm" className="view-btn" onClick={() => navigate(`/register/preview/${doc.Id}`)}>
                                {<FormattedMessage id="nav.view" />}
                              </Button>)}
                          </div>
                        </div>
                      </Form.Item>
                      {/* </Loader> */}
                    </Form.Item>
                  </Col>
                ))}
              </Row>
            </Loader>
          </Form>}

          {/* -------- Handle list display format ------- */}
          {display === "list" && <Table dataSource={docs} columns={columns} />}

           {/* ------- document actions ------- */}
           <div className={applicationLayoutStyles.formFooter}>
              <div className={applicationLayoutStyles.formPrevious}>
                <Button
                  onClick={() => navigate( navDict[appType]['steps'][navDict[appType]['steps'].indexOf(location.pathname) - 1],{state:{ applicantType : appType }})}
                  type="primary"
                  htmlType="submit"
                >
                  {<FormattedMessage id="nav.previous" />}
                </Button>
              </div>
              <div className={applicationLayoutStyles.formSave}>
                <Button type="primary" onClick={() => navigate("/dashboard")}>
                  {<FormattedMessage id="nav.continueLater" />}
                </Button>
                <Button
                  type="primary"
                  onClick={() => navigate("/dashboard")}
                  htmlType="submit">
                  {<FormattedMessage id="nav.finish" />}
                </Button>
              </div>
            </div>

          {/* ------ modal popup to upload documents ------- */}
          <Modal
            title={singleDoc.Name}
            centered
            visible={visible}
            onOk={() => setVisible(false)}
            onCancel={() => setVisible(false)}
            footer={null}
          >
            <Loader
              text={intl.formatMessage({id:'ui.state.uploading'})}
              isLoading={uploadFileState.isLoading}
            >
              <div>
                <FileUploader
                  multiple={true}
                  handleChange={(file) =>
                    handleChange(file, singleDoc.Id)
                  }
                  name="file"
                  types={fileTypes}
                  onSizeError={onSizeError}
                  onDrop={(file) => onDrop(file, singleDoc.Id)}
                  onSelect={onSelect}
                />
              </div>
            </Loader>
          </Modal>
        </div>
      </ApplicationLayout>
    </div>
  );
};

export default RegisterDocuments;

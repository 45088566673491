import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const updateLanguageStart = () => ({
  type: types.UPDATE_LANGUAGE_START
});

export const updateLanguageSuccess = payload => ({
  type: types.UPDATE_LANGUAGE_SUCCESS,
  payload
});

export const updateLanguageFail = payload => ({
  type: types.UPDATE_LANGUAGE_FAIL,
  payload
});

export const updateLanguageCleanup = () =>({
  type: types.UPDATE_LANGUAGE_CLEANUP
});

export const updateLanguage = (languageId, payload) => async dispatch => {
  try {

    dispatch(updateLanguageStart());
    const requestObj = {
      path: `language/${languageId}`,
      method: "PATCH",
      data: payload,
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(updateLanguageSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(updateLanguageFail(error));
  }
}

// ------------- import internal dependencies -----------
import * as types from "../../action-types";
import { getApplicationStage as intialState } from "store/initialState";

export const getApplicantStage = (state = intialState, action) => {
  switch (action.type) {
    case types.GET_APPLICANT_APPLICATION_START:
      return {
        ...state,
        isLoading: true,
      }
    case types.GET_APPLICANT_APPLICATION_SUCCESS:
      return{
        ...state,
        data: action.payload,
        isLoading: false,
        isSuccessful: true,
      }
    case types.GET_APPLICANT_APPLICATION_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      }
    case types.GET_APPLICANT_APPLICATION_CLEANUP:
      return {
        ...state,
        isLoading: false,
        isSuccessful: false,
        error: null,
      }
    default:
      return state
  }
}
import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const deleteConsumerStart = () => ({
  type: types.DELETE_CONSUMER_START
});

export const deleteConsumerSuccess = payload => ({
  type: types.DELETE_CONSUMER_SUCCESS,
  payload
});

export const deleteConsumerFail = payload => ({
  type: types.DELETE_CONSUMER_FAIL,
  payload
});

export const deleteConsumerCleanup = () =>({
  type: types.DELETE_CONSUMER_CLEANUP
});

export const deleteConsumer = consumerId => async dispatch => {
  try {

    dispatch(deleteConsumerStart());
    const requestObj = {
      path: `consumers/${consumerId}`,
      method: "DELETE",
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(deleteConsumerSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(deleteConsumerFail(error));
  }
}

import React, { useState, useEffect } from 'react'
import ApplicationLayout from "components/Layouts/AuthLayout/application";
import applicationLayoutStyles from "components/Layouts/AuthLayout/application.module.css";
import { navDict } from "../../../../utils/helpers/data";
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage, useIntl } from 'react-intl';
import { SaveOutlined , SyncOutlined  } from "@ant-design/icons";
import styles from './styles/style.module.css'
import {
    Form,
    Input,
    Button,
    Row,
    Select,
    Col,
    message,
    DatePicker,
  } from "antd";
import Loader from "../../../../components/Loader";
import Notification from "../../../../utils/notification";
import LanguageForm from './form';

//store
import { useDispatch, useSelector } from 'react-redux';
import { addLanguage, addLanguageCleanup } from 'store/actions/add-language';
import { getAllLanguage, getAllLanguageCleanup } from 'store/actions/get-all-langauges';
import { deleteLanguage, deleteLanguageCleanup } from 'store/actions/delete-language';
import { getSingleLanguage, getSingleLanguageCleanup } from 'store/actions/get-single-language';
import { updateLanguage, updateLanguageCleanup } from 'store/actions/update-language';


const { Option } = Select;

const LanguagePage = ()=>{
    const dispatch = useDispatch()
    const intl = useIntl()
    const location =useLocation()
    const navigate = useNavigate()
    const [disabledButton, setDisabledButton] = useState(false)
    const appType = location.state?.applicantType ?? "HHA";
    let stepNum = navDict[appType]?.['steps']?.indexOf(location.pathname) + 1;
    let totalStepsCount = navDict[appType]?.['totalStepsCount'];
    const [form] = Form.useForm();
    const [newForm] = Form.useForm();
    const [allLanguages, setAllLanguages] = useState([])

    //redux states
    const user = useSelector((s) => s.getPersonalData.data);
    const addLanguageState = useSelector(state=>state.addLanguage)
    const getAllLanguageState = useSelector(state=>state.getAllLanguages)
    const deleteLanguageState = useSelector(state=>state.deleteLanguage)
    const getSingleLanguageState = useSelector(state=>state.getSingleLanguage)
    const updateLanguageState = useSelector(state=>state.updateLanguage)

    useEffect(()=>{
      if(user?.Can_Go_To_Related_Profile_Info__c===false){
        Notification(
          'Access denied',
          "warning",
          `You are not yet allowed to access this section. Please make sure to complete all previous sections`
        );
        return navigate('/')
      }
      document.title = document.title = `${intl.formatMessage({id:"web.websiteTitle"})}-${intl.formatMessage({id:'languages.pageHeader'})}`
        window.scrollTo(0,0)
        dispatch(getAllLanguage())
    },[intl ,user])

    useEffect(() => {
        if (addLanguageState.isSuccessful) {
            
          Notification(
            intl.formatMessage({id:'languages.language.add.success'}),
            "success",
            intl.formatMessage({id:'languages.language.add.success'})
          );
          form.resetFields();
          Reset()
          dispatch(getAllLanguage());
          dispatch(addLanguageCleanup());
        } else if (addLanguageState.error) {
          if (Array.isArray(addLanguageState.error)) {
            Notification(intl.formatMessage({id:'notification.failed'}), "error", addLanguageState.error[0].msg);
          } else Notification(intl.formatMessage({id:'notification.failed'}), "error", intl.formatMessage({id:'notification.emergencyContact.add.failed'}));
    
          dispatch(addLanguageCleanup());
        }
    }, [addLanguageState]);
    
    useEffect(() => {
        if (getAllLanguageState.isSuccessful) {
          setAllLanguages(getAllLanguageState.data.reverse())
          dispatch(getAllLanguageCleanup());
          console.log(addLanguageState);
        } else if (getAllLanguageState.error) {
          dispatch(getAllLanguageCleanup());
        }
    }, [getAllLanguageState]);

    useEffect(() => {
        if (deleteLanguageState.isSuccessful) {
          dispatch(getAllLanguage());
          dispatch(deleteLanguageCleanup());
        } else if (deleteLanguageState.error) {
          if (Array.isArray(deleteLanguageState.error)) {
            Notification(
              intl.formatMessage({id:"notification.failed"}),
              "error",
              deleteLanguageState.error[0].msg
            );
          } else
            Notification(intl.formatMessage({id:"notification.failed"}), "error",intl.formatMessage({id:'notification.language.delete.failed'}));
          dispatch(deleteLanguageCleanup());
        }
    }, [deleteLanguageState]);

    useEffect(() => {
        if (updateLanguageState.isLoading === true) {
          message.loading({
            content: intl.formatMessage({id:'ui.state.updating'}),
            key: "updatable",
            duration: 3,
          });
          dispatch(updateLanguageCleanup());
        }
        if (updateLanguageState.isSuccessful) {
          dispatch(getAllLanguage());
          dispatch(updateLanguageCleanup());
        } else if (updateLanguageState.error) {
          if (Array.isArray(updateLanguageState.error)) {
            Notification(
              intl.formatMessage({id:"notification.failed"}),
              "error",
              updateLanguageState.error[0].msg
            );
          } else
            Notification(intl.formatMessage({id:"notification.failed"}), "error", intl.formatMessage({id:'notification.language.update.failed'}));
          dispatch(updateLanguageCleanup());
        }
    }, [updateLanguageState]);

    useEffect(() => {
      if (getSingleLanguageState.isSuccessful) {
        newForm.setFieldsValue({
          Name: getSingleLanguageState.data.Name,
          School_Name__c: getSingleLanguageState.data.School_Name__c,
          Reading_capabilities__c: getSingleLanguageState.data.Reading_capabilities__c,
          Spoken_skill_level__c:getSingleLanguageState.data.Spoken_skill_level__c,
          Writing_capabilities__c:getSingleLanguageState.data.Writing_capabilites__c,
          Id: getSingleLanguageState.data.Id,
        });
        dispatch(getSingleLanguageCleanup());
      } else if (getSingleLanguageState.error) {
        if (Array.isArray(getSingleLanguageState.error)) {
          Notification(intl.formatMessage({id:'notification.failed'}), "error", getSingleLanguageState.error[0].msg);
        } else Notification(intl.formatMessage({id:'notification.failed'}), "error", intl.formatMessage({id:'notification.educationHistory.get.failed'}));
        dispatch(getSingleLanguageCleanup());
      }
    }, [getSingleLanguageState]);



    const deleteSingleLanguageFunc = (id) => {
        dispatch(deleteLanguage(id));
    };
    
    const getSingleLanguageFunc = (id) => {
        dispatch(getSingleLanguage(id));
    };
    

    const onChange = (e) => {
        const formValues = form.getFieldsValue();
        const {
          Name,
          Spoken_skill_level__c,
          Writing_capabilities__c,
          Reading_capabilities__c,
         
        } = formValues;
        if (
          Name?.length > 0 ||
          Spoken_skill_level__c?.length > 0 ||
          Writing_capabilities__c?.length > 0 ||
          Reading_capabilities__c?.length > 0 
        ) {
          setDisabledButton(true);
        } else {
          setDisabledButton(false);
        }
        //console.log(formValues);
      };

      const Reset = () => {
        form.resetFields();
        setDisabledButton(false);
    };

    const createLanguage = (values)=>{
        console.log(values)
        dispatch(addLanguage(values))
    }
    

    return(
        <ApplicationLayout>
            <div className={applicationLayoutStyles.mainContent}>
                {appType ? <span>
                <FormattedMessage id="nav.step" values={{stepNum,totalStepsCount}} /> : 
                </span>
                : null } 
                
                <h4 className={styles.prompt}><FormattedMessage id="languages.pagePrompt"/></h4>
                <h2> <FormattedMessage id="languages.enterLanguage" /> </h2>
                
                {" "}

                <Loader 
                    isLoading={addLanguageState.isLoading} 
                    text={<FormattedMessage id="ui.loading" />}
                >  
                <Form
                form={form}
                    name={"education"}
                    layout="vertical"
                    initialValues={{
                    remember: true,
                    }}
                    autoComplete="off"
                    onFinish={createLanguage}
                    onChange={onChange}
                >

                    <Row gutter={32}>
                        <Col span={12} xs={24} lg={12}>
                        <Form.Item
                            label={<FormattedMessage id="languages.pageHeader" />}
                            name="Name"
                            rules={[
                            {
                                required: true,
                                message: <FormattedMessage id="languages.languageIsRequired" />,
                            },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                        </Col>
                        <Col span={12} xs={24} lg={12}>
                        <Form.Item
                            label={<FormattedMessage id="languages.readingFluencyQuestion"/>}
                            name="Reading_capabilities__c"
                            rules={[
                            {
                                required: true,
                                message: <FormattedMessage id="languages.required" />,
                            },
                            ]}
                        >
                            <Select  allowClear>
                                <Option value="Fair">
                                    <FormattedMessage id="languages.fluencyOptions.fair" />
                                </Option>
                                <Option value="Well">
                                    <FormattedMessage id="languages.fluencyOptions.well" />
                                </Option>
                                <Option value="Fluent">
                                    <FormattedMessage id="languages.fluencyOptions.fluent" />
                                </Option>
                            </Select>
                        </Form.Item>
                        </Col>
                    </Row>
                    
                    <Row gutter={32}>
                        <Col span={12} xs={24} lg={12}>
                        <Form.Item
                            label={<FormattedMessage id="languages.speakingFluencyQuestion" />}
                            name="Spoken_skill_level__c"
                            rules={[
                            {
                                required: true,
                                message: <FormattedMessage id="languages.required" />,
                            },
                            ]}
                        >
                            <Select  allowClear>
                              <Option value="Fair">
                                    <FormattedMessage id="languages.fluencyOptions.fair" />
                                </Option>
                                <Option value="Well">
                                    <FormattedMessage id="languages.fluencyOptions.well" />
                                </Option>
                                <Option value="Fluent">
                                    <FormattedMessage id="languages.fluencyOptions.fluent" />
                                </Option>
                            </Select>
                        </Form.Item>
                        </Col>
                        <Col span={12} xs={24} lg={12}>
                        <Form.Item
                            label={<FormattedMessage id="languages.writingFluencyQuestion" />}
                            name="Writing_capabilities__c"
                            rules={[
                            {
                                required: true,
                                message:  <FormattedMessage id="languages.required" />,
                            },
                            ]}
                        >
                            <Select  allowClear>
                                <Option value="Fair">
                                    <FormattedMessage id="languages.fluencyOptions.fair" />
                                </Option>
                                <Option value="Well">
                                    <FormattedMessage id="languages.fluencyOptions.well" />
                                </Option>
                                <Option value="Fluent">
                                    <FormattedMessage id="languages.fluencyOptions.fluent" />
                                </Option>
                            </Select>
                        </Form.Item>
                        </Col>
                    </Row>
                    {disabledButton === true ? (
                        <div className={applicationLayoutStyles.addNewItemWrapper}>
                        <Button onClick={Reset} danger size="large" style={{ fontSize: '120%'}}>
                          <SyncOutlined  />{<FormattedMessage id="nav.reset" />}
                        </Button>
                        <Button type="primary" htmlType="submit" size="large" style={{ fontSize: '120%'}}>
                          <SaveOutlined  />{<FormattedMessage id="nav.save" />}
                        </Button>
                        </div>
                    ) : null}
                </Form>
                </Loader>
                <Loader
                    text={<FormattedMessage id="ui.loading" />}
                    isLoading={getAllLanguageState.isLoading}
                >
                  {allLanguages.map((a, i)=>
                    <LanguageForm
                        key={i}
                        updateForm={null}
                        updateLanguage={null}
                        formName={"Language"}
                        data={a}
                        disabled={true}
                        deleteLanguage={deleteSingleLanguageFunc}
                        deleteLanguageState={deleteLanguageState}
                        save={true}
                        getSingleLanguage={getSingleLanguageFunc}
                        openReturnData={getSingleLanguageState}
                        closeReturnData={updateLanguageState}
                        grouped={true}
                        UpdatedForm={
                            <LanguageForm
                              data={a}
                              disabled={false}
                              formType={newForm}
                              deleteLanguage={null}
                              deleteLanguageState={null}
                              save={false}
                              formName={"updateLanguage"}
                              dispatch={dispatch}
                              updateLanguage={updateLanguage}
                              closeReturnData={{
                                data: null,
                                error: null,
                                isLoading: false,
                                isSuccessful: false,
                              }}
                              openReturnData={null}
                              grouped={false}
                              defaultActiveKey={true}
                            />
                          }
                    />
                  )}

                </Loader>

                {disabledButton === false ? (
                    <div className={applicationLayoutStyles.formFooter}>
                    <div className={applicationLayoutStyles.formPrevious}>
                        <Button
                        onClick={() => navigate( navDict[appType]['steps'][navDict[appType]['steps'].indexOf(location.pathname) - 1],{state:{ applicantType : appType }})}
                        type="primary"
                        htmlType="submit"
                        >
                        {<FormattedMessage id="nav.previous" />}
                        </Button>
                    </div>
                    <div className={applicationLayoutStyles.formSave}>
                        <Button type="primary" 
                        onClick={() => navigate("/dashboard")}
                        >
                        {<FormattedMessage id="nav.continueLater" />}
                        </Button>
                        <Button
                        onClick={() => navigate( navDict[appType]['steps'][navDict[appType]['steps'].indexOf(location.pathname) + 1],{state:{ applicantType : appType }})}
                        type="primary"
                        htmlType="submit"
                        >
                        {<FormattedMessage id="nav.next" />}
                        </Button>
                    </div>
                    </div>
                ) : null}
            </div>
        </ApplicationLayout>
    )
}

export default LanguagePage
import { combineReducers } from "redux";
import login from "./login";
import language from './language'
import verify_otp from "./verify-otp";
import resend_otp from "./resend_otp";
import signUp from "./signUp";
import resetPassword from "./reset";
import getPersonalData from "./get-personal-data";
import updatePersonalData from "./update-personal-data";
import addEducation from "./add-education";
import getAllEducations from "./get-all-educations";
import deleteEducation from "./delete-education";
import updateEducation from "./update-education";
import getSingleEducation from "./get-single-education";
import addEmployment from "./add-employment";
import getAllEmployments from "./get-all-employments";
import deleteEmployment from "./delete-employment";
import updateEmployment from "./update-employment";
import getSingleEmployment from "./get-single-employment";
import addConsumer from "./add-consumer";
import getAllConsumers from "./get-all-consumers";
import deleteConsumer from "./delete-consumer";
import updateConsumer from "./update-consumer";
import getSingleConsumer from "./get-single-consumer";
import addEmergencyContact from "./add-emergency-contact";
import getAllEmergencyContacts from "./get-all-emergency-contacts";
import deleteEmergencyContact from "./delete-emergency-contact";
import updateEmergencyContact from "./update-emergency-contact";
import getSingleEmergencyContact from "./get-single-emergency-contact";
import addLanguage from "./add-language";
import getAllLanguages from "./get-all-languages";
import deleteLanguage from "./delete-language";
import updateLanguage from "./update-language";
import getSingleLanguage from "./get-single-language";
import getCaregiverApplicationData from "./get-caregiver-application-data";
import updateCaregiverApplicationData from "./update-caregiver-application";
import uploadFile from "./upload-file";
import getAllRequiredDocuments from "./get-all-required-documents";
import getSingleRequiredDocument from "./get-single-required-documents";
import getSingleUpload from "./get-single-upload/index";
import getAllNavMenus from "./get-all-nav-menus";
import getApplicantQuestion from "./applicant-test";
import { getApplicantStage } from "./get-application";
import modifyNavAccess from "./nav-access";

const rootReducer = combineReducers({
  login,
  language,
  modifyNavAccess,
  verify_otp,
  resend_otp,
  signUp,
  resetPassword,
  getPersonalData,
  updatePersonalData,
  addEducation,
  getAllEducations,
  deleteEducation,
  updateEducation,
  getSingleEducation,
  addEmployment,
  getAllEmployments,
  deleteEmployment,
  updateEmployment,
  getSingleEmployment,
  addConsumer,
  getAllConsumers,
  deleteConsumer,
  updateConsumer,
  getSingleConsumer,
  addEmergencyContact,
  getAllEmergencyContacts,
  deleteEmergencyContact,
  updateEmergencyContact,
  getSingleEmergencyContact,
  addLanguage,
  getAllLanguages,
  deleteLanguage,
  updateLanguage,
  getSingleLanguage,
  getCaregiverApplicationData,
  updateCaregiverApplicationData,
  uploadFile,
  getAllRequiredDocuments,
  getSingleRequiredDocument,
  getSingleUpload,
  getAllNavMenus,
  getApplicantQuestion,
  getApplicantStage
});

export default rootReducer;

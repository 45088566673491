import * as types from '../../action-types';
import {resetPassword as initialState } from "../../initialState";

const resetPassword = (state=initialState, action) => {
  switch (action.type) {
    case types.RESET_PASSWORD_START:
      return {
        ...state,
        isLoading: true
      };
    case types.RESET_PASSWORD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccessful: true,
        data: action.payload
      };
    case types.RESET_PASSWORD_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.RESET_PASSWORD_CLEANUP:
      return {
        ...state,
        isLoading: false,
        isSuccessful: false,
        error: null
      };
    default:
      return state;
  }
}

export default resetPassword;

// -------------- import external dependencies -------------
import React, { useEffect, useState } from "react";
import { Table } from "antd";
import { useDispatch, useSelector } from "react-redux";

// ------------- import internal dependencies ------------
import "./home.scoped.css";
import Seo from "../../shared/Seo";
import ApplicantLayout from "../../components/Layouts/ApplicantLayout";
import SearchInput from "components/SearchInput";
import StepDropDown from "components/StepDropDown";

import { Button } from "antd";
import { useNavigate, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { FormattedMessage } from "react-intl";

// ------------- actions  -------------
import {
  getPersonalData,
  getPersonalDataCleanup,
} from "../../store/actions/get-personal-data";
import {getUserAplicationStatus, getApplicationCleanup} from "../../store/actions/get-application";

const Home = () => {
  const location = useLocation();
  var appType = location.state?.applicantType ?? "CDPAP"; //TODO : update to use Applicant Type

  //hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.getPersonalData);
  const applicationStage = useSelector((state) => state.getApplicantStage);
  const [data, setData] = useState([]);

  useEffect(() => {
    dispatch(getPersonalData());
  }, []);

  useEffect(() => {
    if (userProfile?.isSuccessful) {
      dispatch(getUserAplicationStatus(userProfile?.data?.Id))
    }
  }, [ userProfile?.data?.Id])

  useEffect(() => {
    if (userProfile.isSuccessful) {
      setData(userProfile.data);
      console.log(userProfile.data);
      appType = data.Applicant_Type__c;
      console.log(appType)
      console.log("eDocs")
      console.log(userProfile.data.Edocs)

      // eDocsDataSource.length = 0;
      userProfile.data.Edocs?.forEach( eDoc => {
        eDoc.actionLink = eDoc.Direct_Signing_URL__c
        // eDocsDataSource.push({
        //   key: eDocsDataSource.length,
        //   actions: "Sign "+eDoc.Name,
        //   deadline: eDoc.Sertifi2_0__ExpirationDate__c ?? "N/A",
        //   expiryDate: "N/A",
        // })
      })
      userProfile.data.RequiredDocs?.forEach( rDoc => {
        rDoc.actions = rDoc.attributes.url
      })
      console.log(userProfile.data.Edocs)
      dispatch(getPersonalDataCleanup());
    } else if (userProfile.error) {
      dispatch(getPersonalDataCleanup());
    }
  }, [userProfile]);

  useEffect(() => {
    if (applicationStage?.isSuccessful) {

      setData({...data,...applicationStage.data});
      dispatch(getApplicationCleanup());
    }else if(applicationStage.error){
      dispatch(getApplicationCleanup());
    }
  }, [applicationStage])




  const columns = [

    {
      title: "Missing Documents",
      dataIndex: "Name",
      key: "Id",
    },
    // {
    //   title: "Actions",
    //   dataIndex: "actionLinks",
    //   key: "Id",
    //   render:(actionLink)=>(
    //     <Link className="proceed-btn" to={`${actionLink}`}>proceed</Link>
    //   )
    // },
    // {
    //   title: "Expiry Date",
    //   dataIndex: "expiryDate",
    //   key: "expiry-date",
    // },
  ];

  const dataSource = [ //TODO : get this backend
    {
      key: "1",
      actions: "Upload proof of residence",
      deadline: "12/03/2022",
      expiryDate: "N/A",
    },
    {
      key: "2",
      actions: "Licence expiry. Update Licence record",
      deadline: "22/04/2022",
      expiryDate: "22/04/2022",
    },
  ];

  const eDocsColumns = [
    {
      title: "eDocs to Sign",
      dataIndex: "Name",
      key: "Id",
    },
    {
      title: "Action",
      dataIndex: "actionLink",
      key: "Id",
      render:(actionLink)=>(
        <a
        class="proceed-btn"
        href={`${actionLink}`}
        target="_blank" >Review and Sign</a>
      )
    },
    // {
    //   title: "Deadline",
    //   dataIndex: "deadline",
    //   key: "age",
    // },
    // {
    //   title: "Expiry Date",
    //   dataIndex: "expiryDate",
    //   key: "expiry-date",
    // },
  ];

  const eDocsDataSource = [ //TODO : get this backend
    {
      key: "1",
      actions: "Review and Sign Application",
      deadline: "12/03/2022",
      expiryDate: "N/A",
    }
  ];


  return (
    <>
      <Seo>
        <ApplicantLayout data={data}>
          <div className="dashboard-wrapper">
            {/*<SearchInput />*/}

            {/* ----------- welcome section ----------- */}
            <div className="welcome-wrapper">
              <h1 className="home-user-name">{<FormattedMessage id="dashboard.welcome" />} {data.FirstName}</h1>
              <span>{<FormattedMessage id="dashboard.pagePrompt" />}</span>
            </div>

            {/* ----------- applicat todos ------------ */}
            <div className="applicants-todo">
              <Button
                onClick={() =>
                  navigate(
                    data.Applicant_Type__c === "CDPAP"
                      ? "/register/consumer"
                      : "/register/education", //TODO : update to use NavDict
                    { state: { applicantType: data.Applicant_Type__c } }
                  )
                }
                type="primary"
                htmlType="submit"
              >
                {<FormattedMessage id="dashboard.resumeApplication" />}
              </Button>
              <h2>{<FormattedMessage id="dashboard.applicantToDo" />}</h2>
              <div>
                {data.Needs_Address_Details__c ? (
                  <StepDropDown
                    title={<FormattedMessage id="dashboard.addressPrompt" />}
                    url="/register/personal"
                  />
                ) : null}
                {data.Needs_Emergency_Contact__c ? (
                  <StepDropDown
                    title={<FormattedMessage id="dashboard.emergencyContactsToDoPrompt" />}
                    url="/register/emergency"
                  />
                ) : null}
                {data.Needs_Education_Information__c ? (
                  <StepDropDown
                    title={<FormattedMessage id="dashboard.educationHistoryToDoPrompt" />}
                    url="/register/education"
                  />
                ) : null}
                {data.Needs_Employment_History__c ? (
                  <StepDropDown
                    title={<FormattedMessage id="dashboard.employmentHistoryToDoPrompt" />}
                    url="/register/employment"
                  />
                ) : null}
                {data.Needs_to_Take_Test__c ? (
                  <StepDropDown
                    title={<FormattedMessage id="dashboard.employmentTestToDoPrompt" />}
                    url="/register/test"
                  />
                ) : null}
                {data.Needs_To_Complete_Caregiver_Application__c ? (
                  <StepDropDown
                    title={<FormattedMessage id="dashboard.caregiverAppToDoPrompt" />}
                    url="/register/caregiver"
                  /> /*HHA Only*/
                ) : null}
                {data && data.RequiredDocs ?
                <StepDropDown
                  title={<FormattedMessage id="dashboard.uploadsToDoPrompt" />}  //{`Upload Missing Documents ( ${data.Number_of_Outstanding_Documents__c ?? ""} Outsdanding)`}
                  url="/register/documents"
                >
                  <div className="missing-table">
                    <Table
                      dataSource={data?.RequiredDocs}
                      columns={columns}
                      pagination={false}
                    />
                  </div>
                </StepDropDown>
                 : null}
                {/*<StepDropDown title="Upload Employee History" />*/}{" "}
                {/*HHA Only*/}
                {data && data.Edocs?.length > 0 ?
                <StepDropDown
                  title={<FormattedMessage id="dashboard.edocsToDoPrompt" />}
                  url={(data?.Edocs && data?.Edocs.length > 0) ? data?.Edocs[0].GetLink_Signer1_Url__c ?? "#": null}
                  external={true}
                  key={dataSource.length}
                >
                  <div className="missing-edocs-table">
                    <Table
                      dataSource={data?.Edocs}
                      columns={eDocsColumns}
                      pagination={false}
                    />
                  </div>
                </StepDropDown>
                : null}
              </div>
            </div>
          </div>
        </ApplicantLayout>
      </Seo>
    </>
  );
};

export default Home;

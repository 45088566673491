import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getAllEmergencyContactsStart = () => ({
  type: types.GET_ALL_EMERGENCY_CONTACTS_START
});

export const getAllEmergencyContactsSuccess = payload => ({
  type: types.GET_ALL_EMERGENCY_CONTACTS_SUCCESS,
  payload
});

export const getAllEmergencyContactsFail = payload => ({
  type: types.GET_ALL_EMERGENCY_CONTACTS_FAIL,
  payload
});

export const getAllEmergencyContactsCleanup = () =>({
  type: types.GET_ALL_EMERGENCY_CONTACTS_CLEANUP
});

export const getAllEmergencyContacts = () => async dispatch => {
  try {

    dispatch(getAllEmergencyContactsStart());
    const requestObj = {
      path: "emergencycontacts",
      method: "GET",
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(getAllEmergencyContactsSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getAllEmergencyContactsFail(error));
  }
}

// -------------- import external dependencies ---------------
import * as types from "../../action-types";
import AxiosCall from "../../../utils/axios";
import ErrorHandler from "../../../utils/error-handler";

export const getQuestionStart = () => ({
  type: types.GET_QUESTION_START,
});

export const getQuestionSuccess = (payload) => ({
  type: types.GET_QUESTION_SUCCESS,
  payload,
});

export const getQuestionFail = (payload) => ({
  type: types.GET_QUESTION_FAIL,
  payload,
});

export const getQuestionCleanup = () => ({
  type: types.GET_QUESTION_CLEANUP,
});

export const selectAnswer = (payload) => ({
  type: types.SELECT_QUESTION_ANSWER,
  payload
})

export const submitQuestions = () => ({
  type: types.SUBMIT_QUESTIONS_ANSWER
})

/**
 * Get all applicant test questions
 * @returns
 */
export const getAllQuestions = () => async (dispatch) => {
  try {
    dispatch(getQuestionCleanup());
    dispatch(getQuestionStart());
    const reqObj = {
      path: `test`,
      method: "get",
    };

    const res = await AxiosCall(reqObj);
    dispatch(getQuestionSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getQuestionFail());
  }
};

/**
 * Submit question action function
 * @param {array} data
 * @returns
 */
export const submitQuestionsAnswer = (data) => async (dispatch) => {
  try {
    const reqObj = {
      path: `test/submitAnswers`,
      method: "patch",
      data: {
        questions: data
      }
    }

    const res = await AxiosCall(reqObj)

    return res
  } catch (err) {
    const error= ErrorHandler(err);
    dispatch(getQuestionCleanup());
  }
}

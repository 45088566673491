import React, {useState} from 'react'
import logoImage from '../../assets/img/logo.svg';
import { Menu, Dropdown } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { language, updateLanguageInSERVER } from "../../store/actions/language";
import styles from './authnavbar.module.css'


const authNavStyle = {
  height: '70px',
  background: 'transparent',
  width: '100%',
  position: 'relative',
  top: '0',
  paddingLeft: '52px',
  paddingTop: '43px'
}

const menuStyles = {
  float:'right',
  marginRight:'20px',
  textDecoration:'none'
}

function AuthNavbar() {

  const dispatch = useDispatch()
  const state = useSelector(s=>s.language)
  const localStorageLanguage = localStorage.getItem('language')

  const [currentLanguage, setCurrentLanguage] = useState(state.language==null?localStorageLanguage:'Language')

  const menuOptions = [
    {label:'English'},
    {label:'Spanish'},
    {label:'Russian'},
    {label:'Chinese'},
    {label:'Creole'},
  ]

  const handleSelectLanguage = (item)=>{
    setCurrentLanguage(item.label)
    localStorage.setItem('language', item.label);
    dispatch(language({ language:item.label }));
    dispatch(updateLanguageInSERVER({Primary_Language__c:item.label}))
  }

  const menu = (
    <Menu>
    {menuOptions.map((item, i)=>
        <Menu.Item onClick={()=>handleSelectLanguage(item)} >           
            {item.label}
        </Menu.Item>
    )}
    </Menu>
  );

  return (
    <div style={authNavStyle} className='auth-nav'>
      <span>
        <img className={styles.logo} src={logoImage} />
      </span>
      <div style={menuStyles} >
      <Dropdown overlay={menu}>
          <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
            {currentLanguage || 'Language'} <CaretDownOutlined />
          </a>
      </Dropdown>
      </div>
    </div>
  )
}

export default AuthNavbar

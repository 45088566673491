// ----------- import external dependencies -------------
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

// ------------ import internal dependencies --------------
import "./applicantlayout.css";
import LeftSideBar from "../../LeftSideBar";
import RightSideBar from "../../../components/RightSideBar";
import AuthNavbar from "components/Navbars/ApplicationNavbar";
import { ReactComponent as Hamburger } from "../../../assets/svgs/hamburger.svg";
import { ReactComponent as Notification } from "../../../assets/svgs/bell.svg";
// import { ReactComponent as Email } from "../../../assets/svgs/mail.svg";
// import { ReactComponent as Settings } from "../../../assets/svgs/setting 2.svg";

function ApplicantLayout({ children, data }) {
  // --------- application state managers ----------
  const [desktop, setDesktop] = useState(false);
  const [rightNav, setRightNav] = useState(false);

  useEffect(() => {
    window.scrollTo(0,0)
    const availableSize = window.innerWidth;

    if (availableSize <= 820) {
      console.log("mobile screen");
      setDesktop(false);
    } else {
      setDesktop(true);
    }

    if (availableSize <= 428) {
      setRightNav(false);
      window.scrollTo(0,0)
    } else {

      if(data.Applicant_Type__c){
        setRightNav(true);
        // console.log('data coming from layout', data);
      }

    }
  }, [data]);

  return (
    <>
      <main>
        <AuthNavbar allowBottom={false}/>
        {desktop && <LeftSideBar close={setDesktop} />}
        <div className="mobile-nav">
          <Hamburger
            style={{ cursor: "pointer" }}
            onClick={() => setDesktop(true)}
          />

          <div className="mobile-profile-icons">
            <div className="notify-icon">
              <Notification />
              <span className="indicator"></span>
            </div>
            {/* <Email height="16" /> */}
            <div
              className="img-temp img-temp--mobile"
              onClick={() => setRightNav(!rightNav)}
            ></div>

            {/* <Settings height="16" /> */}
          </div>
        </div>
        <section className="main-section">{children}</section>
        {rightNav && <RightSideBar data={data} close={setRightNav}/>}
      </main>
    </>
  );
}

export default ApplicantLayout;

ApplicantLayout.propTypes = {
  children: PropTypes.node,
};

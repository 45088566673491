import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getAllEducationStart = () => ({
  type: types.GET_ALL_EDUCATIONS_START
});

export const getAllEducationSuccess = payload => ({
  type: types.GET_ALL_EDUCATIONS_SUCCESS,
  payload
});

export const getAllEducationFail = payload => ({
  type: types.GET_ALL_EDUCATIONS_FAIL,
  payload
});

export const getAllEducationCleanup = () =>({
  type: types.GET_ALL_EDUCATIONS_CLEANUP
});

export const getAllEducation = () => async dispatch => {
  try {

    dispatch(getAllEducationStart());
    const requestObj = {
      path: "educations",
      method: "GET",
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(getAllEducationSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getAllEducationFail(error));
  }
}

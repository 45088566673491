import React, {useState, useEffect} from 'react'
import logoImage from '../../assets/img/logo.svg';
import logoImage2 from '../../assets/img/royalfilogo.svg'
import { Menu, Dropdown } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import appStyles from './ApplicationNavbar.module.css';
import { NavLink } from 'react-router-dom';
import {Modal} from 'antd'
import { FormattedMessage } from 'react-intl';
import HelpModal from 'components/Modal/helpModal';

import { useDispatch, useSelector } from 'react-redux';
import { language, updateLanguageInSERVER } from "../../store/actions/language";



function AuthNavbar({ allowBottom = true}) {
    const dispatch = useDispatch()
    const state = useSelector(s=>s.language)
    const user = useSelector((s) => s.getPersonalData.data);
   
    const [appType, setAppType] = useState(null);
    const menuOptions = [
        {label:'English', id:'English'},
        {label:'Español', id:'Spanish'},
        {label:'Русский', id:'Russian'},
        {label:'中文', id:'Chinese'},
        {label:'Kreyòl', id:'Creole'},
    ]
    const localStorageLanguage = menuOptions.find(x => x.id == localStorage.getItem('language')?.label)
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [currentLanguage, setCurrentLanguage] = useState(state.language==null?localStorageLanguage:'Language')
    

    const showModal = () => {
        setIsModalVisible(true);
      };
    
      const handleOk = () => {
        setIsModalVisible(false);
      };
    
      const handleCancel = () => {
        setIsModalVisible(false);
      };

    const handleSelectLanguage = (item)=>{
        setCurrentLanguage(item.label)
        localStorage.setItem('language', item.id);
        dispatch(language({ language:item.id }));
        dispatch(updateLanguageInSERVER({Primary_Language__c:item.id}))
    }

    useEffect(()=>{
        setAppType(user?.Applicant_Type__c)
    },[appType])

    const menu = (
        <Menu>
        {menuOptions.map((item, i)=>
            <Menu.Item onClick={()=>handleSelectLanguage(item)} >
                {item.label}
            </Menu.Item>
        )}
        </Menu>
    );

  return (
      <>
        <HelpModal 
            isModalVisible={isModalVisible} 
            handleCancel={handleCancel} 
            handleOk={handleOk}
        />
        <div className={appStyles.topNav}>
            <nav>
                <ul>
                    <li>
                        <Dropdown overlay={menu}>
                            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                {currentLanguage || 'Language'} <CaretDownOutlined />
                            </a>
                        </Dropdown>
                    </li>
                    <li><NavLink to={
                        "/logout"
                    }><FormattedMessage id="menu.logout" /></NavLink></li>
                    <li style={{cursor:'pointer', display: "flex", alignItems: "center", gap: "0.4rem"}} onClick={()=>showModal()}>
                        <span ><FormattedMessage id="auth.needHelp" /> </span>
                        <span>
                            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <circle cx="12" cy="12" r="12" fill="#4EB3E5"/>
                                <path d="M10.7891 14.7969C10.7995 14.1771 10.8698 13.6875 11 13.3281C11.1302 12.9688 11.3958 12.5703 11.7969 12.1328L12.8203 11.0781C13.2578 10.5833 13.4766 10.0521 13.4766 9.48438C13.4766 8.9375 13.3333 8.51042 13.0469 8.20312C12.7604 7.89062 12.3438 7.73438 11.7969 7.73438C11.2656 7.73438 10.8385 7.875 10.5156 8.15625C10.1927 8.4375 10.0312 8.8151 10.0312 9.28906H8.58594C8.59635 8.44531 8.89583 7.76562 9.48438 7.25C10.0781 6.72917 10.849 6.46875 11.7969 6.46875C12.7812 6.46875 13.5469 6.73438 14.0938 7.26562C14.6458 7.79167 14.9219 8.51562 14.9219 9.4375C14.9219 10.349 14.5 11.2474 13.6562 12.1328L12.8047 12.9766C12.4245 13.3984 12.2344 14.0052 12.2344 14.7969H10.7891ZM10.7266 17.2734C10.7266 17.0391 10.7969 16.8438 10.9375 16.6875C11.0833 16.526 11.2969 16.4453 11.5781 16.4453C11.8594 16.4453 12.0729 16.526 12.2188 16.6875C12.3646 16.8438 12.4375 17.0391 12.4375 17.2734C12.4375 17.5078 12.3646 17.7031 12.2188 17.8594C12.0729 18.0104 11.8594 18.0859 11.5781 18.0859C11.2969 18.0859 11.0833 18.0104 10.9375 17.8594C10.7969 17.7031 10.7266 17.5078 10.7266 17.2734Z" fill="black"/>
                            </svg>
                        </span>
                    </li>
                </ul>
            </nav>
        </div>
       {allowBottom && <div className={appStyles.bottomNav}>
           <nav>
                <ul>
                    <li><img className={appStyles.logo} src={appType=="CDPAP"?logoImage2:logoImage} /></li>

                </ul>
            </nav>
        </div>}

       
      
     
      </>
  )
}

export default AuthNavbar

import React,{ useEffect, useState } from 'react';
import DefaultAuthLayout from 'components/Layouts/AuthLayout/default.js';
import defaultLayoutStyles from 'components/Layouts/AuthLayout/default.module.css';
import {  Button } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';


//utils
import useQuery from '../../utils/useQuery/index';

import {FormattedMessage} from 'react-intl';

const ForgotPassword = () => {
    const navigate = useNavigate();
    const [email]=useQuery()
    const[mail, setMail]=useState("")
    const verticalTextStyle = {
        textAlign: 'center'
    }

    const buttonStyle = {
        width: '60%'
    }

    const helpLinkStyle = {
        marginTop: '14px'
    }

    useEffect(()=>{
        setMail(email[1])
    },[])
  return (
    <div>
        <DefaultAuthLayout>
                <h5 className={defaultLayoutStyles.cardHeader}>
                    {<FormattedMessage id="register.verifyEmail" />}
                </h5>
                <h6 className={defaultLayoutStyles.cardDescription} style={verticalTextStyle}>
                    <p>{<FormattedMessage id="auth.verify.weSentAVerificationEmailToPrefix" />} {mail}.</p>
                    <p>{<FormattedMessage id="auth.verify.justClickOnTheLink" />}</p>
                    <p><i><b>{<FormattedMessage id="auth.verify.stillCantFindEmail" />}</b></i></p>
                </h6>

                 <Button onClick={()=>navigate("/login")}  style={buttonStyle} className='auth-btn--custom-auth' type="primary" htmlType="submit">
                    <FormattedMessage id="auth.verify.backToLogin" />
                </Button>

                <div style={helpLinkStyle}>
                        <h6 className={defaultLayoutStyles.cardDescription} ><FormattedMessage id="auth.needHelp" /> <NavLink to={'https://www.royalcare.nyc/contact'}><b>Contact us.</b></NavLink></h6>
                </div>
        </DefaultAuthLayout>
    </div>
  )
};

export default ForgotPassword;

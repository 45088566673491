import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getSingleRequiredDocumentStart = () => ({
  type: types.GET_SINGLE_REQUIRED_DOCUMENTS_START
});

export const getSingleRequiredDocumentSuccess = payload => ({
  type: types.GET_SINGLE_REQUIRED_DOCUMENTS_SUCCESS,
  payload
});

export const getSingleRequiredDocumentFail = payload => ({
  type: types.GET_SINGLE_REQUIRED_DOCUMENTS_FAIL,
  payload
});

export const getSingleRequiredDocumentCleanup = () =>({
  type: types.GET_SINGLE_REQUIRED_DOCUMENTS_CLEANUP
});

export const getSingleRequiredDocument = requireddocumentId => async dispatch => {
  try {

    dispatch(getSingleRequiredDocumentStart());
    const requestObj = {
      path: `requireddocument/${requireddocumentId}`,
      method: "GET",
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(getSingleRequiredDocumentSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getSingleRequiredDocumentFail(error));
  }
}

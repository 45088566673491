// ------------- import external dependencies -----------
import React from "react";
import { Link, NavLink } from "react-router-dom";
import PropTypes from "prop-types";

// ----------- import internal dependencies ----------
import "./leftsidebar.css";
import BrandLogo from "../../assets/img/logo.png";
import { ReactComponent as Dashboard } from "../../assets/svgs/dashboard.svg";
import { ReactComponent as Profile } from "../../assets/svgs/profile.svg";
import { ReactComponent as Document } from "../../assets/svgs/document.svg";
import { ReactComponent as Help } from "../../assets/svgs/help.svg";

import { FormattedMessage } from "react-intl";

function LeftSideBar({ close }) {
  const paths = [
    { path: "/dashboard", icon: Dashboard, title: <FormattedMessage id="menu.dashboard" />  },
    { path: "/register/personal", icon: Profile, title: <FormattedMessage id="menu.profile" /> },
    { path: "/register/documents", icon: Document, title: <FormattedMessage id="menu.documents" /> },
    { path: "/register/emergency", icon: Document, title: <FormattedMessage id="menu.application" /> },
    // { path: "/dashboard", icon: Help, title: "Trainings" },
    // { path: "/dashboard", icon: Help, title: "Help center" },
  ];
  return (
    <>
      <aside className="left-sidebar">
        <span className="close-nav" onClick={() => close(false)}>
          &times;
        </span>
        <div className="brand-logo">
          <img src={BrandLogo} className={"home-logo"} alt="Royal care logo" />
        </div>
        <nav id="main">
          <ul>
            {paths.map((elem, i) => (
              <li key={i}>
                <NavLink
                  to={elem.path}
                  className={({ isActive }) => (isActive ? "active" : "")}
                >
                  {({ isActive }) => (
                    <>
                      <elem.icon />
                      <span>{elem.title}</span>
                    </>
                  )}
                </NavLink>
              </li>
            ))}
          </ul>
          <Link to="/logout" className="logout">
            <Help />
            <span><FormattedMessage id="menu.logout" /></span>
          </Link>
        </nav>
      </aside>
    </>
  );
}

export default LeftSideBar;

LeftSideBar.propTypes = {
  close: PropTypes.func,
};

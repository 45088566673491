import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const resetPasswordStart = () => ({
  type: types.RESET_PASSWORD_START
});

export const resetPasswordSuccess = payload => ({
  type: types.RESET_PASSWORD_SUCCESS,
  payload
});

export const resetPasswordFail = payload => ({
  type: types.RESET_PASSWORD_FAIL,
  payload
});

export const resetPasswordCleanup = () => ({
  type: types.RESET_PASSWORD_CLEANUP
});

export const resetPassword = payload => async dispatch => {

  try {
    dispatch(resetPasswordStart());
    const requestObj = {
      path: "auth/reset",
      method: "POST",
      data: payload,
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(resetPasswordSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(resetPasswordFail(error));
  }
}

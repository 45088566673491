import applicationLayoutStyles from './application.module.css';
import ApplicationNavbar from "components/Navbars/ApplicationNavbar";
import React,{useEffect, useState} from 'react'
import { NavLink } from "react-router-dom";
import { ArrowLeftOutlined } from '@ant-design/icons';
import {useNavigate} from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux';
import Notification from "../../../utils/notification";
import useQuery from "../../../utils/useQuery/index";


//actions
import {
  getPersonalData,
  getPersonalDataCleanup,
} from "../../../store/actions/get-personal-data";
import { getAllNavMenus } from '../../../store/actions/get-all-nav-menus';

import { navDict } from "../../../utils/helpers/data";

import { FormattedMessage } from 'react-intl';


function ApplicationLayout({children}) {
  const getPersonalDataState = useSelector((s) => s.getPersonalData);
  const [data, setData] = useState(null)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [path, setPath] = useState(undefined);
  const [lastPath] = useQuery();
  useEffect(() => {
    if (lastPath !== undefined) {
      setPath(lastPath[1]);
    }
    dispatch(getPersonalData());

}, []);
useEffect(() => {
     if (getPersonalDataState.isSuccessful) {
        console.log(getPersonalDataState.data)
        dispatch(getAllNavMenus(document.getElementById("list")?.childElementCount));
        setData(getPersonalDataState.data)
        dispatch(getPersonalDataCleanup());
    }

}, [getPersonalDataState])

const handleNavigation = (url, flag)=>{
  
  Notification(
    'Access denied',
    "warning",
    `You are not yet allowed to access this section. Please make sure to complete all previous sections`
  );
}

  return (
    <>
      
      <div className={applicationLayoutStyles.appLayout}>
          <ApplicationNavbar />

          {/* ---------- application layout wrapper ----------- */}
          <div className={applicationLayoutStyles.appLayoutWrapper}>

              {/* --------- application sidebar wrapper ---------- */}
              <div className={applicationLayoutStyles.appSidebar}>
                <div style={{cursor:"pointer"}} onClick={()=>navigate("/dashboard")} className={applicationLayoutStyles.appSidebarBackLink}>
                  <span><ArrowLeftOutlined /></span>
                  <b>{<FormattedMessage id="menu.goToDashboard" />}</b>
                </div>

                {data && data.Applicant_Type__c ?
                <div className={applicationLayoutStyles.appSidebarBox}>

                  {/* ---------- application box sidebar header --------- */}
                  <div className={applicationLayoutStyles.appSidebarBoxHeader}>
                    <h6>{navDict[data.Applicant_Type__c]['label']}</h6>
                   {/*{data&&data.Applicant_Type__c==="HHA"?<h6>HHA APPLICATION</h6>: <h6>CDPAP APPLICATION</h6>}*/}
                    <span>{navDict[data.Applicant_Type__c]['prompt']}</span>
                  </div>

                  {/* ----------- application sidebar navigation list ---------- */}
                  <div  className={applicationLayoutStyles.appSidebarBoxList}>
                    <ul id="list">
                      <li>
                      
                        <NavLink 
                          className={({ isActive }) => (isActive ? applicationLayoutStyles.listLinkActive : 'inactive')} 
                          to='/register/personal' 
                          state={{ applicantType : data&&data.Applicant_Type__c }}>
                            <span><FormattedMessage id="menu.personalInformation" /></span>
                        </NavLink>
                      </li>

                     {data.Can_Go_To_Related_Profile_Info__c &&
                      <li>
                        <NavLink 
                          className={({ isActive }) => (isActive ? applicationLayoutStyles.listLinkActive : 'inactive')}  
                          to='/register/emergency' 
                          state={{ applicantType : data&&data.Applicant_Type__c }}>
                            <span><FormattedMessage id="menu.emergencyContacts" /></span>
                        </NavLink>
                      </li>}

                      {data&&data.Applicant_Type__c==="HHA"? <>
                      {data.Can_Go_To_Related_Profile_Info__c &&
                      <li>
                        <NavLink 
                          className={({ isActive }) => (isActive ? applicationLayoutStyles.listLinkActive : 'inactive')}  
                          to='/register/education' state={{ applicantType : data&&data.Applicant_Type__c }}>
                            <span><FormattedMessage id="menu.educationInformation" /></span>
                        </NavLink>
                      </li>}

                    
                      {data.Can_Go_To_Related_Profile_Info__c && 
                      <li>
                        <NavLink 
                          className={({ isActive }) => (isActive ? applicationLayoutStyles.listLinkActive : 'inactive')}  
                          to='/register/employment' state={{ applicantType : data&&data.Applicant_Type__c }}>
                            <span><FormattedMessage id="menu.employmentInformation" /></span>
                        </NavLink>
                      </li>}

                      {data.Can_Go_To_Related_Profile_Info__c &&
                      <li>
                      <NavLink 
                        className={({ isActive }) => (isActive ? applicationLayoutStyles.listLinkActive : 'inactive')}  
                        to='/register/language' state={{ applicantType : data&&data.Applicant_Type__c }}>
                          <span><FormattedMessage id="languages.pageHeader" /></span>
                      </NavLink>
                    </li>}
                     
                      </>:null}

                    {data&&data.Applicant_Type__c==="CDPAP"? 
                    <>
                    {data.Can_Go_To_Related_Profile_Info__c && 
                    <li>
                      <NavLink 
                        className={({ isActive }) => (isActive ? applicationLayoutStyles.listLinkActive : 'inactive')}  
                        to='/register/consumer' state={{ applicantType : data&&data.Applicant_Type__c }}>
                          <span><FormattedMessage id="menu.consumerDetails" /></span>
                      </NavLink>
                    </li>}
                    </>:null}

                     {data&&data.Applicant_Type__c==="HHA"? <>
                      
                      {data.Can_Go_To_Employment_Test__c && 
                      <li>
                        <NavLink 
                          className={({ isActive }) => (isActive ? applicationLayoutStyles.listLinkActive : 'inactive')}  
                          to={'/register/test'} 
                          state={{ applicantType : data&&data.Applicant_Type__c }}>
                            <span><FormattedMessage id="menu.employmentTest" /></span>
                        </NavLink>
                      </li>}

                      {data.Can_Go_To_Application_For_Employment__c && 
                      <li>
                        <NavLink 
                          className={({ isActive }) => (isActive ? applicationLayoutStyles.listLinkActive : 'inactive')}  
                          to={ '/register/caregiver'} 
                          state={{ applicantType : data&&data.Applicant_Type__c }}>
                            <span><FormattedMessage id="menu.applicationForEmployment" /></span>
                        </NavLink>
                      </li>}
                      </>:null}

                      {data.Can_Go_To_Documents_Uploads__c &&
                      <li>
                        <NavLink 
                          className={({ isActive }) => (isActive ? applicationLayoutStyles.listLinkActive : 'inactive')} 
                          to={'/register/documents'} 
                          state={{ applicantType : data&&data.Applicant_Type__c }}>
                            <span><FormattedMessage id="menu.uploads" /></span>
                        </NavLink>
                      </li>}
                     
                    </ul>
                  </div>
                </div>
                : ''}
              </div>

              {/* ---------- application children wrapper ------------ */}
              <div className={applicationLayoutStyles.appContent}>
                {children}
              </div>
          </div>

      </div>
    </>
  )
}

export default ApplicationLayout

import React from 'react';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const antIcon = <LoadingOutlined style={{ fontSize: 40 }} spin />;

const Loader = ({text='Loading...', isLoading=false, children }) => {

  return (
    <Spin indicator={antIcon} spinning={isLoading} tip={text} className='mat-text-tertiary' >
      {children}
    </Spin>
  );
}

export default Loader;

// -------------- import external dependencies ------------
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import CountryPhoneInput from "antd-country-phone-input";

// ----------- import internal dependencies -------------
import ApplicationLayout from "components/Layouts/AuthLayout/application";
import applicationLayoutStyles from "components/Layouts/AuthLayout/application.module.css";
import { PhoneNumberValidation } from "../../../utils/registration-form-validators/index";

import { FormattedMessage, useIntl } from "react-intl";

import { language } from "../../../store/actions/language";
import { navAccess } from "store/actions/nav-access";



import {
  Form,
  Input,
  Button,
  Row,
  Col,
  DatePicker,
  Select,
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";

//actions
import {
  getPersonalData,
  getPersonalDataCleanup,
} from "../../../store/actions/get-personal-data";
import {
  updatePersonalData,
  updatePersonalDataCleanup,
} from "../../../store/actions/update-personal-data";

//utils
import Notification from "../../../utils/notification";
import Loader from "../../../components/Loader";
import { loadScript, handleScriptLoad, loadGMapsScript } from "../../../utils/helpers";
import { raceList, pgpList, heightList,
  eyeColorOptions, hairColorOptions, maritalStatusOptions
} from "../../../utils/helpers/data";
import { navDict } from "../../../utils/helpers/data";

let autoComplete;

const RegisterPersonalInfo = () => {
  const location = useLocation();


  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const intl = useIntl();
  const getPersonalDataState = useSelector((s) => s.getPersonalData);
  const updatePersonalDataState = useSelector((s) => s.updatePersonalData);
  const getAllNavMenusState = useSelector((s) => s.getAllNavMenus);
  const state = useSelector(s=>s.language)
  // const [currentLanguage, setCurrentLanguage] = useState("");
  const [PersonMailingCity, setPersonMailingCity] = useState("");
  const [PersonMailingCountry, setPersonMailingCountry] = useState("");
  const [PersonMailingState, setPersonMailingState] = useState("");
  const [PersonMailingStreet, setPersonMailingStreet] = useState("");
  const [PersonMailingPostalCode, setPersonMailingPostalCode] = useState("");
  const [Applicant_Type__c, setApplicant_Type__c] = useState("");
  const [isHHA, setIsHHA] = useState("");
  var appType = Applicant_Type__c ?? "CDPAP"; //location.state?.applicantType
  const dateFormat = "MM/DD/YYYY";

  const [query, setQuery] = useState("");
  const[menuLength, setMenuLength] = useState(0);
  const [, setPhoneNumber] = useState("");


  const autoCompleteRef = useRef(null);

  useEffect(() => {
    document.title = `${intl.formatMessage({id:"web.websiteTitle"})}-${intl.formatMessage({id:'menu.profile'})}`
    dispatch(getPersonalData());
    // ----------- handle google place autocomplete feture on mount ----------
    loadGMapsScript(autoComplete, updateAddressField, autoCompleteRef)
  }, [intl]);
  
  useEffect(() => {
    if(!query) return
    
    let address = query.split(", ");
   
    form.setFieldsValue({
      PersonMailingCity:address[address.length - 3],
      PersonMailingCountry:address[address.length - 1],
      PersonMailingPostalCode:address[address.length - 2]?.split(" ")[1],
      PersonMailingState:address[address.length - 2]?.split(" ")[0],
      PersonMailingStreet:address[address.length - 4]
    })
    // alert('changing me')

  }, [query]);

  useEffect(() => {
    if (getPersonalDataState.isSuccessful) {
     
      setApplicant_Type__c(getPersonalDataState.data.Applicant_Type__c);

      if(getPersonalDataState.data.PGP__c && getPersonalDataState.data.PGP__c!= '') {
        form.setFieldsValue({
          PGP__c: getPersonalDataState.data.PGP__c,
        })
      }

      form.setFieldsValue({
        Applicant_Type__c: getPersonalDataState.data.Applicant_Type__c,
        HCR_Number__c: getPersonalDataState.data.HCR_Number__c,
        FirstName: getPersonalDataState.data.FirstName,
        LastName: getPersonalDataState.data.LastName,
        MiddleName: getPersonalDataState.data.MiddleName,
        SSN: getPersonalDataState.data.SSN__c,
        Salutation: getPersonalDataState.data.Salutation,
        Birthplace__c: getPersonalDataState.data.Birthplace__c,
        // PGP__c: null,//  getPersonalDataState.data.PGP__c,
        Marital_Status__c: getPersonalDataState.data.Marital_Status__c,
        Number_of_Dependents__c: getPersonalDataState.data.Number_of_Dependents__c,
        PersonBirthdate:
          getPersonalDataState.data.PersonBirthdate === null
            ? null
            : moment(getPersonalDataState.data.PersonBirthdate),// dateFormat),
        Email: getPersonalDataState.data.PersonEmail,
        Phone: getPersonalDataState.data.Phone,
        PersonMobilePhone: getPersonalDataState.data.PersonMobilePhone,
        Gender__c: getPersonalDataState.data.Gender__c,
        Hair_Color__c: getPersonalDataState.data.Hair_Color__c,
        Race__c: getPersonalDataState.data.Race__c,
        Height__c: getPersonalDataState.data.Height__c,
        Weight__c: getPersonalDataState.data.Weight__c,
        Eye_Color__c: getPersonalDataState.data.Eye_Color__c,
        PersonMailingCity: getPersonalDataState?.data?.PersonMailingAddress?.city,
        PersonMailingCountry:getPersonalDataState?.data?.PersonMailingAddress?.country,
        PersonMailingState:getPersonalDataState?.data?.PersonMailingAddress?.state,
        PersonMailingStreet:getPersonalDataState?.data?.PersonMailingAddress?.street,
        PersonMailingPostalCode:getPersonalDataState?.data?.PersonMailingAddress?.postalCode,
        // ------ TODO : Notice - added the address property
        address:
          (getPersonalDataState?.data?.PersonMailingAddress?.street ===
          undefined
            ? ""
            : getPersonalDataState?.data?.PersonMailingAddress?.street + ", ") +
          (getPersonalDataState?.data?.PersonMailingAddress?.city === undefined
            ? ""
            : getPersonalDataState?.data?.PersonMailingAddress?.city + ", ") +
          (getPersonalDataState?.data?.PersonMailingAddress?.state === undefined
            ? ""
            : getPersonalDataState?.data?.PersonMailingAddress?.state + " ") +
          (getPersonalDataState?.data?.PersonMailingAddress?.postalCode ===
          undefined
            ? ""
            : getPersonalDataState?.data?.PersonMailingAddress?.postalCode +
              ", ") +
          (getPersonalDataState?.data?.PersonMailingAddress?.country ===
          undefined
            ? ""
            : getPersonalDataState?.data?.PersonMailingAddress?.country),
      });
      setIsHHA(Applicant_Type__c=="HHA");
      if (getPersonalDataState?.data?.Primary_Language__c) {
        var lang = getPersonalDataState?.data?.Primary_Language__c
        // setCurrentLanguage(lang);
        localStorage.setItem("language", lang);
        // dispatch(language({ language: lang}));
      }
      
      dispatch(getPersonalDataCleanup());
    } else if (getPersonalDataState.error) {
      if (Array.isArray(getPersonalDataState.error)) {
        Notification(intl.formatMessage({id:"notification.failed"}), "error", getPersonalDataState.error[0].msg);
      } else Notification(intl.formatMessage({id:"notification.failed"}), "error", intl.formatMessage({id:"notification.profile.get.failed"}));
      dispatch(getPersonalDataCleanup());
    }
  }, [getPersonalDataState]);

  useEffect(() => {
    if (updatePersonalDataState.isSuccessful) {
      Notification(intl.formatMessage({id:'notification.success'}), "success", intl.formatMessage({id:"notification.profile.update.success"}));
      navigate(`/register/emergency`,{state:{ applicantType : Applicant_Type__c }});
      dispatch(navAccess({Can_Go_To_Related_Profile_Info__c:true}))
      dispatch(updatePersonalDataCleanup());
    } else if (updatePersonalDataState.error) {
      if (Array.isArray(updatePersonalDataState.error)) {
        Notification(intl.formatMessage({id:"notification.failed"}), "error", updatePersonalDataState.error[0].msg);
      } else Notification(intl.formatMessage({id:"notification.failed"}), "error", intl.formatMessage({id:'notification.profile.update.failed'}));
      dispatch(updatePersonalDataCleanup());
    }
  }, [updatePersonalDataState]);

  useEffect(() => {
    console.log(getAllNavMenusState);
    // setMenuLength(getAllNavMenusState.data.length);
  }, [getAllNavMenusState]);
  
  const onFinish = (values) => {
    values.PGP__c = Array.isArray(values.PGP__c) ? ((values.PGP__c[0] == '' && values.PGP__c.length > 1) ? values.PGP__c[1] : values.PGP__c[0]) : values.PGP__c;
    values.Primary_Language__c = localStorage.getItem("language") ?? "English";
    console.log(values)
    dispatch(updatePersonalData(values));
  };


  /**
   * Handle a
   * @param {string} addess
   */
  const updateAddressField = (address) => {
    setQuery(address);
    form.setFieldsValue({ address: address });
  };

  const clearAddressField = ()=>{
    form.setFieldsValue({
      address:''
    })
  }

  const { Option } = Select;

  let stepNum = navDict[appType]?.['steps']?.indexOf(location.pathname) + 1;
  let totalStepsCount = navDict[appType]?.['totalStepsCount'];

  return (
    <div>
      <ApplicationLayout>
        <div className={applicationLayoutStyles.mainContent}>
          {appType ? <span>
              <FormattedMessage id="nav.step" values={{stepNum,totalStepsCount}} /> : 
            </span>
            : null } <span>{<FormattedMessage id="profile.pageHeader" />}
          </span>
          <h2>{<FormattedMessage id="profile.pagePrompt" />}</h2>
          <Loader text={<FormattedMessage id="ui.loading" />} loading={getPersonalDataState.isLoading}>
            <Form
              name="basic"
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
              form={form}
              onFinish={onFinish}
            >
              <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                    label={<FormattedMessage id="profile.salutation" />} 
                    name="Salutation">
                    <Select placeholder={<FormattedMessage id="profile.salutationPrompt" />} >
                      <Option value="Mr">{<FormattedMessage id="choices.salutation.mr" />}</Option>
                      <Option value="Mrs">{<FormattedMessage id="choices.salutation.mrs" />}</Option>
                      <Option value="Miss">{<FormattedMessage id="choices.salutation.miss" />}</Option>
                    </Select>
                    {/* <Input disabled={updatePersonalDataState.isLoading} /> */}
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                    label={<FormattedMessage id="profile.genderId" />} 
                    name="PGP__c">
                    <Select 
                      placeholder={<FormattedMessage id="profile.genderIdPrompt" />}
                      mode="tags" 
                      >
                      {pgpList.map((e, i) => (
                        <Option key={i} value={e.sfVal}>
                          {<FormattedMessage id={e.displayStringId} />}
                        </Option>
                      ))}
                    </Select>
                    {/* <Input disabled={updatePersonalDataState.isLoading} /> */}
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="profile.firstName" />}
                    name="FirstName"
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id:'validation.firstNameIsRequired'
                        }),
                      },
                    ]}
                  >
                    <Input disabled={updatePersonalDataState.isLoading} />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="profile.lastName" />}
                    name="LastName"
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id:'validation.lastNameIsRequired'
                        }),
                      },
                    ]}
                  >
                    <Input disabled={updatePersonalDataState.isLoading} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                
                
              </Row>
              <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                    label={<FormattedMessage id="profile.SSN" />} 
                    name="SSN">
                    <Input disabled />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                    label= {<FormattedMessage id="profile.middleName" />} 
                    name="MiddleName">
                    <Input disabled={updatePersonalDataState.isLoading} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                    label={<FormattedMessage id="profile.birthdate" />} 
                    name="PersonBirthdate"
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id:'validation.birthdateIsRequired'
                        }),
                      },
                    ]}
                    >
                    <DatePicker 
                      placeholder=""  
                      format={dateFormat} f/>
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                    label={<FormattedMessage id="profile.countryOfBirth" />} 
                    name="Birthplace__c">
                    <Input disabled={updatePersonalDataState.isLoading} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                    label={<FormattedMessage id="profile.maritalStatus" />} 
                    name="Marital_Status__c">
                    <Select 
                      placeholder={<FormattedMessage id="profile.maritalStatusPrompt" />}
                      disabled={updatePersonalDataState.isLoading}>
                      {maritalStatusOptions.map((e, i) => (
                        <Option key={i} value={e.sfVal}>
                          {<FormattedMessage id={e.displayStringId} />}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                    label={<FormattedMessage id="profile.numberOfDependents" />}  
                    name="Number_of_Dependents__c">
                    <Input type="number" disabled={updatePersonalDataState.isLoading} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={32}>
                
                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                  label={<FormattedMessage id="profile.email" />} 
                  name="Email">
                    <Input disabled={true} />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={32}>
              {getPersonalDataState.isLoading ?
                null:
                <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="profile.phoneNumber" />}
                    name="PersonMobilePhone"
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id:'validation.phoneNumberIsRequired'
                        }),
                      },
                    ]}
                    initialValue={{
                      short: "us",
                    }}
                  >
                    <Input />
                  </Form.Item>
                  {/* <Form.Item
                    label="Phone Number"
                    name="PersonMobilePhone"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        validator: PhoneNumberValidation,
                      },
                    ]}
                    initialValue={{
                      short: "us",
                    }}
                  >
                    <CountryPhoneInput
                      onChange={(p) => {
                        setPhoneNumber(`+${p.code}${p.phone}`);
                      }}
                    />
                  </Form.Item> */}
                </Col>}
                <Col span={12} xs={24} lg={12}>
                  <Form.Item label={<FormattedMessage id="profile.mobile" />} name="Phone">
                    <Input disabled={updatePersonalDataState.isLoading} />
                  </Form.Item>
                </Col>
              </Row>
              {isHHA?<Row gutter={32}>
                <Col span={12} xs={24} lg={12}> 
                <Form.Item
                  label="HCR Number"
                  name="HCR_Number__c"
                  rules={[
                    {
                      required: isHHA,
                      message: <FormattedMessage id="validation.hcrNumberIsRequired" />
                    },

                  ]}
                >
                  <Input
                    disabled={updatePersonalDataState.isLoading || getPersonalDataState.data.HCR_Number__c != ""}
                  />
              </Form.Item>
                </Col>
            </Row>:null}
              <br></br>
            <div hidden={appType == "CDPAP"}>
              <div>
                <span className="form-heading">{<FormattedMessage id="profile.biometricsPrompt" />}</span>
              </div>
              <br></br>

              <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                  label={<FormattedMessage id="profile.legalGender" />}
                  name="Gender__c"
                  rules={[
                      {
                        required: isHHA,
                        message: intl.formatMessage({
                          id:'validation.legalGenderIsRequired'
                        }),
                      },
                    ]}
                  >
                    <Select placeholder={<FormattedMessage id="profile.legalGenderPrompt" />}>
                      <Option value="Female">{<FormattedMessage id="choices.legalGender.female" />}</Option>
                      <Option value="Male">{<FormattedMessage id="choices.legalGender.male" />}</Option>
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                  label={<FormattedMessage id="profile.race" />} 
                  name="Race__c"
                  rules={[
                      {
                        required: isHHA,
                        message: intl.formatMessage({
                          id:'validation.raceIsRequired'
                        }),
                      },
                    ]}
                  >
                    <Select placeholder={<FormattedMessage id="profile.racePrompt" />}>
                      {raceList.map((e, i) => (
                        <Option key={i} value={e.sfVal}>
                          {<FormattedMessage id={e.displayStringId} />}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                  label={<FormattedMessage id="profile.heightWithUnit" />} 
                  name="Height__c"
                  rules={[
                      {
                        required: isHHA,
                        message: intl.formatMessage({
                          id:'validation.heightIsRequired'
                        }),
                      },
                    ]}
                  >
                    <Select>
                      {heightList.map((e, i) => (
                        <Option key={i} value={e}>
                          {e}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>

                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                  label={<FormattedMessage id="profile.weightWithUnit" />} 
                  name="Weight__c"
                  rules={[
                      {
                        required: isHHA,
                        message: intl.formatMessage({
                          id:'validation.weightIsRequired'
                        }),
                      },
                    ]}
                  >
                  <Input disabled={updatePersonalDataState.isLoading} />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                  label={<FormattedMessage id="profile.eyeColor" />} 
                  name="Eye_Color__c"
                  rules={[
                      {
                        required: isHHA,
                        message: intl.formatMessage({
                          id:'validation.eyeColorIsRequired'
                        }),
                      },
                    ]}
                  >
                    {/*<Input disabled={updatePersonalDataState.isLoading} />*/}
                    <Select placeholder={<FormattedMessage id="profile.eyeColorPrompt" />} disabled={updatePersonalDataState.isLoading}>
                      {eyeColorOptions.map((e, i) => (
                        <Option key={i} value={e.sfVal}>
                          {<FormattedMessage id={e.displayStringId} />}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                  label={<FormattedMessage id="profile.hairColor" />} 
                  name="Hair_Color__c"
                  rules={[
                      {
                        required: isHHA,
                        message: intl.formatMessage({
                          id:'validation.hairColorIsRequired'
                        }),
                      },
                    ]}
                  >
                    {/*<Input disabled={updatePersonalDataState.isLoading} />*/}
                    <Select placeholder={<FormattedMessage id="profile.hairColorPrompt" />} disabled={updatePersonalDataState.isLoading}>
                      {hairColorOptions.map((e, i) => (
                        <Option key={i} value={e.sfVal}>
                          {<FormattedMessage id={e.displayStringId} />}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
            </div>

              <br></br>
              <div>
                <span className="form-heading">{<FormattedMessage id="profile.addressPrompt" />}:</span>
              </div>
              <br></br>
              <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                  label={<FormattedMessage id="address.lookup" />} //{<FormattedMessage id="profile.streetAddress" />} 
                  name="address" 
                  rules={[
                      {
                        message: intl.formatMessage({
                          id:'validation.streetAddressIsRequired'
                        }),
                      },
                    ]}>
                    <Input
                      ref={autoCompleteRef}
                       onChange={(event) => setQuery(event.target.value)}
                       contentEditable={false}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                  label={<FormattedMessage id="address.mailingStreet" />} 
                  name="PersonMailingStreet"
                  rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id:'validation.mailingStreetIsRequired'
                        }),
                      },
                    ]}
                  >
                   <Input 
                      onChange={(e)=>clearAddressField()} 
                      disabled={updatePersonalDataState.isLoading} 
                    />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                  label={<FormattedMessage id="address.mailingCity" />} 
                  name="PersonMailingCity"
                  rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id:'validation.mailingCityIsRequired'
                        }),
                      },
                    ]}
                  >
                   <Input 
                      disabled={updatePersonalDataState.isLoading}
                      onChange={(e)=>clearAddressField()} 
                   />
                   
                  </Form.Item>
                </Col>
              </Row>

              
              <Row gutter={32}>
                
                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                  label={<FormattedMessage id="address.state" />} 
                  name="PersonMailingState"
                  rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id:'validation.stateIsRequired'
                        }),
                      },
                    ]}
                  >
                   <Input 
                        disabled={updatePersonalDataState.isLoading}
                        onChange={(e)=>clearAddressField()} 
                   />
                   
                  </Form.Item>
                </Col>

                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                  label={<FormattedMessage id="address.zipCode" />} 
                  name="PersonMailingPostalCode"
                  rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id:'validation.zipCodeIsRequired'
                        }),
                      },
                    ]}
                  >
                   <Input 
                        disabled={updatePersonalDataState.isLoading} 
                        onChange={(e)=>clearAddressField()} 
                   />
                  </Form.Item>
                </Col>
              </Row>

              
              <Row gutter={32}>
               

                <Col span={12} xs={24} lg={12}>
                  <Form.Item 
                  label={<FormattedMessage id="address.country" />} 
                  name="PersonMailingCountry"
                  rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id:'validation.countryIsRequired'
                        }),
                      },
                    ]}
                  >
                   <Input 
                        disabled={updatePersonalDataState.isLoading} 
                        onChange={()=>clearAddressField()} 
                    />
                  </Form.Item>
                </Col>
                
              </Row>


              <div className={applicationLayoutStyles.formFooter}>
                <div className={applicationLayoutStyles.formPrevious}>
                  {/* <Button
                      type="primary"
                      disabled={updatePersonalDataState.isLoading}

                    >
                      Previous
                    </Button> */}
                </div>
                <div className={applicationLayoutStyles.formSave}>
                  <Button
                    disabled={updatePersonalDataState.isLoading}
                    type="primary"
                    onClick={() => navigate("/dashboard")}
                  >
                    {<FormattedMessage id="nav.continueLater" />}
                  </Button>
                  <Button
                    disabled={updatePersonalDataState.isLoading}
                    loading={updatePersonalDataState.isLoading}
                    type="primary"
                    htmlType="submit"
                  >
                    {<FormattedMessage id="nav.next" />}
                  </Button>
                </div>
              </div>
            </Form>
          </Loader>
        </div>
      </ApplicationLayout>
    </div>
  );
};

export default RegisterPersonalInfo;

import React, {useEffect} from "react";
import { Navigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga";


export function PrivateRoute({ children }) {
  // ------- intialize custom hooks --------
  const location = useLocation()

  useEffect(() => {
    ReactGA.pageview(
      window.location.pathname + window.location.search,
      [])
  }, [location])

  const auth = localStorage.getItem("authToken");
  return auth ? (
    children
  ) : (
    <Navigate to={"/login?lastPath=" + window.location.pathname} />
  );
}

export function AuthRoute({ children }) {
   // ------- intialize custom hooks --------
   const location = useLocation()

   useEffect(() => {
     ReactGA.pageview(
       window.location.pathname + window.location.search,
       [])
   }, [location])


  const auth = localStorage.getItem("authToken");
  return auth ? <Navigate to="/" /> : children;
}

export function PublicRoute({ children }) {
   // ------- intialize custom hooks --------
   const location = useLocation()

   useEffect(() => {
     ReactGA.pageview(
       window.location.pathname + window.location.search,
       [])
   }, [location])

  const auth = localStorage.getItem("authToken");

  return auth ? <Navigate to="/dashboard" /> : children;
}

// ------------- import internal dependencies -----------
import * as types from "../../action-types";
import { getApplicantTest as intialState } from "store/initialState";

export const getApplicantQuestion = (state = intialState, action) => {
  switch (action.type) {
    case types.GET_QUESTION_START:
      return {
        ...state,
        isLoading: true,
      };
    case types.GET_QUESTION_SUCCESS:

      // ------------ check if all questions has been answered ------------
      const completedStatusLoad = action.payload.questions.filter((e) => e.Answer_Chosen_by_Applicant__c !== null).length === action.payload.length

      return {
        ...state,
        data: action.payload.questions,
        testScore: action.payload.Test_Score__c,
        testResult: action.payload.Test_Result__c,
        isLoading: false,
        isSuccessful: true,
        completedQuestion: completedStatusLoad
      };
    case types.SELECT_QUESTION_ANSWER:
      // ------------- make copy of data state array ----------
      const questions = [...state.data]

      // ------------ find index of answered question -----------
      const questionIndex = questions.findIndex((e) => e.Id === action.payload.id)
      questions[questionIndex].Answer_Chosen_by_Applicant__c = action.payload.answer

      // ------------ check if all questions has been answered ------------
      const completedStatus = questions.filter((e) => e.Answer_Chosen_by_Applicant__c !== null).length === questions.length

      if (questionIndex < -1) {
        return {
          ...state
        }
      }

      return{
        ...state,
        data: [...questions],
        completedQuestion: completedStatus
      }
    case types.GET_QUESTION_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
        completedQuestion: true
      };
    case types.GET_QUESTION_CLEANUP:
      return {
        ...state,
        isLoading: false,
        isSuccessful: false,
        error: null,
        completedQuestion: false,
        testScore: 0,
        testResult: ""
      };

    default:
      return state;
  }
};

export default getApplicantQuestion;

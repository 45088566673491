// ------------- import external dependencies --------------
import React, {useEffect, useState} from 'react'
import { Button } from 'antd';
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { FileFilled, ArrowLeftOutlined } from '@ant-design/icons';

// ---------------- import internal dependencies --------------
import ApplicationLayout from "components/Layouts/AuthLayout/application";
import Loader from "../../../../components/Loader";
// ------ style files ------
import applicationLayoutStyles from "components/Layouts/AuthLayout/application.module.css";
import "./style.scoped.css"
// ----- import asset files ------
import PreviewImg from  "../../../../assets/img/preview.png"
// ------ import actions -------
import {
  getSingleUpload,
  getSingleUploadCleanup,
} from "../../../../store/actions/get-single-upload";
// ------- import utils -------
import AxiosCall from "../../../../utils/axios"
import { FormattedMessage,useIntl } from 'react-intl';

function PreviewDocument() {
  // ----- custom hook intialization -----
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {id} = useParams()
  const intl = useIntl()
  // ---- state selectors ------
  const getSingleUploadState= useSelector(
    (s) => s.getSingleUpload
  );

  // ------ component state managers -------
  const [activeDoc, setActiveDoc] = useState(0)
  const [downloadObj, setDownloadObj] = useState(null)
  const [loading, setLoading] = useState(false)
  const [type, setType] = useState("")

  /**
   * Handle file base64 download
   * @param {string} id
   */
  const downloadFile = async (id) => {
    setLoading(true)
    try {
      const requestObj = {
        path: `upload/${id}/download`,
        method: "GET",
      }
      const  res  = await AxiosCall(requestObj);
      setDownloadObj(res.data)
      // const linkSource = `data:image/png;base64,${res.data.fileData}`;
      // const downloadLink = document.createElement("a");
      // downloadLink.href = linkSource;
      // downloadLink.download = `${Date.now()}`;
      // downloadLink.click();
    } catch (err) {
      console.log(err);
    }finally{
      setLoading(false)
    }
  }

  const downloadDocument = () => {
     const linkSource = `data:${type};base64,${downloadObj.fileData}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = `${Date.now()}`;
      downloadLink.click();
  }

  /**
   * Handle delete active file
   * @param {string} id
   */
   const deleteFile = async (reqId) => {
    try {
      const requestObj = {
        path: `upload/${reqId}`,
        method: "DELETE",
      }
      const  res  = await AxiosCall(requestObj);
      console.log(res);
      dispatch(getSingleUpload(id))

    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    dispatch(getSingleUpload(id))
    console.log(getSingleUploadState);
  }, [])

  useEffect(() => {
    if (getSingleUploadState.isSuccessful) {
      if (getSingleUploadState.data.length > 0) {
        downloadFile(getSingleUploadState.data[activeDoc ?? 0]?.Id ?? "")
        setType(getSingleUploadState.data[activeDoc ?? 0].ContentType)
      }
      // setFiles([...files, getSingleUploadState.data]);
      dispatch(getSingleUploadCleanup());
    } else if (getSingleUploadState.error) {
      dispatch(getSingleUploadCleanup());
    }
  }, [getSingleUploadState]);


  return (
    <>
      <ApplicationLayout>
        <div className={applicationLayoutStyles.mainContent}>
          <h2><FormattedMessage id="uploads.previewDocument" /></h2>

          {getSingleUploadState.isLoading &&
          <div style={{textAlign: "center", display: "block", fontSize: "1.5rem"}}><FormattedMessage id="ui.loading" /></div>}

          {!getSingleUploadState.isLoading && !getSingleUploadState?.data && <div>No data available</div>}

          {!getSingleUploadState.isLoading && getSingleUploadState?.data?.length > 0  && <>

              {/* ------ document view section ----- */}
          <div className="document-viewer">
            {/* <div className="preview-header">
              <span>Photo Identification</span>
              <span>Second document</span>
            </div> */}
            <div className="preview-pane">
              {!loading && downloadObj &&
              <>
                {type === "application/pdf" ?
                <embed src={`data:application/pdf;base64,${downloadObj.fileData}`} />
                :
                <img src={`data:image/png;base64, ${downloadObj.fileData}`} alt="Preview document"/>
              }
              </>}
              {loading && !downloadObj && <FileFilled style={{fontSize: "7rem", display: "block"}}/>}
              <div className="doc-info">
                <span>{!loading ? getSingleUploadState?.data[activeDoc]?.Name : <FormattedMessage id="ui.state.fetchingDocunent" />}</span>
              </div>

              {/* ------ download file section ------- */}
              {!loading && downloadObj && <div className="doc-actions">
                {/* <Button className="doc-btn" onClick={() => deleteFile(getSingleUploadState.data[activeDoc].Id)}>Delete</Button> */}
                <Button className="doc-btn__download" type="text" onClick={downloadDocument}>Download</Button>
              </div>}
            </div>

            {/* ------ file list section ------ */}
            <div className="document-previews">
              <div className="preview-img">
                {/* <img src={PreviewImg} alt="Preview image" className=""/> */}
                {
                  getSingleUploadState.data.map((doc, ind) => (
                    <FileFilled style={{fontSize: "4rem", display: "block", border: ind === activeDoc ? "1px solid #767676" : "" }} key={doc.ID} onClick={() => {
                      if(activeDoc !== ind){
                        setActiveDoc(ind)
                      }
                    }} />
                  ))
                }
              </div>
              {/* <Button className="doc-btn">
                <PlusOutlined /> Add another page
              </Button> */}
            </div>
          </div>

          {/* ------- document actions ------- */}
          <div className={applicationLayoutStyles.formFooter}>
              <div className={applicationLayoutStyles.formPrevious}>
                <Button
                onClick={()=>navigate(-1)}
                type="primary"
                htmlType="submit">
                  <ArrowLeftOutlined /> <FormattedMessage id="nav.back"/>
                </Button>
              </div>
              {/* <div className={applicationLayoutStyles.formSave}>
                <Button type="primary" onClick={()=>navigate("/dashboard")}>
                  Continue Later
                </Button>
                <Button
                type="primary"
                onClick={()=>navigate("/register/documents")}
                htmlType="submit">
                  Next
                </Button>
              </div> */}
           </div>
          </>}
        </div>
      </ApplicationLayout>
    </>
  )
}

export default PreviewDocument
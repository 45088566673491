export const language = {
  language:null,
  isSuccessfull:false
}

export const login = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const verify_otp = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const resend_otp = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const signUp = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const resetPassword = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const getPersonalData = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const updatePersonalData = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const addEducation = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const getAllEducations = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const deleteEducation = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const updateEducation = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const getSingleEducation = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const addEmployment = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const getAllEmployments = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const deleteEmployment = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const updateEmployment = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const getSingleEmployment = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const addConsumer = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const getAllConsumers = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const deleteConsumer = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const updateConsumer = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const getSingleConsumer = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const addLanguage = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const deleteLanguage = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const getAllLanguages = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const navAccess = {
  Can_Go_To_Related_Profile_Info__c:false,
  Can_Go_To_Employment_Test__c:false,
  Can_Go_To_Application_For_Employment__c:false,
  Can_Go_To_Documents_Uploads__c:false
}

export const getSingleLanguage = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const updateLanguage = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};


export const addEmergencyContact = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};


export const getAllEmergencyContacts = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const deleteEmergencyContact = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const updateEmergencyContact = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const getSingleEmergencyContact = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const getCaregiverApplicationData = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const updateCaregiverApplicationData = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const uploadFile = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const getAllRequiredDocuments = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const getSingleRequiredDocument = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const getSingleUpload = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
};

export const getAllNavMenus = {
  data: null,
};

// -------------- applicant test default state -----------
export const getApplicantTest = {
  isLoading: false,
  isSuccessful: false,
  data: null,
  error: null,
  completedQuestion: false,
  testScore: 0,
  testResult: "",
};

// ------------ applicant application default state -------------
export const getApplicationStage = {
  error: null,
  isLoading: false,
  isSuccessful: false,
  data: null
}

import React, { useState } from 'react';
import DefaultAuthLayout from 'components/Layouts/AuthLayout/default.js';
import defaultLayoutStyles from 'components/Layouts/AuthLayout/default.module.css';
import { Form, Input, Button, Radio, Select, Checkbox } from 'antd';
import { FormattedMessage } from 'react-intl';
import { NavLink } from 'react-router-dom';

const ResetPassword = () => {

    const { Option } = Select;
    const [form] = Form.useForm();
    const [requiredMark, setRequiredMarkType] = useState('optional');

    const onRequiredTypeChange = ({ requiredMarkValue }) => {
        setRequiredMarkType(requiredMarkValue);
    };
    
    const verticalFormStyle = {
        width: '100%',
        marginTop: '32px'
    }

    const buttonStyle = {

    }

  return (
    <div>
        <DefaultAuthLayout>
                <h5 className={defaultLayoutStyles.cardHeader}> <FormattedMessage id="auth.newPasswordCreationPrompt"/> </h5>
                

                <Form
                    form={form}
                    layout="vertical"
                    style={verticalFormStyle}
                    onValuesChange={onRequiredTypeChange}
                    >
                        <Form.Item label={<FormattedMessage id="auth.newPassword" />}>
                            <Input.Password  />
                        </Form.Item>

                        <Form.Item label={<FormattedMessage id="auth.confirmNewPassword" />}>
                            <Input.Password  />
                        </Form.Item>

                        <Form.Item >
                            <Button className='auth-btn--custom-auth' type="primary" htmlType="submit">
                           <FormattedMessage id="nav.continue" />
                            </Button>
                        </Form.Item>

                        <div>
                            <span><FormattedMessage id="auth.dontHaveAnAccount" /> <NavLink to={'/register'}> <FormattedMessage id="auth.register" /> </NavLink></span>
                        </div>
                </Form>
        </DefaultAuthLayout>
    </div>
  )
};

export default ResetPassword;

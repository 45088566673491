// ---------- import external dependencies -----------
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { Button, Modal } from "antd";

// ---------- import internal dependencies ------------
// import UnAuthLayout from "components/Layouts/UnAuthLayout";
import ApplicationLayout from "components/Layouts/AuthLayout/application";
import applicationLayoutStyles from "components/Layouts/AuthLayout/application.module.css";
import Question from "components/Questions";
import Seo from "shared/Seo";
import { questions } from "utils/testData/applicantQuestions";
import "./testapplication.scoped.css";

// ---------- redux actions ----------
import { getAllQuestions, submitQuestionsAnswer } from "../../../../store/actions/applicant-test";
import { navAccess } from "store/actions/nav-access";
//utils
import { navDict } from "../../../../utils/helpers/data";
import Notification from "../../../../utils/notification";
import { FormattedMessage } from "react-intl";

function TestApplication() {
  // ----------- intialize custom hooks ----------
  const dispatch = useDispatch();
  const user = useSelector((s) => s.getPersonalData.data);
  const applicantTest = useSelector((state) => state.getApplicantQuestion);
  const navigate= useNavigate();
  const location = useLocation();
  

  useEffect(() => {
    //console.log(user)
    if(user?.Can_Go_To_Employment_Test__c===false){
      Notification(
        'Access denied',
        "warning",
        `You are not yet allowed to access this section. Please make sure to complete all previous sections`
      );
      return navigate('/')
    }

    document.title = "Royal Care HR Portal - Employment Test"
    setLoading(true)
    dispatch(getAllQuestions());
  }, [user]);

  useEffect(() => {
   if (applicantTest.isSuccessful) {
    console.log("here")
    console.log(applicantTest)
    setPassStatus(applicantTest.testResult)
    setTestScore(applicantTest.testScore)
    setVisible(["Failed","Passed"].includes(applicantTest.testResult))
   }
   setLoading(false)
  }, [applicantTest])


  // ------------ application state managers -----------
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [passStatus, setPassStatus] = useState("")
  const [testScore, setTestScore] = useState("")

  const appType = "HHA";

  /**
   * Handle questions submission
   */
  const submitQuestions = async () => {
    setLoading(true)
    try {
      const res = await dispatch(submitQuestionsAnswer(applicantTest.data))
      console.log(res);
      if (res.data.Test_Result__c == "Passed") {
        setPassStatus(res.data.Test_Result__c)
        setTestScore(res.data.Test_Score__c)
        //TODO : display success modal (with button to [Continue to Application])
        setVisible(true)
        dispatch(navAccess({Can_Go_To_Application_For_Employment__c:true}))
        dispatch(navAccess({Can_Go_To_Documents_Uploads__c:true}))
      } else {
        setVisible(true)
        //TODO : display failure modal (with no option to close out of modal)
        // window.alert("Test Failed. Please seek assistance from Royal Care Staff");
      }
    } catch (error) {
      console.log(error)
    }finally{
      setLoading(false)
    }
  }

  const retakTest = () => {
    dispatch(getAllQuestions());
    setVisible(false)
  }

  const nextPath = navDict[appType]?.['steps'][navDict[appType]?.['steps']?.indexOf(location.pathname) + 1];

  let stepNum = navDict[appType]?.['steps']?.indexOf(location.pathname) + 1;
  let totalStepsCount = navDict[appType]?.['totalStepsCount'];
  
  return (
    <Seo>
      <div>
        <ApplicationLayout>
          <div className={applicationLayoutStyles.mainContent}>
            <section className="question-wrapper">
              <div className="question-wrap">
                {appType ? <span>
              <FormattedMessage id="nav.step" values={{stepNum,totalStepsCount}} /> : 
            </span>
            : null } <span>{<FormattedMessage id="profile.pageHeader" />}
          </span>
          <h2>{<FormattedMessage id="employmentTest.pagePrompt" />}</h2>
                <div>
                  {applicantTest.isLoading && <div>Loading</div>}
                  {applicantTest.isSuccessful &&
                    applicantTest.data.sort((a, b) => a.Question_Number__c - b.Question_Number__c).map((e, i) => (
                      <Question
                        key={e.Id}
                        number={e?.Question_Number__c}
                        queId={e.Id}
                        question={e.Question_Body__c}
                        answers={[
                          e.Option_A__c ?? null,
                          e.Option_B__c ?? null,
                          e.Option_C__c ?? null,
                          e.Option_D__c ?? null,
                        ].filter((e, i) => e !== null)}
                      />
                    ))}
                </div>
                <Button
                  className="btn block ml-auto"
                  type="primary"
                  disabled={!applicantTest.completedQuestion || loading}
                  onClick={submitQuestions}
                >
                  {<FormattedMessage id="nav.submit" />}
                </Button>
              </div>
            </section>
          </div>
        </ApplicationLayout>
      </div>
      <Modal visible={visible} footer={null} closable={false} width={900} maskStyle={{background: "#1B2C33D4"}}>
        <div className="submission-popup">
          <div className="pop-content">
            <span className="pop-content--heading">
              {
                passStatus === "Passed" ? 
                <FormattedMessage id="employmentTest.congratulations" /> : 
                <FormattedMessage id="employmentTest.pleaseRetake" />
              }
            </span>
            {passStatus === "Passed" ? 
              <span className="pop-content--message">
                <FormattedMessage id="employmentTest.testPassedWithScorePrefix" /> <br /> {testScore}%!
              </span> : 
              <span className="pop-content--message">
                <FormattedMessage id="employmentTest.testFailedWithAssistanceInstructions" />
              </span>}
           { passStatus === "Passed" && <Button
                className="btn w-full mt-4 block"
                type="primary"
                size="sm"
                disabled={loading}
                onClick={() => navigate( navDict[appType]['steps'][navDict[appType]['steps'].indexOf(location.pathname) + 1],{state:{ applicantType : appType }})}
              >
                {<FormattedMessage id="nav.continue" />}
              </Button>}

              { applicantTest.testResult === "Failed" && <Button
                className="btn w-full mt-4 block"
                type="primary"
                size="sm"
                hidden="true"
                onClick={retakTest}
              >
                {<FormattedMessage id="employmentTest.retake" />}
              </Button>}
          </div>
        </div>
      </Modal>
     </Seo>
  );
}

export default TestApplication;

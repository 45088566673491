import {
  Form,
  Input,
  Button,
  Select,
  Row,
  Col,
  DatePicker,
  Collapse,
} from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import applicationLayoutStyles from "components/Layouts/AuthLayout/application.module.css";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
//components
import ModalComponent from "../../../../components/Modal";

const { Panel } = Collapse;
const { Option } = Select;

const EmploymentForm = ({
  formType,
  disabled,
  data,
  deleteSingleEmployment,
  deleteEmploymentState,
  UpdatedForm,
  save,
  formName,
  getSingleEmploymentDataFunc,
  updateEmployment,
  closeReturnData,
  openReturnData,
  grouped,
  defaultActiveKey,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl()
  const onFinish = (values) => {
    dispatch(updateEmployment(values.Id, values));
  };

  const [Name, setName] = useState(null);
  const [Employed_From_Date__c, setEmployed_From_Date__c] = useState(null);
  const [Employed_To_Date__c, setEmployed_To_Date__c] = useState(null);
  const [Job_Title__c, setJob_Title__c] = useState(null);
  const [Work_Performed__c, setWork_Performed__c] = useState(null);
  const [Name_of_supervisor__c, setName_of_supervisor__c] = useState(null);
  const [Phone__c, setPhone__c] = useState(null);
  const [Street_Address__c, setStreet_Address__c] = useState(null);
  const [City__c, setCity__c] = useState(null);
  const [State__c, setState__c] = useState(null);
  const [Reason_for_leaving__c, setReason_for_leaving__c] = useState(null);
  const [Can_be_contacted__c, setCan_be_contacted__c] = useState(null);
  const [Postal_Code__c, setPostal_Code__c] = useState(null);
  const [Supervisor_Email__c, setSupervisor_Email__c] = useState(null);
  const [Supervisor_Title__c, setSupervisor_Title__c] = useState(null);
  const [Hourly_Rate_Salary_Starting__c, setHourly_Rate_Salary_Starting__c] =
    useState(null);
  const [Hourly_Rate_Salary_Final__c, setHourly_Rate_Salary_Final__c] =
    useState(null);
  const [Why_not_contact__c, setWhy_not_contact__c] = useState(null);

  const [fromCloseData, setFromCloseData] = useState({
    data: null,
    error: null,
    isLoading: false,
    isSuccessful: false,
  });

  const handleSingleEmploymentGet = (id) => {
    getSingleEmploymentDataFunc(id);
  };

  useEffect(() => {
    setFromCloseData(closeReturnData);
  }, [closeReturnData]);

  return (
    <Collapse
      defaultActiveKey={"1"}
      accordion
    >
      <Panel collapsible={true} header={data.Name} key={"1"}>
        <Form
          className={applicationLayoutStyles.updateForm}
          form={formType}
          name={formName}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            style={{ display: "none", visibility: "hidden" }}
            name="Id"
          ></Form.Item>

          <Row>
            <Col span={24}>
              <Form.Item
                label={<FormattedMessage id="employmentHistory.companyName" />}
                name="Name"
                rules={[
                  {
                    required: true,
                    message: "Company's required",
                  },
                ]}
              >
                <Input
                  placeholder={data.Name}
                  disabled={disabled}
                  value={Name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                label={<FormattedMessage id="profile.streetAddress" />}
                name="Street_Address__c"
                rules={[
                  {
                    required: true,
                    message: "Street is required",
                  },
                ]}
              >
                <Input
                  placeholder={data.Street_Address__c}
                  disabled={disabled}
                  value={Street_Address__c}
                  onChange={(e) => {
                    setStreet_Address__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                label={<FormattedMessage id="address.city" />}
                name="City__c"
                rules={[
                  {
                    required: true,
                    message: "City is required",
                  },
                ]}
              >
                <Input
                  placeholder={data.City__c}
                  disabled={disabled}
                  value={City__c}
                  onChange={(e) => {
                    setCity__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                label={<FormattedMessage id='address.state' />}
                name="State__c"
                rules={[
                  {
                    required: true,
                    message: "State is required",
                  },
                ]}
              >
                <Input
                  placeholder={data.State__c}
                  disabled={disabled}
                  value={State__c}
                  onChange={(e) => {
                    setState__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: true,
                    message: "Zip Code is required",
                  },
                ]}
              label="Zipcode" name="Postal_Code__c">
                <Input
                  placeholder={data.Postal_Code__c}
                  disabled={disabled}
                  value={Postal_Code__c}
                  onChange={(e) => {
                    setPostal_Code__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                label={<FormattedMessage id="employmentHistory.reasonForLeaving" />}
                name="Reason_for_leaving__c"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <Input.TextArea
                  placeholder={data.Reason_for_leaving__c}
                  disabled={disabled}
                  value={Reason_for_leaving__c}
                  onChange={(e) => {
                    setReason_for_leaving__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                label={<FormattedMessage id="employmentHistory.canContactEmployer" />}
                name="Can_be_contacted__"
              >
                {grouped ? (
                  <Input placeholder={Can_be_contacted__c} />
                ) : (
                  <Select
                    placeholder=""
                    allowClear
                    disabled={disabled}
                    value={Can_be_contacted__c}
                    onChange={(e) => {
                      setCan_be_contacted__c(e);
                    }}
                  >
                    <Option value="Yes, it's okay to contact this person">
                       <FormattedMessage id="choices.employmentHistory.canContactEmployer.okToContact" />
                    </Option>
                    <Option value="Please do not contact this person">
                      <FormattedMessage id="choices.employmentHistory.canContactEmployer.PleaseDoNotContact" />
                    </Option>
                  </Select>
                )}
              </Form.Item>
            </Col>
          </Row>

          <Row >
            <Col  span={24}>
              <Form.Item label={<FormattedMessage id="employmentHistory.canContactEmployerDetails" />} name="Why_not_contact__c">
                <Input
                  disabled={disabled}
                  value={Why_not_contact__c}
                  onChange={(e) => {
                    setWhy_not_contact__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <div className={applicationLayoutStyles.formItemHeader}>
            <span><FormattedMessage id='employmentHistory.jobInformation' /></span>
          </div>

          <Row gutter={32}>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                label={<FormattedMessage id='employmentHistory.jobTitle' />}
                name="Job_Title__c"
                rules={[
                  {
                    required: true,
                    message: "Job Title is required",
                  },
                ]}
              >
                <Input
                  placeholder={data.Job_Title__c}
                  disabled={disabled}
                  value={Job_Title__c}
                  onChange={(e) => {
                    setJob_Title__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} lg={12}>
              <Form.Item label={<FormattedMessage id="employmentHistory.responsibilities" />} name="Work_Performed__c">
                <Input
                  placeholder={data.Work_Performed__c}
                  disabled={disabled}
                  value={Work_Performed__c}
                  onChange={(e) => {
                    setWork_Performed__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                label={<FormattedMessage id="employmentHistory.employedFromDate"/>}
                name="Employed_From_Date__c"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <DatePicker
                  placeholder={data.Employed_From_Date__c}
                  disabled={disabled}
                  value={Employed_From_Date__c}
                  onChange={(e) => {
                    setEmployed_From_Date__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                label={<FormattedMessage id='employmentHistory.employedToDate' />}
                name="Employed_To_Date__c"
                rules={[
                  {
                    required: true,
                    message: "This field is required",
                  },
                ]}
              >
                <DatePicker
                  placeholder={data.Employed_To_Date__c}
                  disabled={disabled}
                  value={Employed_To_Date__c}
                  onChange={(e) => {
                    setEmployed_To_Date__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                label={<FormattedMessage id='employmentHistory.startSalaryHourly' />}
                name="Hourly_Rate_Salary_Starting__c"
              >
                <Input
                  disabled={disabled}
                  placeholder={data.Hourly_Rate_Salary_Starting__c}
                  value={Hourly_Rate_Salary_Starting__c}
                  onChange={(e) => {
                    setHourly_Rate_Salary_Starting__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                label={<FormattedMessage id="employmentHistory.finalSalaryHourly" />}
                name="Hourly_Rate_Salary_Final__c"
              >
                <Input
                  placeholder={data.Hourly_Rate_Salary_Final__c}
                  disabled={disabled}
                  value={Hourly_Rate_Salary_Final__c}
                  onChange={(e) => {
                    setHourly_Rate_Salary_Final__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <div className={applicationLayoutStyles.formItemHeader}>
            <span><FormattedMessage id="employmentHistory.supervisors" /></span>
          </div>

          <Row>
            <Col span={24}>
              <Form.Item
                label={<FormattedMessage id='employmentHistory.nameOfSupervisor' />}
                name="Name_of_supervisor__c"
              >
                <Input
                  placeholder={data.Name_of_supervisor__c}
                  disabled={disabled}
                  value={Name_of_supervisor__c}
                  onChange={(e) => {
                    setName_of_supervisor__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <Form.Item label={<FormattedMessage id='employmentHistory.jobTitle' />} name="Supervisor_Title__c">
                <Input
                  disabled={disabled}
                  placeholder={data.Supervisor_Title__c}
                  value={Supervisor_Title__c}
                  onChange={(e) => {
                    setSupervisor_Title__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          <Row gutter={32}>
            <Col span={12} xs={24} lg={12}>
              <Form.Item label={<FormattedMessage id='employmentHistory.phoneNumber' />} name="Phone__c">
                <Input
                  disabled={disabled}
                  placeholder={data.Phone__c}
                  value={Phone__c}
                  onChange={(e) => {
                    setPhone__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                label={<FormattedMessage id="employmentHistory.email" />}
                name="Supervisor_Email__c"
                rules={[
                  {
                    type: "email",
                    message: "The input is not valid E-mail!",
                  },
                ]}
              >
                <Input
                  disabled={disabled}
                  placeholder={data.Supervisor_Email__c}
                  value={Supervisor_Email__c}
                  onChange={(e) => {
                    setSupervisor_Email__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          {deleteEmploymentState === null ? null : (
            <div
              className={applicationLayoutStyles.addNewItemWrapper}
              style={{ width: "100%", position: "relative" }}
            >
              <Button
                loading={deleteEmploymentState.isLoading}
                type="button"
                onClick={() => deleteSingleEmployment(data.Id)}
                style={{ position: "absolute", right: 0 }}
              >
                <DeleteFilled /> Delete{" "}
              </Button>
            </div>
          )}
          {save === true ? (
            <ModalComponent
              id={data.Id}
              getFunc={handleSingleEmploymentGet}
              title={"Update Employment"}
              buttonText={<><EditFilled/>  Edit</>}
              closeReturnData={fromCloseData}
              openReturnData={openReturnData}
            >
              {UpdatedForm}
            </ModalComponent>
          ) : (
            <Button
              htmlType="submit"
              disabled={closeReturnData.isLoading}
              loading={closeReturnData.isLoading}
            >
              <FormattedMessage id="nav.save" />
            </Button>
          )}
        </Form>
      </Panel>
    </Collapse>
  );
};

export default EmploymentForm;

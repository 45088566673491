import * as types from '../../action-types'
import AxiosCall from 'utils/axios'
import ErrorHandler from 'utils/error-handler'


export const resend_otp_start = ()=>({
    type: types.RESEND_OTP_START
})

export const resend_otp_success = (payload)=>({
    type: types.RESEND_OTP_SUCCESS,
    payload
})

export const resend_otp_fail = (payload)=>({
    type: types.RESEND_OTP_FAIL,
    payload
})

export const resend_otp_cleanUp = ()=>({
    type: types.RESEND_OTP_CLEANUP
})

export const resend_otp = payload=> async dispatch=> {
    //console.log(payload);
    try{
        dispatch(resend_otp_start())
        const requestBody = {
            path: "auth/resend_otp",
            method: "GET"
        }

        const response = await AxiosCall(requestBody)
        dispatch(resend_otp_success(response.data))
        console.log(response.data)
    }catch(err){
        
        const error = ErrorHandler(err)
        console.log(error)
        dispatch(resend_otp_fail(error))
        
        
    }
}
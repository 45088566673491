import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const updateEducationStart = () => ({
  type: types.UPDATE_EDUCATION_START
});

export const updateEducationSuccess = payload => ({
  type: types.UPDATE_EDUCATION_SUCCESS,
  payload
});

export const updateEducationFail = payload => ({
  type: types.UPDATE_EDUCATION_FAIL,
  payload
});

export const updateEducationCleanup = () =>({
  type: types.UPDATE_EDUCATION_CLEANUP
});

export const updateEducation = (educationId, payload) => async dispatch => {
  try {

    dispatch(updateEducationStart());
    const requestObj = {
      path: `educations/${educationId}`,
      method: "PATCH",
      data: payload,
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(updateEducationSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(updateEducationFail(error));
  }
}

import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const deleteLanguageStart = () => ({
  type: types.DELETE_LANGUAGE_START
});

export const deleteLanguageSuccess = payload => ({
  type: types.DELETE_LANGUAGE_SUCCESS,
  payload
});

export const deleteLanguageFail = payload => ({
  type: types.DELETE_LANGUAGE_FAIL,
  payload
});

export const deleteLanguageCleanup = () =>({
  type: types.DELETE_LANGUAGE_CLEANUP
});

export const deleteLanguage = languageId => async dispatch => {
  try {

    dispatch(deleteLanguageStart());
    const requestObj = {
      path: `language/${languageId}`,
      method: "DELETE",
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(deleteLanguageSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(deleteLanguageFail(error));
  }
}

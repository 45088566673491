import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const updateConsumerStart = () => ({
  type: types.UPDATE_CONSUMER_START
});

export const updateConsumerSuccess = payload => ({
  type: types.UPDATE_CONSUMER_SUCCESS,
  payload
});

export const updateConsumerFail = payload => ({
  type: types.UPDATE_CONSUMER_FAIL,
  payload
});

export const updateConsumerCleanup = () =>({
  type: types.UPDATE_CONSUMER_CLEANUP
});

export const updateConsumer = (educationId, payload) => async dispatch => {
  try {

    dispatch(updateConsumerStart());
    const requestObj = {
      path: `consumers/${educationId}`,
      method: "PATCH",
      data: payload,
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(updateConsumerSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(updateConsumerFail(error));
  }
}

import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getSingleConsumerStart = () => ({
  type: types.GET_SINGLE_CONSUMER_START
});

export const getSingleConsumerSuccess = payload => ({
  type: types.GET_SINGLE_CONSUMER_SUCCESS,
  payload
});

export const getSingleConsumerFail = payload => ({
  type: types.GET_SINGLE_CONSUMER_FAIL,
  payload
});

export const getSingleConsumerCleanup = () =>({
  type: types.GET_SINGLE_CONSUMER_CLEANUP
});

export const getSingleConsumer = consumerId => async dispatch => {
  try {

    dispatch(getSingleConsumerStart());
    const requestObj = {
      path: `consumers/${consumerId}`,
      method: "GET",
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(getSingleConsumerSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getSingleConsumerFail(error));
  }
}

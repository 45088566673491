import React, { useEffect, useState, useRef } from "react";
import ApplicationLayout from "components/Layouts/AuthLayout/application";
import applicationLayoutStyles from "components/Layouts/AuthLayout/application.module.css";
import { PhoneNumberValidation } from "../../../../utils/registration-form-validators/index";
import CountryPhoneInput from "antd-country-phone-input";

import {
  Form,
  Input,
  Button,
  Row,
  Select,
  Col,
  DatePicker,
  message,
} from "antd";
import { SaveOutlined, SyncOutlined } from "@ant-design/icons";

import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import moment from "moment";

import { FormattedMessage, useIntl } from "react-intl";

//actions
import {
  addEmployment,
  addEmploymentCleanup,
} from "../../../../store/actions/add-employment";
import {
  deleteEmployment,
  deleteEmploymentCleanup,
} from "../../../../store/actions/delete-employment";
import {
  getAllEmployments,
  getAllEmploymentsCleanup,
} from "../../../../store/actions/get-all-employments/index";
import {
  updateEmployment,
  updateEmploymentCleanup,
} from "../../../../store/actions/update-employment/index";
import {
  getSingleEmployment,
  getSingleEmploymentCleanup,
} from "../../../../store/actions/get-single-employment/index";

//utils
import Notification from "../../../../utils/notification";
import Loader from "../../../../components/Loader";
import { loadScript, handleScriptLoad } from "../../../../utils/helpers";
import { navDict } from "../../../../utils/helpers/data";

//components
import EmploymentForm from "./Form.js";

const { Option } = Select;
let autoComplete;
const RegisterEmploymentInfo = () => {
  const location = useLocation();
  const appType = location.state?.applicantType ?? "HHA";

  //hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [newForm] = Form.useForm();
  const user = useSelector((s) => s.getPersonalData.data);
  const addEmploymentState = useSelector((state) => state.addEmployment);
  const deleteEmploymentState = useSelector((state) => state.deleteEmployment);
  const [disabledButton, setDisabledButton] = useState(false);
  const getAllEmploymentsState = useSelector(
    (state) => state.getAllEmployments
  );
  const updateEmploymentState = useSelector((state) => state.updateEmployment);
  const getSingleEmploymentState = useSelector(
    (state) => state.getSingleEmployment
  );
  const dateFormat = "MM/DD/YYYY";

  //state
  const [allEmployments, setAllEmployments] = useState([]);
  const [Street_Address__c, setStreet_Address__c] = useState("");
  const [City__c, setCity__c] = useState("");
  const [State__c, setState__c] = useState("");
  const [Country__c, setCountry__c] = useState("");
  const [Postal_Code__c, setPostal_Code__c] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);

  /**
   * Handle a
   * @param {string} addess
   */
  const updateAddressField = (address) => {
    setQuery(address);
    form.setFieldsValue({ address: address });
  };

  //functions for api calls
  const createEmployment = (values) => {
    values.Phone__c=`+${values.Phone__c.code}${values.Phone__c.phone}`;
    values.Street_Address__c = Street_Address__c;
    values.City__c = City__c;
    values.State__c = State__c;
    values.Country__c = Country__c;
    values.Postal_Code__c = Postal_Code__c;
    dispatch(addEmployment(values));
  };
  const deleteSingleEmployment = (id) => {
    dispatch(deleteEmployment(id));
  };
  const getSingleEmploymentData = (id) => {
    dispatch(getSingleEmployment(id));
  };
  const intl =useIntl()

  //useEffect for api calls
  useEffect(() => {
    if(user?.Can_Go_To_Related_Profile_Info__c===false){
      Notification(
        'Access denied',
        "warning",
        `You are not yet allowed to access this section. Please make sure to complete all previous sections`
      );
      return navigate('/')
    }
    document.title = document.title = `${intl.formatMessage({id:"web.websiteTitle"})}-${intl.formatMessage({id:'menu.employmentInformation'})}`
    window.scrollTo(0,0)
    dispatch(getAllEmployments()); // ----------- handle google place autocomplete feture on mount ----------
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API}&libraries=places`,
      () =>
        handleScriptLoad(
          autoComplete,
          updateAddressField,
          autoCompleteRef.current.input
        )
    );
  }, [intl, user]);

  useEffect(() => {
    if (updateEmploymentState.isSuccessful) {
      message.loading({
        content: intl.formatMessage({id:'ui.state.updating'}),
        key: "updatable",
        duration: 1,
      });
      dispatch(getAllEmployments());
      dispatch(updateEmploymentCleanup());
    } else if (updateEmploymentState.error) {
      if (Array.isArray(updateEmploymentState.error)) {
        Notification(intl.formatMessage({id:'notification.failed'}), "error", updateEmploymentState.error[0].msg);
      } else Notification(intl.formatMessage({id:'notification.failed'}), "error", intl.formatMessage({id:'notification.employment.update.failed'}));
      dispatch(updateEmploymentCleanup());
    }
  }, [updateEmploymentState]);

  useEffect(() => {
    if (deleteEmploymentState.isSuccessful) {
      dispatch(getAllEmployments());
      dispatch(deleteEmploymentCleanup());
    } else if (deleteEmploymentState.error) {
      if (Array.isArray(deleteEmploymentState.error)) {
        Notification(intl.formatMessage({id:'notification.failed'}), "error", deleteEmploymentState.error[0].msg);
      } else Notification(intl.formatMessage({id:'notification.failed'}), "error", intl.formatMessage({id:'notification.employment.delete.failed'}));
      dispatch(deleteEmploymentCleanup());
    }
  }, [deleteEmploymentState]);

  useEffect(() => {
    if (getAllEmploymentsState.isSuccessful) {
      let employments = getAllEmploymentsState.data.map((item) => {
        return {
          ...item,
          address:
            (item?.PersonMailingAddress?.Street_Address__c === undefined
              ? ""
              : item?.Street_Address__c + ", ") +
            (item?.City__c === undefined ? "" : item?.City__c + ", ") +
            (item?.State__c === undefined ? "" : item?.State__c + " ") +
            (item?.Postal_Code__c === undefined
              ? ""
              : item?.Postal_Code__c + " ") +
            (item?.Country__c === undefined ? "" : item?.Country__c),
        };
      });
      setAllEmployments(employments.reverse());
      dispatch(getAllEmploymentsCleanup());
    } else if (getAllEmploymentsState.error) {
      dispatch(getAllEmploymentsCleanup());
    }
  }, [getAllEmploymentsState]);

  useEffect(() => {
    if (addEmploymentState.isSuccessful) {
      Notification(intl.formatMessage({id:'notification.success'}), "success", intl.formatMessage({id:'notification.employment.add.success'}));
      form.resetFields();
      Reset();
      dispatch(getAllEmployments());
      dispatch(addEmploymentCleanup());
    } else if (addEmploymentState.error) {
      if (Array.isArray(addEmploymentState.error)) {
        Notification(intl.formatMessage({id:'notification.failed'}), "error", addEmploymentState.error[0].msg);
      } else Notification(intl.formatMessage({id:'notification.failed'}), "error", intl.formatMessage({id:'notification.employment.add.failed'}));

      dispatch(addEmploymentCleanup());
    }
  }, [addEmploymentState]);

  useEffect(() => {
    let address = query.split(", ");

    setStreet_Address__c(address[address.length - 4]);
    setCity__c(address[address.length - 3]);
    setCountry__c(address[address.length - 1]);
    setState__c(address[address.length - 2]?.split(" ")[0]);
    setPostal_Code__c(address[address.length - 2]?.split(" ")[1]);
  }, [query]);

  useEffect(() => {
    if (getSingleEmploymentState.isSuccessful) {
      newForm.setFieldsValue({
        Name: getSingleEmploymentState.data.Name,
        Employed_From_Date__c: moment(
          getSingleEmploymentState.data.Employed_From_Date__c,
          dateFormat
        ),
        Employed_To_Date__c: moment(
          getSingleEmploymentState.data.Employed_To_Date__c,
          dateFormat
        ),
        Job_Title__c: getSingleEmploymentState.data.Job_Title__c,
        Work_Performed__c: getSingleEmploymentState.data.Work_Performed__c,
        Name_of_supervisor__c:
          getSingleEmploymentState.data.Name_of_supervisor__c,
        Phone__c: getSingleEmploymentState.data.Phone__c,
        Street_Address__c: getSingleEmploymentState.data.Street_Address__c,
        Street_Address_2__c: getSingleEmploymentState.data.Street_Address_2__c,
        City__c: getSingleEmploymentState.data.City__c,
        State__c: getSingleEmploymentState.data.State__c,
        Reason_for_leaving__c:
          getSingleEmploymentState.data.Reason_for_leaving__c,
        Can_be_contacted__c: getSingleEmploymentState.data.Can_be_contacted__c,
        Postal_Code__c: getSingleEmploymentState.data.Postal_Code__c,
        Supervisor_Email__c: getSingleEmploymentState.data.Supervisor_Email__c,
        Supervisor_Title__c: getSingleEmploymentState.data.Supervisor_Title__c,
        Hourly_Rate_Salary_Starting__c:
          getSingleEmploymentState.data.Hourly_Rate_Salary_Starting__c,
        Hourly_Rate_Salary_Final__c:
          getSingleEmploymentState.data.Hourly_Rate_Salary_Final__c,
        Why_not_contact__c: getSingleEmploymentState.data.Why_not_contact__c,
        Id: getSingleEmploymentState.data.Id,
        address:
          (getSingleEmploymentState?.data?.PersonMailingAddress
            ?.Street_Address__c === undefined
            ? ""
            : getSingleEmploymentState?.data?.Street_Address__c + ", ") +
          (getSingleEmploymentState?.data?.City__c === undefined
            ? ""
            : getSingleEmploymentState?.data?.City__c + ", ") +
          (getSingleEmploymentState?.data?.State__c === undefined
            ? ""
            : getSingleEmploymentState?.data?.State__c + " ") +
          (getSingleEmploymentState?.data?.Postal_Code__c === undefined
            ? ""
            : getSingleEmploymentState?.data?.Postal_Code__c + ", ") +
          (getSingleEmploymentState?.data?.Country__c === undefined
            ? ""
            : getSingleEmploymentState?.data?.Country__c),
      });
      dispatch(getSingleEmploymentCleanup());
    } else if (getSingleEmploymentState.error) {
      if (Array.isArray(getSingleEmploymentState.error)) {
        Notification(intl.formatMessage({id:'notification.failed'}), "error", getSingleEmploymentState.error[0].msg);
      } else Notification(intl.formatMessage({id:'notification.failed'}), "error", intl.formatMessage({id:'notification.employment.get.failed'}));
      dispatch(getSingleEmploymentCleanup());
    }
  }, [getSingleEmploymentState]);
  const Reset = () => {
    form.resetFields();
    setDisabledButton(false);
  };

  const onChange = () => {
    const formValues = form.getFieldsValue();
    const {
      Name,
      Employed_From_Date__c,
      Employed_To_Date__c,
      Job_Title__c,
      Work_Performed__c,
      Name_of_supervisor__c,
      Phone__c,
      address,
      Reason_for_leaving__c,
      Can_be_contacted__c,
      Postal_Code__c,
      Supervisor_Email__c,
      Supervisor_Title__c,
      Hourly_Rate_Salary_Starting__c,
      Hourly_Rate_Salary_Final__c,
      Why_not_contact__c,
    } = formValues;

    if (
      Name?.length > 0 ||
      Employed_From_Date__c?.length > 0 ||
      Employed_To_Date__c?.length > 0 ||
      Job_Title__c?.length > 0 ||
      Work_Performed__c?.length > 0 ||
      Name_of_supervisor__c?.length > 0 ||
      Phone__c?.length > 0 ||
      address?.length > 0 ||
      Reason_for_leaving__c?.length > 0 ||
      Can_be_contacted__c?.length > 0 ||
      Postal_Code__c?.length > 0 ||
      Supervisor_Email__c?.length > 0 ||
      Supervisor_Title__c?.length > 0 ||
      Hourly_Rate_Salary_Starting__c?.length > 0 ||
      Hourly_Rate_Salary_Final__c?.length > 0 ||
      Why_not_contact__c?.length > 0
    ) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  };

  let stepNum = navDict[appType]?.['steps']?.indexOf(location.pathname) + 1;
  let totalStepsCount = navDict[appType]?.['totalStepsCount'];

  return (
    <div>
      <ApplicationLayout>
        <div className={applicationLayoutStyles.mainContent}>
          {appType ? <span>
              <FormattedMessage id="nav.step" values={{stepNum,totalStepsCount}} /> : 
            </span>
            : null } <span>{<FormattedMessage id="profile.pageHeader" />}
          </span>
          <h2>{<FormattedMessage id="employmentHistory.pagePrompt" />}</h2>
          <Loader isLoading={addEmploymentState.isLoading} text={<FormattedMessage id="ui.loading" />}>
            <Form
              form={form}
              name={"employment"}
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
              onFinish={createEmployment}
              onChange={onChange}
            >
              <div className={applicationLayoutStyles.formItemHeader}>
                <span className="form-heading">{<FormattedMessage id="employmentHistory.companySectionHeader" />}</span>
              </div>

              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<FormattedMessage id="employmentHistory.companyName" />}
                    name="Name"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="validation.companyIsRequired" />,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: "Address is required",
                      },
                    ]}
                    label={<FormattedMessage id="employmentHistory.Address" />}
                    name="address"
                  >
                    <Input
                      placeholder="Enter Address"
                      ref={autoCompleteRef}
                      onChange={(event) => setQuery(event.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="employmentHistory.reasonForLeaving" />}
                    name="Reason_for_leaving__c"
                    rules={[
                      {
                        required: true,
                        message:<FormattedMessage id="validation.genericFieldIsRequired" />,
                      },
                    ]}
                  >
                    <Input.TextArea />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="employmentHistory.canContactEmployer" />}
                    name="Can_be_contacted__"
                  >
                    <Select placeholder="" allowClear>
                      <Option value="Yes, it's okay to contact this person">
                        {<FormattedMessage id="choices.employmentHistory.canContactEmployer.okToContact" />}
                      </Option>
                      <Option value="Please do not contact this person">
                        {<FormattedMessage id="choices.employmentHistory.canContactEmployer.PleaseDoNotContact" />}
                      </Option>
                    </Select>
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item label={<FormattedMessage id="employmentHistory.canContactEmployerDetails" />} name="Why_not_contact__c">
                    <Input.TextArea />
                  </Form.Item>
                </Col>
              </Row>

              <div className={applicationLayoutStyles.formItemHeader}>
                <span className="form-heading">{<FormattedMessage id="employmentHistory.jobInformation" />}</span>
              </div>

              <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="employmentHistory.jobTitle" />}
                    name="Job_Title__c"
                    rules={[
                      {
                        required: true,
                        message: "Job Title is required",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item label={<FormattedMessage id="employmentHistory.responsibilities" />} name="Work_Performed__c">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="employmentHistory.employedFromDate" />}
                    name="Employed_From_Date__c"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <DatePicker format={dateFormat}/>
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="employmentHistory.employedToDate" />}
                    name="Employed_To_Date__c"
                    rules={[
                      {
                        required: true,
                        message: "This field is required",
                      },
                    ]}
                  >
                    <DatePicker format={dateFormat}/>
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={32} hidden="true">
                <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="employmentHistory.startSalaryHourly" />}
                    name="Hourly_Rate_Salary_Starting__c"
                  >
                    <Input type="number" min={1} />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="employmentHistory.finalSalaryHourly" />}
                    name="Hourly_Rate_Salary_Final__c"
                  >
                    <Input type="number" min={1} />
                  </Form.Item>
                </Col>
              </Row>

              <div className={applicationLayoutStyles.formItemHeader}>
                <span className="form-heading">{<FormattedMessage id="employmentHistory.supervisors" />}</span>
              </div>

              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<FormattedMessage id="employmentHistory.nameOfSupervisor" />}
                    name="Name_of_supervisor__c"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item label={<FormattedMessage id="employmentHistory.jobTitle" />}name="Supervisor_Title__c">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item

                  label={<FormattedMessage id="employmentHistory.phoneNumber" />} name="Phone__c"

                  rules={[
                    {
                      required: true,
                    },
                    {
                      validator: PhoneNumberValidation,
                    },
                  ]}
                  initialValue={{
                    short: "us",
                  }}
                >
                  <CountryPhoneInput
                    onChange={(p) => {
                      setPhoneNumber(`+${p.code}${p.phone}`);
                    }}
                  />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="employmentHistory.email" />}
                    name="Supervisor_Email__c"
                    rules={[
                      {
                        type: "email",
                        message: "The input is not valid E-mail!",
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>

              {disabledButton === true ? (
                <div className={applicationLayoutStyles.addNewItemWrapper}>
                  <button onClick={Reset} type="button">
                    <SyncOutlined  />{<FormattedMessage id="nav.reset" />}
                  </button>
                  <button type="submit">
                    <SaveOutlined  />{<FormattedMessage id="nav.save" />}
                  </button>
                </div>
              ) : null}
            </Form>
          </Loader>

          <Loader
            isLoading={getAllEmploymentsState.isLoading}
            text={<FormattedMessage id="ui.loading" />}
          >
            {allEmployments.map((a, i) => (
              <EmploymentForm
                UpdatedForm={
                  <EmploymentForm
                    data={{
                      Name: a.Name,
                      Id: a.Id,
                      Employed_From_Date__c: a.Employed_From_Date__c,
                      Employed_To_Date__c: a.Employed_To_Date__c,
                      Job_Title__c: a.Job_Title__c,
                      Work_Performed__c: a.Work_Performed__c,
                      Name_of_supervisor__c: a.Name_of_supervisor__c,
                      Phone__c: a.Phone__c,
                      Street_Address__c: a.Street_Address__c,
                      Street_Address_2__c: a.Street_Address_2__c,
                      City__c: a.City__c,
                      State__c: a.State__c,
                      Reason_for_leaving__c: a.Reason_for_leaving__c,
                      Can_be_contacted__c: a.Can_be_contacted__c,
                      Postal_Code__c: a.Postal_Code__c,
                      Supervisor_Email__c: a.Supervisor_Email__c,
                      Supervisor_Title__c: a.Supervisor_Title__c,
                      Hourly_Rate_Salary_Starting__c:
                        a.Hourly_Rate_Salary_Starting__c,
                      Hourly_Rate_Salary_Final__c:
                        a.Hourly_Rate_Salary_Final__c,
                      Why_not_contact__c: a.Why_not_contact__c,
                      address: a.address,
                    }}
                    disabled={false}
                    formType={newForm}
                    deleteSingleEmployment={null}
                    deleteEmploymentState={null}
                    save={false}
                    formName={"updateEmployment"}
                    dispatch={dispatch}
                    updateEmployment={updateEmployment}
                    closeReturnData={{
                      data: null,
                      error: null,
                      isLoading: false,
                      isSuccessful: false,
                    }}
                    openReturnData={null}
                    grouped={false}
                    defaultActiveKey={true}
                  />
                }
                updateForm={null}
                updateEmployment={null}
                formName={"allEmployment"}
                key={i}
                data={a}
                disabled={true}
                deleteSingleEmployment={deleteSingleEmployment}
                deleteEmploymentState={deleteEmploymentState}
                applicationLayoutStyles={applicationLayoutStyles}
                save={true}
                getSingleEmploymentDataFunc={getSingleEmploymentData}
                openReturnData={getSingleEmploymentState}
                closeReturnData={updateEmploymentState}
                grouped={true}
                defaultActiveKey={false}
              />
            ))}{" "}
          </Loader>

          {disabledButton === false ? (
            <div className={applicationLayoutStyles.formFooter}>
              <div className={applicationLayoutStyles.formPrevious}>
                <Button
                  onClick={() => navigate( navDict[appType]['steps'][navDict[appType]['steps'].indexOf(location.pathname) - 1],{state:{ applicantType : appType }})}
                  type="primary"
                  htmlType="submit"
                >
                  {<FormattedMessage id="nav.previous" />}
                </Button>
              </div>
              <div className={applicationLayoutStyles.formSave}>
                <Button type="primary"
                onClick={() => navigate("/dashboard")}
                >
                  {<FormattedMessage id="nav.continueLater" />}
                </Button>
                <Button
                  onClick={() => navigate( navDict[appType]['steps'][navDict[appType]['steps'].indexOf(location.pathname) + 1],{state:{ applicantType : appType }})}
                  type="primary"
                  htmlType="submit"
                >
                  {<FormattedMessage id="nav.next" />}
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </ApplicationLayout>
    </div>
  );
};

export default RegisterEmploymentInfo;

import React, { useEffect, useState, useRef } from "react";
import ApplicationLayout from "components/Layouts/AuthLayout/application";
import applicationLayoutStyles from "components/Layouts/AuthLayout/application.module.css";
import CountryPhoneInput from "antd-country-phone-input";
import { Form, Input, Button, Row, Col, message, Select } from "antd";
import { SaveOutlined , SyncOutlined  } from "@ant-design/icons";
import { PhoneNumberValidation } from "../../../../utils/registration-form-validators/index";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";

//actions
import {
  addEmergencyContact,
  addEmergencyContactCleanup,
} from "../../../../store/actions/add-emergency-contact";
import {
  deleteEmergencyContact,
  deleteEmergencyContactCleanup,
} from "../../../../store/actions/delete-emergency-contact";
import {
  getAllEmergencyContacts,
  getAllEmergencyContactsCleanup,
} from "../../../../store/actions/get-all-emergency-contacts/index";
import {
  updateEmergencyContact,
  updateEmergencyContactCleanup,
} from "../../../../store/actions/update-emergency-contact/index";
import {
  getSingleEmergencyContact,
  getSingleEmergencyContactCleanup,
} from "../../../../store/actions/get-single-emergency-contact/index";

//utils
import Notification from "../../../../utils/notification";
import Loader from "../../../../components/Loader";
import { relationshipList } from "utils/helpers/data";
import { loadScript, handleScriptLoad } from "../../../../utils/helpers";

import { navDict } from "../../../../utils/helpers/data";

//components
import EmergencyContactForm from "./Form.js";
const { Option } = Select;
let autoComplete;

const EmergencyContact = () => {
  const location = useLocation();
  const intl = useIntl();
  const appType = location.state?.applicantType;// ?? "CDPAP";
  

  const [phoneNumber, setPhoneNumber] = useState("");
  //hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [newForm] = Form.useForm();
  const user = useSelector((s) => s.getPersonalData.data);
  const addEmergencyContactState = useSelector(
    (state) => state.addEmergencyContact
  );
  const navAccessState = useSelector(s=>s.navAccess)
  const deleteEmergencyContactState = useSelector(
    (state) => state.deleteEmergencyContact
  );
  const getAllEmergencyContactsState = useSelector(
    (state) => state.getAllEmergencyContacts
  );
  const updateEmergencyContactState = useSelector(
    (state) => state.updateEmergencyContact
  );
  const getSingleEmergencyContactState = useSelector(
    (state) => state.getSingleEmergencyContact
  );
  const [disabledButton, setDisabledButton] = useState(false);
  const [allEmergencyContacts, setAllEmergencyContacts] = useState([]);
  const [Street__c, setStreet__c] = useState("");
  const [City__c, setCity__c] = useState("");
  const [State__c, setState__c] = useState("");
  const [Country__c, setCountry__c] = useState("");
  const [Zip__c, setZip__c] = useState("");
  const [query, setQuery] = useState("");
  const [Relationship__c, setRelationship__c] = useState("");
  const autoCompleteRef = useRef(null);

  /**
   * Handle a
   * @param {string} addess
   */
  const updateAddressField = (address) => {
    setQuery(address);
    form.setFieldsValue({ address: address });
  };

  //functions for api calls
  const createEmergencyContact = (values) => {
    values.CellPhone__c = `+${values.CellPhone__c.code}${values.CellPhone__c.phone}`.replace(/\+/g,""); //TODO : better handle this;
    let FullName = values.Name.split(" ");
    // values.Emp_LastName__c = Emp_LastName__c; //FullName[FullName.length - 1];
    // values.Name = Name; //FullName[0];
    values.Street__c = Street__c;
    values.City__c = City__c;
    values.State__c = State__c;
    values.Country__c = Country__c;
    values.Zip__c = Zip__c;
    dispatch(addEmergencyContact(values));
  };
  const deleteSingleEmergencyContact = (id) => {
    dispatch(deleteEmergencyContact(id));
  };
  const getSingleEmergencyContactData = (id) => {
    dispatch(getSingleEmergencyContact(id));
  };

  //useEffect for api calls
  useEffect(() => {
    if(user?.Can_Go_To_Related_Profile_Info__c===false && navAccessState?.Can_Go_To_Related_Profile_Info__c===false){
      Notification(
        'Access denied',
        "warning",
        `You are not yet allowed to access this section. Please make sure to complete all previous sections`
      );
      return navigate('/')
    }
    document.title = `${intl.formatMessage({id:"web.websiteTitle"})}-${intl.formatMessage({id:'emergencycontact.pageHeader'})}`
    window.scrollTo(0,0)
    dispatch(getAllEmergencyContacts());
    // ----------- handle google place autocomplete feture on mount ----------
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process?.env.REACT_APP_GOOGLE_API}&libraries=places`,
      () =>
        handleScriptLoad(
          autoComplete,
          updateAddressField,
          autoCompleteRef.current.input
        )
    );
  }, [intl, user]);

  useEffect(() => {
    if (updateEmergencyContactState.isLoading === true) {
      message.loading({
        content: intl.formatMessage({id:'ui.state.updating'}),
        key: "updatable",
        duration: 1,
      });
      dispatch(updateEmergencyContactCleanup());
    }
    if (updateEmergencyContactState.isSuccessful) {
      dispatch(getAllEmergencyContacts());
      dispatch(updateEmergencyContactCleanup());
    } else if (updateEmergencyContactState.error) {
      if (Array.isArray(updateEmergencyContactState.error)) {
        Notification(
          intl.formatMessage({id:"notification.failed"}),
          "error",
          updateEmergencyContactState.error[0].msg
        );
      } else
        Notification(intl.formatMessage({id:"notification.failed"}), "error", intl.formatMessage({id:"notification.emergencyContact.update.failed"}));
      dispatch(updateEmergencyContactCleanup());
    }
  }, [updateEmergencyContactState]);

  useEffect(() => {
    if (deleteEmergencyContactState.isSuccessful) {
      dispatch(getAllEmergencyContacts());
      dispatch(deleteEmergencyContactCleanup());
    } else if (deleteEmergencyContactState.error) {
      if (Array.isArray(deleteEmergencyContactState.error)) {
        Notification(
          intl.formatMessage({id:"notification.failed"}),
          "error",
          deleteEmergencyContactState.error[0].msg
        );
      } else
        Notification(intl.formatMessage({id:"notification.failed"}), "error", intl.formatMessage({id:"notification.emergencyContact.delete.failed"}));
      dispatch(deleteEmergencyContactCleanup());
    }
  }, [deleteEmergencyContactState]);

  useEffect(() => {
    if (getAllEmergencyContactsState.isSuccessful) {
      let contacts = getAllEmergencyContactsState.data.map((item) => {
        return {
          ...item,
          address:
            (item?.PersonMailingAddress?.Street__c === undefined
              ? ""
              : item?.Street__c + ", ") +
            (item?.City__c === undefined ? "" : item?.City__c + ", ") +
            (item?.State__c === undefined ? "" : item?.State__c + " ") +
            (item?.Zip__c === undefined ? "" : item?.Zip__c + " ") +
            (item?.Country__c === undefined ? "" : item?.Country__c),
        };
      });
      setAllEmergencyContacts(contacts.reverse());
      dispatch(getAllEmergencyContactsCleanup());
    } else if (getAllEmergencyContactsState.error) {
      dispatch(getAllEmergencyContactsCleanup());
    }
  }, [getAllEmergencyContactsState]);

  useEffect(() => {
    if (addEmergencyContactState.isSuccessful) {
      Notification(
        intl.formatMessage({id:'notification.success'}),
        "success",
        intl.formatMessage({id:'notification.emergencyContact.update.success'})
      );
      form.resetFields();
      Reset()
      dispatch(getAllEmergencyContacts());
      dispatch(addEmergencyContactCleanup());
    } else if (addEmergencyContactState.error) {
      if (Array.isArray(addEmergencyContactState.error)) {
        Notification(intl.formatMessage({id:'notification.failed'}), "error", addEmergencyContactState.error[0].msg);
      } else Notification(intl.formatMessage({id:'notification.failed'}), "error", intl.formatMessage({id:'notification.emergencyContact.add.failed'}));

      dispatch(addEmergencyContactCleanup());
    }
  }, [addEmergencyContactState]);
  useEffect(() => {
    let address = query.split(", ");

    setStreet__c(address[address.length - 4]);
    setCity__c(address[address.length - 3]);
    setCountry__c(address[address.length - 1]);
    setState__c(address[address.length - 2]?.split(" ")[0]);
    setZip__c(address[address.length - 2]?.split(" ")[1]);
  }, [query]);

  useEffect(() => {
    if (getSingleEmergencyContactState.isSuccessful) {
      newForm.setFieldsValue({
        Name: getSingleEmergencyContactState.data.Name,
        Emp_LastName__c: getSingleEmergencyContactState.data.Emp_LastName__c,
        RelatioshipNew__c:
          getSingleEmergencyContactState.data.RelatioshipNew__c,
        Email__c: getSingleEmergencyContactState.data.Email__c,
        HomePhone__c: getSingleEmergencyContactState.data.HomePhone__c,
        CellPhone__c: getSingleEmergencyContactState.data.CellPhone__c,
        Zip__c: getSingleEmergencyContactState.data.Zip__c,
        State__c: getSingleEmergencyContactState.data.State__c,
        Street__c: getSingleEmergencyContactState.data.Street__c,
        City__c: getSingleEmergencyContactState.data.City__c,
        Id: getSingleEmergencyContactState.data.Id,
        address:
          (getSingleEmergencyContactState?.data?.PersonMailingAddress
            ?.Street__c === undefined
            ? ""
            : getSingleEmergencyContactState?.data?.Street__c + ", ") +
          (getSingleEmergencyContactState?.data?.City__c === undefined
            ? ""
            : getSingleEmergencyContactState?.data?.City__c + ", ") +
          (getSingleEmergencyContactState?.data?.State__c === undefined
            ? ""
            : getSingleEmergencyContactState?.data?.State__c + " ") +
          (getSingleEmergencyContactState?.data?.Zip__c === undefined
            ? ""
            : getSingleEmergencyContactState?.data?.Zip__c + ", ") +
          (getSingleEmergencyContactState?.data?.Country__c === undefined
            ? ""
            : getSingleEmergencyContactState?.data?.Country__c),
      });
      dispatch(getSingleEmergencyContactCleanup());
    } else if (getSingleEmergencyContactState.error) {
      if (Array.isArray(getSingleEmergencyContactState.error)) {
        Notification(
          intl.formatMessage({id:'notification.failed'}),
          "error",
          getSingleEmergencyContactState.error[0].msg
        );
      } else Notification(intl.formatMessage({id:'notification.failed'}), "error", "Failed to get emergency Contact");
      dispatch(getSingleEmergencyContactCleanup());
    }
  }, [getSingleEmergencyContactState]);

  const onChange = (e) => {
    const formValues = form.getFieldsValue();
    const {
      Name,
      Emp_LastName__c,
      address,
      Email__c,
      HomePhone__c,
      RelatioshipNew__c,
      CellPhone__c,
    } = formValues;
    if (
      Name?.length > 0 ||
      Emp_LastName__c?.length > 0 ||
      address?.length > 0 ||
      Email__c?.length > 0 ||
      HomePhone__c?.length > 0 ||
      RelatioshipNew__c?.length > 0 ||
      CellPhone__c?.phone?.length > 0
    ) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  };
  const Reset = () => {
    form.resetFields();
    setDisabledButton(false);
  };

  let stepNum = navDict[appType]?.['steps']?.indexOf(location.pathname) + 1;
  let totalStepsCount = navDict[appType]?.['totalStepsCount'];

  return (
    <div>
      <ApplicationLayout>
        <div className={applicationLayoutStyles.mainContent}>
          {appType ? <span>
              <FormattedMessage id="nav.step" values={{stepNum,totalStepsCount}} /> : 
            </span>
            : null } <span>{<FormattedMessage id="profile.pageHeader" />}
          </span>
          <h2>{<FormattedMessage id="emergencycontact.pagePrompt" />}</h2>
          <Loader
            isLoading={addEmergencyContactState.isLoading}
            text={<FormattedMessage id="ui.loading" />}
          >
            <Form
              form={form}
              name={"emergencyContactForm"}
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              scrollToFirstError={true}
              autoComplete="off"
              onChange={onChange}
              onFinish={createEmergencyContact}
            >
              <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="emergencycontact.firstName" />}
                    name="Name"
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id:'validation.firstNameIsRequired'
                        }),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                  </Col>
                  <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="emergencycontact.lastName" />}
                    name="Emp_LastName__c"
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id:'validation.lastNameIsRequired'
                        }),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="emergencycontact.relationship" />}
                    name="RelatioshipNew__c"
                    rules={[
                      {
                        required: true,
                        message: intl.formatMessage({
                          id:'validation.relationshipIsRequired'
                        }),
                      },
                    ]}
                  >
                    <Select>
                      {relationshipList.map((e, i) => (
                        <Option key={i} value={e.sfVal}>
                          {<FormattedMessage id={e.displayStringId} />}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="emergencycontact.email" />}
                    name="Email__c"
                    rules={[
                      // {
                      //   required: true,
                      //   message: "Email is required",
                      // },
                      {
                        type: "email",
                        message: intl.formatMessage({
                          id:'validation.emailIsNotValid'
                        }),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="emergencycontact.phoneNumber" />}
                    name="CellPhone__c"
                    rules={[
                      {
                        required: true,
                      },
                      {
                        validator: PhoneNumberValidation,
                      },
                    ]}
                    initialValue={{
                      short: "us",
                    }}
                  >
                    <CountryPhoneInput
                      onChange={(p) => {
                        setPhoneNumber(`+${p.code}${p.phone}`);
                      }}
                    />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item label={<FormattedMessage id="emergencycontact.homePhone" />} name="HomePhone__c">
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row>
                <Col span={24}>
                  <Form.Item
                    rules={[
                      {
                        required: false,
                        message: intl.formatMessage({
                          id:'validation.addressIsRequired'
                        }),
                      },
                    ]}
                    label={<FormattedMessage id="emergencycontact.addressPrompt" />}
                    name="address"
                  >
                    <Input
                      placeholder={intl.formatMessage({id:"emergencycontact.address"})}
                      ref={autoCompleteRef}
                      onChange={(event) => setQuery(event.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {disabledButton === true ? (
                <div className={applicationLayoutStyles.addNewItemWrapper}>
                  <Button onClick={Reset} danger size="large" style={{ fontSize: '120%'}}>
                    <SyncOutlined  />{<FormattedMessage id="nav.reset" />}
                  </Button>
                  <Button type="primary" htmlType="submit" size="large" style={{ fontSize: '120%'}}>
                    <SaveOutlined  />{<FormattedMessage id="nav.save" />}
                  </Button>
                </div>
              ) : null}
            </Form>
          </Loader>
          <Loader
            isLoading={getAllEmergencyContactsState.isLoading}
            text={<FormattedMessage id="ui.loading" />}
          >
            {allEmergencyContacts.map((a, i) => (
              <EmergencyContactForm
                key={i}
                UpdatedForm={
                  <EmergencyContactForm
                    data={{
                      Name: a.Name,
                      RelatioshipNew__c: a.RelatioshipNew__c,
                      Email__c: a.Email__c,
                      HomePhone__c: a.HomePhone__c,
                      CellPhone__c: a.CellPhone__c,
                      Street__c: a.Street__c,
                      City__c: a.City__c,
                      State__c: a.State__c,
                      Zip__c: a.Zip__c,
                      Country__c: a.Country__c,
                      address: a.address,
                      Id: a.Id,
                    }}
                    autoCompleteRef={autoCompleteRef}
                    disabled={false}
                    formType={newForm}
                    deleteSingleEmergencyContact={null}
                    deleteEmergencyContactState={null}
                    save={false}
                    formName={"updateEmergencyContact"}
                    dispatch={dispatch}
                    updateEmergencyContact={updateEmergencyContact}
                    closeReturnData={{
                      data: null,
                      error: null,
                      isLoading: false,
                      isSuccessful: false,
                    }}
                    openReturnData={null}
                    grouped={false}
                    defaultActiveKey={true}
                  />
                }
                updateForm={null}
                updateEmergencyContact={null}
                formName={"allEmergencyContact"}
                data={a}
                disabled={true}
                deleteSingleEmergencyContact={deleteSingleEmergencyContact}
                deleteEmergencyContactState={deleteEmergencyContactState}
                applicationLayoutStyles={applicationLayoutStyles}
                save={true}
                getSingleEmergencyContactDataFunc={
                  getSingleEmergencyContactData
                }
                openReturnData={getSingleEmergencyContactState}
                closeReturnData={updateEmergencyContactState}
                grouped={true}
                defaultActiveKey={false}
                PhoneNumberValidation={PhoneNumberValidation}
                autoCompleteRef={autoCompleteRef}
              />
            ))}{" "}
          </Loader>
          {disabledButton === false ? (
            <div className={applicationLayoutStyles.formFooter}>
              <div className={applicationLayoutStyles.formPrevious}>
                <Button
                  onClick={() => navigate( navDict[appType]['steps'][navDict[appType]['steps'].indexOf(location.pathname) - 1],{state:{ applicantType : appType }})}
                  type="primary"
                  htmlType="submit"
                >
                  {<FormattedMessage id="nav.previous" />}
                </Button>
              </div>
              <div className={applicationLayoutStyles.formSave}>
                <Button type="primary" 
                onClick={() => navigate("/dashboard")}
                >
                 {<FormattedMessage id="nav.continueLater" />}
                </Button>
                <Button
                  onClick={() => navigate( navDict[appType]['steps'][navDict[appType]['steps'].indexOf(location.pathname) + 1],{state:{ applicantType : appType }})}
                  type="primary"
                  htmlType="submit"
                >
                  {<FormattedMessage id="nav.next" />}
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </ApplicationLayout>
    </div>
  );
};

export default EmergencyContact;

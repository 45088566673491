import defaultLayoutStyles from './default.module.css';
import React from 'react'
import AuthNavbar from "components/Navbars/AuthNavbar.js";

function DefaultAuthLayout({children}) {
  return (
    <>
      <div className={defaultLayoutStyles.defaultLayout}>
          <div className={defaultLayoutStyles.defaultLayoutWrapper}>
                <AuthNavbar />
                <div className={defaultLayoutStyles.defaultLayoutContent}>
                    {children}
                </div>
          </div>
      </div>
    </>
  )
}

export default DefaultAuthLayout

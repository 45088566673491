import * as types from '../../action-types';
import {getAllEducations as initialState } from "../../initialState";

const getAllEducations = (state=initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_EDUCATIONS_START:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_ALL_EDUCATIONS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccessful: true,
        data: action.payload
      };
    case types.GET_ALL_EDUCATIONS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.GET_ALL_EDUCATIONS_CLEANUP:
      return {
        ...state,
        isLoading: false,
        isSuccessful: false,
        error: null
      };
    default:
      return state;
  }
}

export default getAllEducations;

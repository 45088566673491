import InputLengthValidation from "../input-lenght-validator";
import PhoneValidator from "../phone-number-validation";
import { useIntl, FormattedMessage } from "react-intl";

const SSNValidator = (rule, value, callback, type) => {
  const intl = useIntl()
   if ((value===undefined)||(value ===null) ) {
    callback(<FormattedMessage id="validation.ssnIsRequired"/>);
  }
  else if (typeof value !== "undefined") {
    let pattern = new RegExp(/^[0-9-]+$/);

    if (value && !pattern.test(value)) {
      callback(`Please enter valid ${type}.`);
    } else if ((value&& value.trim().length !== 11) ) {
      callback(`Please enter valid ${type}.`);
    }
    else if ((value && value.trim().length <3) ) {
      callback(`SSN is required.`);
    }
    callback();
  }
};



export const FirstNameValidation = (rule, value, callback) => {
    InputLengthValidation(rule, value, callback, "First Name", 2);
  };
  export const LastNameValidation = (rule, value, callback) => {
    InputLengthValidation(rule, value, callback, "Last Name", 1);
  };
  export const PhoneNumberValidation = ( rule, value, callback) => {
    PhoneValidator(rule, value, callback , "Phone Number");
  };
  export const SSNValidation = (rule, value, callback) => {
    SSNValidator(rule, value, callback, "SSN");
  };
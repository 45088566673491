import React from 'react'
import {Modal} from 'antd'
import appStyles from './helpModal.module.css'

const HelpModal = ({isModalVisible, handleOk, handleCancel})=>{
    return(
        <Modal 
          visible={isModalVisible} 
          footer={null} 
          closable={true} 
          width={500} 
          maskStyle={{background: "#1B2C33D4"}}
          onOk={handleOk} 
          onCancel={handleCancel}
      >
          <div style={{fontWeight:'700', textAlign:'center', marginBottom:'10px', fontSize:'16px'}}>Local Offices:</div>
          <div className={appStyles.addressItem}>
            Brooklyn: <br/>
            6317 14th Avenue <br/>
            Brooklyn, NY 11219 <br/>
            <a href='tel:718-851-3800'>718-851-3800</a> <br/>
            <a href='mailo:hr@royalcare.nyc'>hr@royalcare.nyc</a>
          </div>
          <div className={appStyles.addressItem}>
            Bronx: <br/>
            798 Southern Blvd, 3rd Floor <br/>
            Bronx, NY 10455 <br/>
            <a href="tel:718-840-3000">718-840-3000</a>  <br/>
           <a href='mailto:hr@royalcare.nyc'>hr@royalcare.nyc</a> 
          </div>
          <div className={appStyles.addressItem}>
            Jamaica: <br/>
            168-04 Jamaica Avenue<br/>
            Jamaica, NY 11432 <br/>
            <a href='tel:718-840-3900'>718-840-3900</a>  <br/>
           <a href='mailto:hr@royalcare.nyc'>hr@royalcare.nyc</a>
          </div>
          <div className={appStyles.addressItem}>
            Flushing: <br/>
            136-21 Roosevelt Ave, Ste 403 <br/>
            Flushing, NY 11354<br/>
            <a href='tel:718-534-6300'>718-534-6300</a><br/>
           <a href='mailto:hr@royalcare.nyc'>hr@royalcare.nyc</a>
          </div>
          <div className={appStyles.addressItem}>
            Nassau:<br/>
            211 Fulton Avenue <br/>
            Hempstead, NY 11550<br/>
            <a href='tel:516-453-5200'>516-453-5200</a><br/>
           <a href='mailto:hr@royalcare.nyc'>hr@royalcare.nyc</a>
          </div>

      </Modal>
    )
}

export default HelpModal;
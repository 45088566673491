import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getSingleEmploymentStart = () => ({
  type: types.GET_SINGLE_EMPLOYMENT_START
});

export const getSingleEmploymentSuccess = payload => ({
  type: types.GET_SINGLE_EMPLOYMENT_SUCCESS,
  payload
});

export const getSingleEmploymentFail = payload => ({
  type: types.GET_SINGLE_EMPLOYMENT_FAIL,
  payload
});

export const getSingleEmploymentCleanup = () =>({
  type: types.GET_SINGLE_EMPLOYMENT_CLEANUP
});

export const getSingleEmployment = employmentId => async dispatch => {
  try {

    dispatch(getSingleEmploymentStart());
    const requestObj = {
      path: `experiences/${employmentId}`,
      method: "GET",
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(getSingleEmploymentSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getSingleEmploymentFail(error));
  }
}

import React, { useState, useEffect } from "react";
import { Form, Input, Button, Checkbox, Select } from "antd";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CountryPhoneInput from "antd-country-phone-input";
import NumberFormat from "react-number-format";
import { useNavigate } from "react-router-dom";
import {InfoCircleOutlined} from '@ant-design/icons'
import Tooltip from "antd/es/tooltip";
import hcr from '../../assets/img/hcr.png'


//components
import AuthLayout from "components/Layouts/AuthLayout";
import TermsAndConditionsModal from "components/Modal/termsAndConditonsModal";

//actions
import { signUp, signUpCleanup } from "../../store/actions/signup";

//utils
import {
  FirstNameValidation,
  LastNameValidation,
  PhoneNumberValidation,
  SSNValidation,
} from "../../utils/registration-form-validators/index";
import PasswordValidator from "../../utils/password-validaror/index";
import Notification from "../../utils/notification";
import useQuery from "../../utils/useQuery";
import {FormattedMessage, useIntl} from 'react-intl';

const { Option } = Select;

const Register = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const navigate=useNavigate()
  const query = useQuery();
  const intl = useIntl();
  const signUpState = useSelector((s) => s.signUp);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail]=useState("")
  const [phone, setPhone] = useState("")
  const [mask, setIMask] = useState("");
  const [maskValue] = useState({ tel: "###-##-####" });
  const [hha, setHHA] = useState(null);
  const [cdpap, setCDPAP] = useState(null);
  const [appType, setAppType] = useState("");
  const [office, setOffice] = useState(null);
  const [checked, setChecked]=useState(false)
  const [didUseDirectLink, setDidUseDirectLink] = useState(false)
  const [isModalVisible, setIsModalVisible] = useState(false)
  const [userDetails, setUserDetails] = useState({})
  const onFinish = (values) => {
    values.Applicant_Type__c=hha?"HHA":"CDPAP"
    values.Applicant_Type__c=appType=="" ? values.UserSelectedApplicantType: appType
    setEmail(values.PersonEmail)
   
    values.PersonMobilePhone = phoneNumber.replace(/\+/g,""); //TODO : better handle this
    setPhone(values.PersonMobilePhone)
    

    values.SSN__c = values.SSN__c.replace(/\-/g, "");
    values.Location_Team__c = office ? (office.charAt(0).toUpperCase() + office.slice(1) + " - R1") : "OnlineUser";
    values.Primary_Language__c = localStorage.getItem("language") ?? "English";
    //values.Translating_Language__c = "en"; //TODO: Set language to default to english through SF Admin

    setUserDetails({
      email:values.PersonEmail,
      phone:values.PersonMobilePhone,
      SSN:values.SSN__c,
      HCR:values.HCR_Number__c
    })
 
   
    checked===false?
    Notification(intl.formatMessage({id:'alert.note'}), "warning", intl.formatMessage({id:'alert.tosBody'})):
    dispatch(signUp(values));
  };

  
  useEffect(() => { 
    if(query.get("type")==="hha"){
      form.setFieldsValue({
        UserSelectedApplicantType: "HHA"
      })
      setHHA(true);
      setAppType("HHA");
      setDidUseDirectLink(true)
    } else if(query.get("type")==="cdpap"){
      form.setFieldsValue({
        UserSelectedApplicantType: "CDPAP"
      })
      setCDPAP(true);
      setAppType("CDPAP");
      setDidUseDirectLink(true)
    }
    if(query.get("office")){
      setOffice(query.get("office"));
    }
  }, []);
  
  useEffect(() => {
    if (signUpState.isSuccessful) {
      Notification(
        intl.formatMessage({id:'alert.registrationSuccessful'}),
        "success",
        intl.formatMessage({id:'alert.registrationSuccessfulBodyWithEmailVerificationPrompt'})
      );
      navigate(`/verify-otp?email=${encodeURIComponent(email)}&phone=${phone}`)
      localStorage.setItem('userDetails' ,JSON.stringify(userDetails))
      dispatch(signUpCleanup());
    } else if (signUpState.error) {
      if (Array.isArray(signUpState.error)) {
         Notification(intl.formatMessage({id:'alert.registrationFailed'}), "error", signUpState.error[0].msg);
      } else
        console.log(signUpState);
        switch (signUpState.error.name) {
          case "EMAIL_ALREADY_IN_USE" :
            Notification(
              intl.formatMessage({id:'alert.registrationFailed'}),
              "error",
              intl.formatMessage({id:'alert.registration.failedBecauseOfEmailAlreadyInUse'})
            );
            break;
          case "DUPLICATES_DETECTED" : 
            switch(signUpState.error.duplicateResut.duplicateRule) {
              case "Duplicate_Applicants_SSN" : 
                Notification(
                  intl.formatMessage({id:'alert.registrationFailed'}),
                  "error",
                  intl.formatMessage({id:'alert.registration.failedBecauseOfExistingSSN'})
                );
                break;
              default:
                Notification(
                  intl.formatMessage({id:'alert.registrationFailed'}),
                  "error",
                  intl.formatMessage({id:'alert.registration.failedBecauseOfGenericDuplicateError'})
                );
            }
            break;
          default:
            Notification(
              intl.formatMessage({id:'alert.registrationFailed'}),
              "error",
              intl.formatMessage({id:'alert.invalidRefistrationCredentials'})
            );
        }
      
      dispatch(signUpCleanup());
    }
  }, [signUpState]);

  return (
    <div>
      <TermsAndConditionsModal
        isModalVisible={isModalVisible}
        handleCancel = {()=>setIsModalVisible(false)}
        handleOk = {()=>setIsModalVisible(false)}
      />
      <AuthLayout>
          <Form
            layout="vertical"
            name="basic"
            // layout="vertical"
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
            onFinish={onFinish}
            form={form}
            // name="advanced_search"
            className="ant-advanced-search-form"
          >
            <Form.Item
              label={<FormattedMessage id="register.firstName" />}
              name="FirstName"
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id="register.validation.firstNameIsRequired" />,
                },
                {
                  validator: FirstNameValidation,
                },
              ]}
            >
              <Input disabled={signUpState.isLoading} />
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id="register.lastName" />}
              name="LastName"
              rules={[
                {
                  required: true,
                  message:  <FormattedMessage id="register.validation.lastNameIsRequired" />,
                },
                {
                  validator: LastNameValidation,
                },
              ]}
            >
              <Input disabled={signUpState.isLoading} />
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id="register.createPassword" />}
              // label="Password"
              name={"Password"}
              rules={[
                {
                  required: true,
                  message:  <FormattedMessage id="register.validation.passwordIsRequired" />,
                },
                {
                  validator: PasswordValidator,
                },
              ]}
            >
              <Input.Password disabled={signUpState.isLoading} />
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id="register.SSN" />}
              name="SSN__c"
              rules={[
                {
                  required: true,
                  message: <FormattedMessage id ="validation.ssnIsRequired"/>
                },
                {
                  // validator: SSNValidation,
                },
              ]}
            >
              <NumberFormat
                className={"ant-input"}
                // placeholder="SSN"
                format={mask}
                onFocus={(event) => setIMask(maskValue[event.target.type])}
                placeholder={mask}
                type="tel"
                disabled={signUpState.isLoading} 
              />
            </Form.Item>
            <Form.Item 
              label={<FormattedMessage id="register.applicationType" />} 
              name="UserSelectedApplicantType"
              rules={[
                      {
                        required: appType==="",
                        message: intl.formatMessage({
                          id:'validation.applicationTypeIsRequired'
                        }),
                      },
                    ]}
              >
              <Select onSelect={setAppType} disabled={didUseDirectLink} placeholder={<FormattedMessage id="register.applicationTypePrompt" />} >
                <Option value="HHA">HHA</Option>
                <Option value="CDPAP">CDPAP</Option>
              </Select>
            </Form.Item>
           {appType=="HHA" ? <Form.Item
              // hidden={values.UserSelectedApplicantType != "HHA"}
              label={
              <>
                <FormattedMessage id="register.HCRnumber" /> 
                <Tooltip className="ant-toolTip" title={<img className="hcrDocs" src={hcr} />}>
                    <InfoCircleOutlined className="register-tooltip" />
                </Tooltip>
               </>}
              name="HCR_Number__c"
              rules={[
                {
                  required: true,
                  message: ``
                },
         
              ]}
            >
              <NumberFormat
                className={"ant-input"}
                placeholder="HCR Number"
                onFocus={(event) => setIMask(maskValue[event.target.type])}
                // placeholder={mask}
                type="tel"
                disabled={signUpState.isLoading} 
              />
            </Form.Item>:null}
            <Form.Item
              label={<FormattedMessage id="register.email" />}
              name="PersonEmail"
              rules={[
                {
                  required: true,
                  message:  <FormattedMessage id="register.validation.emailIsRequired" />,
                },
                {
                  type: "email",
                  message:  <FormattedMessage id="register.validation.emailIsNotValid" />,
                },
              ]}
            >
              <Input disabled={signUpState.isLoading} />
            </Form.Item>
            <Form.Item
              label={<FormattedMessage id="register.phoneNo" />}
              name="PersonMobilePhone"
              rules={[
                {
                  required: true,
                  message:<FormattedMessage id="validation.phoneNumberIsRequired" />,
                },
                {
                  validator: PhoneNumberValidation,
                },
              ]}
              initialValue={{
                short: "us",
              }}
            >
              <CountryPhoneInput
                onChange={(p) => {
                  setPhoneNumber(`+${p.code}${p.phone}`);
                }}
                disabled={signUpState.isLoading} 
              />
            </Form.Item>
            <Form.Item>
              <Checkbox checked={checked} onChange={(e)=>setChecked(e.target.checked)}>
                {<FormattedMessage id="register.termsAndConditionsPrefix" />}{" "}
                <span onClick={()=>setIsModalVisible(true)} style={{fontWeight:'bold'}}>{<FormattedMessage id="register.termsAndConditions" />}</span>
              </Checkbox>
            </Form.Item>

            <Form.Item>
              <Button
                loading={signUpState.isLoading}
                className="auth-btn--custom-auth"
                type="primary"
                htmlType="submit"
              >
                {<FormattedMessage id="auth.createAccount" />}
              </Button>
            </Form.Item>
          </Form>
      </AuthLayout>
    </div>
  );
};

export default Register;

import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getSingleEducationStart = () => ({
  type: types.GET_SINGLE_EDUCATION_START
});

export const getSingleEducationSuccess = payload => ({
  type: types.GET_SINGLE_EDUCATION_SUCCESS,
  payload
});

export const getSingleEducationFail = payload => ({
  type: types.GET_SINGLE_EDUCATION_FAIL,
  payload
});

export const getSingleEducationCleanup = () =>({
  type: types.GET_SINGLE_EDUCATION_CLEANUP
});

export const getSingleEducation = educationId => async dispatch => {
  try {

    dispatch(getSingleEducationStart());
    const requestObj = {
      path: `educations/${educationId}`,
      method: "GET",
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(getSingleEducationSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getSingleEducationFail(error));
  }
}

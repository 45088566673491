import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getSingleUploadStart = () => ({
  type: types.GET_SINGLE_UPLOAD_START
});

export const getSingleUploadSuccess = payload => ({
  type: types.GET_SINGLE_UPLOAD_SUCCESS,
  payload
});

export const getSingleUploadFail = payload => ({
  type: types.GET_SINGLE_UPLOAD_FAIL,
  payload
});

export const getSingleUploadCleanup = () =>({
  type: types.GET_SINGLE_UPLOAD_CLEANUP
});

export const getSingleUpload = UploadId => async dispatch => {
  try {

    dispatch(getSingleUploadStart());
    const requestObj = {
      path: `upload/${UploadId}`,
      method: "GET",
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(getSingleUploadSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getSingleUploadFail(error));
  }
}

import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const deleteEmergencyContactStart = () => ({
  type: types.DELETE_EMERGENCY_CONTACT_START
});

export const deleteEmergencyContactSuccess = payload => ({
  type: types.DELETE_EMERGENCY_CONTACT_SUCCESS,
  payload
});

export const deleteEmergencyContactFail = payload => ({
  type: types.DELETE_EMERGENCY_CONTACT_FAIL,
  payload
});

export const deleteEmergencyContactCleanup = () =>({
  type: types.DELETE_EMERGENCY_CONTACT_CLEANUP
});

export const deleteEmergencyContact = emegencycontactId => async dispatch => {
  try {

    dispatch(deleteEmergencyContactStart());
    const requestObj = {
      path: `emergencycontacts/${emegencycontactId}`,
      method: "DELETE",
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(deleteEmergencyContactSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(deleteEmergencyContactFail(error));
  }
}

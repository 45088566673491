import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const addLanguageStart = () => ({
    type: types.ADD_LANGUAGE_START
  });
  
  export const addLanguageSuccess = payload => ({
    type: types.ADD_LANGUAGE_SUCCESS,
    payload
  });
  
  export const addLanguageFail = payload => ({
    type: types.ADD_LANGUAGE_FAIL,
    payload
  });
  
  export const addLanguageCleanup = () =>({
    type: types.ADD_LANGUAGE_CLEANUP
  });

  export const addLanguage = payload => async dispatch => {
    try {
      console.log('language upload dispatched');
      dispatch(addLanguageStart());
      const requestObj = {
        path: "language",
        method: "POST",
        data: payload,
      }
      const  res  = await AxiosCall(requestObj);
      console.log('language response', res.data);
      dispatch(addLanguageSuccess(res.data));
    } catch (err) {
      const error = ErrorHandler(err);
      dispatch(addLanguageFail(error));
    }
  }
import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const deleteEducationStart = () => ({
  type: types.DELETE_EDUCATION_START
});

export const deleteEducationSuccess = payload => ({
  type: types.DELETE_EDUCATION_SUCCESS,
  payload
});

export const deleteEducationFail = payload => ({
  type: types.DELETE_EDUCATION_FAIL,
  payload
});

export const deleteEducationCleanup = () =>({
  type: types.DELETE_EDUCATION_CLEANUP
});

export const deleteEducation = educationId => async dispatch => {
  try {

    dispatch(deleteEducationStart());
    const requestObj = {
      path: `educations/${educationId}`,
      method: "DELETE",
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(deleteEducationSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(deleteEducationFail(error));
  }
}

// ----------- import external dependencies ----------
import React from "react";
import PropTypes from "prop-types";
import { useDispatch } from "react-redux";

// ----------- import internal dependencies ----------
import "./question.css";
import {selectAnswer} from "../../store/actions/applicant-test"

function Question({ number, question, answers, queId }) {
  // ------------- handle custom hooks initialization -----------
  const dispatch = useDispatch();

  const optionsArray = ["A", "B", "C", "D"];

   /**
   * Handle user answer click
   * @param {string} answer
   * @param {string} id
   */
    const answerClick = (answer, id) => {
      dispatch(selectAnswer({answer, id}))
    }

  return (
    <>
      <div className="question-container">
        <span className="question-container--number">Question {number}</span>
        <span className="question-container--question">{question}</span>
        <div className="question-answers relative">
          {answers.map((e, i) => (
            <div className="question-answers--label" key={i}>
              <input
                type="radio"
                name={`answers-${number}`}
                id={`question-${number}-answer-${i + 1}`}
                onClick={() => answerClick(optionsArray[i], queId)}
              />
              <label htmlFor={`question-${number}-answer-${i + 1}`}>
                <div>
                  <span className="answer-selector"></span>
                </div>
                <span>{e}</span>
              </label>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

Question.propTypes = {
  number: PropTypes.string,
  question: PropTypes.string,
  answers: PropTypes.arrayOf(PropTypes.string),
  queId: PropTypes.string
};

export default Question;

// ----------- import external dependencies ----------
import React, { useState } from "react";
import { Link } from "react-router-dom";

// ----------- import internal dependencies ----------
import "./stepdropdown.css";
import { ReactComponent as Open } from "../../assets/svgs/open.svg";
import { ReactComponent as Close } from "../../assets/svgs/close.svg";


import { FormattedMessage } from "react-intl";

function StepDropDown({ title, children, url, external }) {
  // ----------- application state managers ------------
  const [open, setOpen] = useState(false);
  return (
    <>
      <div className="step-dropdown" onClick={() => setOpen(!open)}>
        <div className="step-heading">
          <div className="step-detail">
            <span className="indicator"></span>
            <span className="step__title">{title}</span>
          </div>
          {url && external ? (
            <a href={url} target="_blank" className="proceed-btn">
              {<FormattedMessage id="dashboard.proceed" />}
            </a>
          ) : (
            <Link to={url} className="proceed-btn">{<FormattedMessage id="dashboard.proceed" />}</Link>
          )}
          {/* {open ? <Open /> : <Close />} */}
        </div>
        {open && <div className="step-children">{children}</div>}
      </div>
    </>
  );
}

export default StepDropDown;

const ErrorHandler = e => {
  let errorResponse;
  try {
    if (e.response.data === undefined) errorResponse = 'Something went wrong - a';
    
    if(e.response.status === 500){
      errorResponse = e.response.data.error ?? e.response.data;
    }else if(e.response.data.errors){
      const { errors } = e.response.data;
      errorResponse = errors && Array.isArray(errors) ? errors[0].msg : 'Something went wrong - c';
    }else{
      const { error } = e.response.data;
      errorResponse = error ? error : 'Something went wrong - d';
    }
  } catch (e) {
    errorResponse = 'Something went wrong - e';
  }

  return errorResponse;
};

export default ErrorHandler;

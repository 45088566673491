const PasswordValidator = (rule, value, callback) => {
  var regularExpression =
    /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).+$/;
  if (value && value.length < 8) {
    callback("Password should contain at least 8 characters");
  } else if (value && !regularExpression.test(value)) {
    callback(
      "Password must contain at least one Uppercase and lowercase letter, number and special characters"
    );
  } else {
    callback();
  }
};

export default PasswordValidator;

import './styles/app.css';
import React, {useEffect, useState} from 'react';
import ReactGA from "react-ga";
import { Provider } from 'react-redux';
import Routes from './routes';
import { store } from './store';
import { IntlProvider } from 'react-intl';
import SpanishMessages from './utils/translation/spanish'
import EnglishMessages from './utils/translation/English'
import ChineseMessages from './utils/translation/chinese'
import RussianMessages from './utils/translation/russian'
import CreoleMessages from './utils/translation/creole'
import LogRocket from 'logrocket';

// ------- staging account id --------
const trackingId = "UA-226673348-2";

ReactGA.initialize(trackingId);
LogRocket.init('aetz8a/rc-hr-portal');

const App = () => {
  // console.log("v20220505-1")

  let Messages


  const [currentMessages, setCurrentMessages] = useState("")

  function setLanguage (lang){
      //console.log(lang);
      if(!lang) return
      if(lang=="English"){
        Messages = EnglishMessages()
      }else if(lang=="Spanish"){
        Messages = SpanishMessages()
      }else if (lang=="Chinese"){
        Messages = ChineseMessages()
      }else if(lang=="Russian"){
        Messages= RussianMessages()
      }else if(lang=="Creole"){
        Messages= CreoleMessages()
      }
      else{
        Messages = EnglishMessages()
      }
    setCurrentMessages(Messages)
  }





  let language = localStorage.getItem('language') ;
  let storeLanguage = store.getState().language.language

  store.subscribe(()=>{
    const language = store.getState().language.language
    //console.log(language);
    setLanguage(language?.language)
    //console.log(language?.language);
  })
  useEffect(()=>{
    if(!language) return setLanguage('English')
    setLanguage(storeLanguage?storeLanguage:language)
  },[])







let i18nConfig = {
  locale: 'el',
  messages:currentMessages
};

  return (
    <IntlProvider
    locale={i18nConfig.locale}
    defaultLocale={i18nConfig.locale}
    messages={i18nConfig.messages}
 >
    <Provider store={store}>
        <Routes />
    </Provider>
    </IntlProvider>
  );
};

export default App;

import {
    Form,
    Input,
    Button,
    Select,
    Row,
    Col,
    DatePicker,
    Collapse,
  } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import applicationLayoutStyles from "components/Layouts/AuthLayout/application.module.css";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
//components
import ModalComponent from "../../../../components/Modal";

const { Panel } = Collapse;
const { Option } = Select;

const LanguageForm = ({
    key,
    updateForm,
    UpdatedForm,
    updateLanguage,
    formName,
    data,
    disabled,
    formType,
    deleteLanguage,
    deleteLanguageState,
    save,
    getSingleLanguage,
    openReturnData,
    closeReturnData,
    grouped
})=>{

    const dispatch = useDispatch();
    const intl = useIntl()

    const onFinish = (values) => {
      dispatch(updateLanguage(data.Id, values));
    };

    const [Name, setName] = useState(null);
    const [Writing_capabilities__c, setWriting_capabilites__c] = useState(null);
    const [Spoken_skill_level__c, setSpoken_skill_level__c] = useState(null);
    const [Reading_capabilities__c, setReading_capabilities__c] = useState(null);
    const [fromCloseData, setFromCloseData] = useState({
        data: null,
        error: null,
        isLoading: false,
        isSuccessful: false,
    });

    const handleGetSingleLanguage = (id) => {
        getSingleLanguage(id);
    };

    useEffect(() => {
        setFromCloseData(closeReturnData);
        
    }, [closeReturnData]);

        
    return(
    <Collapse
      defaultActiveKey={"1"}
      accordion
    >
      <Panel collapsible={true} header={data.Name} key={"1"}>
        <Form
            className={applicationLayoutStyles.updateForm}
            form={formType}
            name={formName}
            layout="vertical"
            onFinish={onFinish}
        >
            <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                        <Form.Item
                            label={<FormattedMessage id="languages.pageHeader" />}
                            name="Name"
                            rules={[
                            {
                                required: true,
                                message: <FormattedMessage id="languages.languageIsRequired" />,
                            },
                            ]}
                        >
                            <Input
                                placeholder={save && data.Name}
                                disabled={disabled}
                                value={Name}
                                onChange={(e) => {
                                    setName(e.target.value);
                                }}
                            />
                        </Form.Item>
                        </Col>
                        <Col span={12} xs={24} lg={12}>
                        <Form.Item
                            label={<FormattedMessage id="languages.readingFluencyQuestion"/>}
                            name="Reading_capabilities__c"
                            rules={[
                            {
                                required: true,
                                message: <FormattedMessage id="languages.required" />,
                            },
                            ]}
                        >
                            <Select  
                                onChange={(e) => {
                                    setReading_capabilities__c(e);
                                }} 
                                disabled={disabled} 
                                value={Reading_capabilities__c} 
                                placeholder={save && data.Reading_capabilities__c}  
                                allowClear
                            >
                                <Option value="Fair">
                                    <FormattedMessage id="languages.fluencyOptions.fair" />
                                </Option>
                                <Option value="Well">
                                    <FormattedMessage id="languages.fluencyOptions.well" />
                                </Option>
                                <Option value="Fluent">
                                    <FormattedMessage id="languages.fluencyOptions.fluent" />
                                </Option>
                            </Select>
                        </Form.Item>
                        </Col>
                    </Row>
                    
                    <Row gutter={32}>
                        <Col span={12} xs={24} lg={12}>
                        <Form.Item
                            label={<FormattedMessage id="languages.speakingFluencyQuestion" />}
                            name="Spoken_skill_level__c"
                            rules={[
                            {
                                required: true,
                                message: <FormattedMessage id="languages.required" />,
                            },
                            ]}
                        >
                            <Select 
                                value={Spoken_skill_level__c} 
                                placeholder={save && data.Spoken_skill_level__c} 
                                disabled={disabled} 
                                allowClear
                                onChange={(e) => {
                                    setSpoken_skill_level__c(e);
                                }}
                            >
                               <Option value="Fair">
                                    <FormattedMessage id="languages.fluencyOptions.fair" />
                                </Option>
                                <Option value="Well">
                                    <FormattedMessage id="languages.fluencyOptions.well" />
                                </Option>
                                <Option value="Fluent">
                                    <FormattedMessage id="languages.fluencyOptions.fluent" />
                                </Option>
                            </Select>
                        </Form.Item>
                        </Col>
                        <Col span={12} xs={24} lg={12}>
                        <Form.Item
                            label={<FormattedMessage id="languages.writingFluencyQuestion" />}
                            name="Writing_capabilities__c"
                            rules={[
                            {
                                required: true,
                                message: <FormattedMessage id="languages.required" />,
                            },
                            ]}
                        >
                            <Select 
                                value={Writing_capabilities__c}
                                placeholder={save && data.Writing_capabilites__c} 
                                disabled={disabled}  
                                allowClear
                                onChange={(e) => {
                                    setWriting_capabilites__c(e);
                                }}
                            >
                                <Option value="Fair">
                                    <FormattedMessage id="languages.fluencyOptions.fair" />
                                </Option>
                                <Option value="Well">
                                    <FormattedMessage id="languages.fluencyOptions.well" />
                                </Option>
                                <Option value="Fluent">
                                    <FormattedMessage id="languages.fluencyOptions.fluent" />
                                </Option>
                            </Select>
                        </Form.Item>
                </Col>
            </Row>
            {deleteLanguageState === null ? null : (
            <div
              className={applicationLayoutStyles.addNewItemWrapper}
              style={{ width: "100%", position: "relative" }}
            >
              <Button
                loading={deleteLanguageState.isLoading}
                type="button"
                onClick={() => deleteLanguage(data.Id)}
                style={{ position: "absolute", right: 0 }}
              >
                <DeleteFilled /> Delete{" "}
              </Button>
            </div>
            )}

          {save === true ? (
            <ModalComponent
              id={data.Id}
              getFunc={handleGetSingleLanguage}
              title={"Update Language"}
              buttonText={<><EditFilled/>  Edit</>}
              closeReturnData={fromCloseData}
              openReturnData={openReturnData}
            >
              {UpdatedForm}
            </ModalComponent>
          ) : (
            <Button
              htmlType="submit"
              disabled={closeReturnData.isLoading}
              loading={closeReturnData.isLoading}
            >
              <FormattedMessage id="nav.save" />
            </Button>
          )}
        </Form>
      </Panel> 
    </Collapse>
    )
} 

export default LanguageForm
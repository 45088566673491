import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getAllLanguageStart = () => ({
  type: types.GET_ALL_LANGUAGES_START
});

export const getAllLanguageSuccess = payload => ({
  type: types.GET_ALL_LANGUAGES_SUCCESS,
  payload
});

export const getAllLanguageFail = payload => ({
  type: types.GET_ALL_LANGUAGES_FAIL,
  payload
});

export const getAllLanguageCleanup = () =>({
  type: types.GET_ALL_LANGUAGES_CLEANUP
});

export const getAllLanguage = () => async dispatch => {
  try {

    dispatch(getAllLanguageStart());
    const requestObj = {
      path: "language",
      method: "GET",
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(getAllLanguageSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getAllLanguageFail(error));
  }
}

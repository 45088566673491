import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const updateEmergencyContactStart = () => ({
  type: types.UPDATE_EMERGENCY_CONTACT_START
});

export const updateEmergencyContactSuccess = payload => ({
  type: types.UPDATE_EMERGENCY_CONTACT_SUCCESS,
  payload
});

export const updateEmergencyContactFail = payload => ({
  type: types.UPDATE_EMERGENCY_CONTACT_FAIL,
  payload
});

export const updateEmergencyContactCleanup = () =>({
  type: types.UPDATE_EMERGENCY_CONTACT_CLEANUP
});

export const updateEmergencyContact = (emergencycontactsId, payload) => async dispatch => {
  try {

    dispatch(updateEmergencyContactStart());
    const requestObj = {
      path: `emergencycontacts/${emergencycontactsId}`,
      method: "PATCH",
      data: payload,
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(updateEmergencyContactSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(updateEmergencyContactFail(error));
  }
}

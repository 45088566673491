export const SET_LANGUAGE = "English"
export const UPDATE_LANGUAGE = "UPDATE_LANGUAGE"

export const MODIFY_NAV_ACCESS = "MODIFY_NAV_ACCESS"

export const LOGIN_START = "LOGIN_START";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGIN_CLEANUP = "LOGIN_CLEANUP";

export const VERIFY_OTP_START = "VERIFY_OTP_START";
export const VERIFY_OTP_SUCCESS = "VERIFY_OTP_SUCCESS";
export const VERIFY_OTP_FAIL = "VERIFY_OTP_FAIL";
export const VERIFY_OTP_CLEANUP = "VERIFY_OTP_CLEANUP";

export const RESEND_OTP_START = "RESEND_OTP_START";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAIL = "RESEND_OTP_FAIL";
export const RESEND_OTP_CLEANUP = "RESEND_OTP_CLEANUP";

export const SIGNUP_START = "SIGNUP_START";
export const SIGNUP_SUCCESS = "SIGNUP_SUCCESS";
export const SIGNUP_FAIL = "SIGNUP_FAIL";
export const SIGNUP_CLEANUP = "SIGNUP_CLEANUP";

export const RESET_PASSWORD_START = "RESET_PASSWORD_START";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";
export const RESET_PASSWORD_CLEANUP = "RESET_PASSWORD_CLEANUP";

export const GET_PERSONAL_DATA_START = "GET_PERSONAL_DATA_START";
export const GET_PERSONAL_DATA_SUCCESS = "GET_PERSONAL_DATA_SUCCESS";
export const GET_PERSONAL_DATA_FAIL = "GET_PERSONAL_DATA_FAIL";
export const GET_PERSONAL_DATA_CLEANUP = "GET_PERSONAL_DATA_CLEANUP";

export const UPDATE_PERSONAL_DATA_START = "UPDATE_PERSONAL_DATA_START";
export const UPDATE_PERSONAL_DATA_SUCCESS = "UPDATE_PERSONAL_DATA_SUCCESS";
export const UPDATE_PERSONAL_DATA_FAIL = "UPDATE_PERSONAL_DATA_FAIL";
export const UPDATE_PERSONAL_DATA_CLEANUP = "UPDATE_PERSONAL_DATA_CLEANUP";

export const ADD_EDUCATION_START = "ADD_EDUCATION_START";
export const ADD_EDUCATION_SUCCESS = "ADD_EDUCATION_SUCCESS";
export const ADD_EDUCATION_FAIL = "ADD_EDUCATION_FAIL";
export const ADD_EDUCATION_CLEANUP = "ADD_EDUCATION_CLEANUP";

export const GET_ALL_EDUCATIONS_START = "GET_ALL_EDUCATIONS_START";
export const GET_ALL_EDUCATIONS_SUCCESS = "GET_ALL_EDUCATIONS_SUCCESS";
export const GET_ALL_EDUCATIONS_FAIL = "GET_ALL_EDUCATIONS_FAIL";
export const GET_ALL_EDUCATIONS_CLEANUP = "GET_ALL_EDUCATIONS_CLEANUP";

export const DELETE_EDUCATION_START = "DELETE_EDUCATION_START";
export const DELETE_EDUCATION_SUCCESS = "DELETE_EDUCATION_SUCCESS";
export const DELETE_EDUCATION_FAIL = "DELETE_EDUCATION_FAIL";
export const DELETE_EDUCATION_CLEANUP = "DELETE_EDUCATION_CLEANUP";

export const UPDATE_EDUCATION_START = "UPDATE_EDUCATION_START";
export const UPDATE_EDUCATION_SUCCESS = "UPDATE_EDUCATION_SUCCESS";
export const UPDATE_EDUCATION_FAIL = "UPDATE_EDUCATION_FAIL";
export const UPDATE_EDUCATION_CLEANUP = "UPDATE_EDUCATION_CLEANUP";

export const GET_SINGLE_EDUCATION_START = "GET_SINGLE_EDUCATION_START";
export const GET_SINGLE_EDUCATION_SUCCESS = "GET_SINGLE_EDUCATION_SUCCESS";
export const GET_SINGLE_EDUCATION_FAIL = "GET_SINGLE_EDUCATION_FAIL";
export const GET_SINGLE_EDUCATION_CLEANUP = "GET_SINGLE_EDUCATION_CLEANUP";

export const ADD_EMPLOYMENT_START = "ADD_EMPLOYMENT_START";
export const ADD_EMPLOYMENT_SUCCESS = "ADD_EMPLOYMENT_SUCCESS";
export const ADD_EMPLOYMENT_FAIL = "ADD_EMPLOYMENT_FAIL";
export const ADD_EMPLOYMENT_CLEANUP = "ADD_EMPLOYMENT_CLEANUP";

export const GET_ALL_EMPLOYMENTS_START = "GET_ALL_EMPLOYMENTS_START";
export const GET_ALL_EMPLOYMENTS_SUCCESS = "GET_ALL_EMPLOYMENTS_SUCCESS";
export const GET_ALL_EMPLOYMENTS_FAIL = "GET_ALL_EMPLOYMENTS_FAIL";
export const GET_ALL_EMPLOYMENTS_CLEANUP = "GET_ALL_EMPLOYMENTS_CLEANUP";

export const DELETE_EMPLOYMENT_START = "DELETE_EMPLOYMENT_START";
export const DELETE_EMPLOYMENT_SUCCESS = "DELETE_EMPLOYMENT_SUCCESS";
export const DELETE_EMPLOYMENT_FAIL = "DELETE_EMPLOYMENT_FAIL";
export const DELETE_EMPLOYMENT_CLEANUP = "DELETE_EMPLOYMENT_CLEANUP";

export const UPDATE_EMPLOYMENT_START = "UPDATE_EMPLOYMENT_START";
export const UPDATE_EMPLOYMENT_SUCCESS = "UPDATE_EMPLOYMENT_SUCCESS";
export const UPDATE_EMPLOYMENT_FAIL = "UPDATE_EMPLOYMENT_FAIL";
export const UPDATE_EMPLOYMENT_CLEANUP = "UPDATE_EMPLOYMENT_CLEANUP";

export const GET_SINGLE_EMPLOYMENT_START = "GET_SINGLE_EMPLOYMENT_START";
export const GET_SINGLE_EMPLOYMENT_SUCCESS = "GET_SINGLE_EMPLOYMENT_SUCCESS";
export const GET_SINGLE_EMPLOYMENT_FAIL = "GET_SINGLE_EMPLOYMENT_FAIL";
export const GET_SINGLE_EMPLOYMENT_CLEANUP = "GET_SINGLE_EMPLOYMENT_CLEANUP";
//
export const ADD_LANGUAGE_START = "ADD_LANGUAGE_START";
export const ADD_LANGUAGE_SUCCESS = "ADD_LANGUAGE_SUCCESS";
export const ADD_LANGUAGE_FAIL = "ADD_LANGUAGE_FAIL";
export const ADD_LANGUAGE_CLEANUP = "ADD_LANGUAGE_CLEANUP";

export const GET_ALL_LANGUAGES_START = "GET_ALL_LANGUAGES_START";
export const GET_ALL_LANGUAGES_SUCCESS = "GET_ALL_LANGUAGES_SUCCESS";
export const GET_ALL_LANGUAGES_FAIL = "GET_ALL_LANGUAGES_FAIL";
export const GET_ALL_LANGUAGES_CLEANUP = "GET_ALL_LANGUAGES_CLEANUP";

export const DELETE_LANGUAGE_START = "DELETE_LANGUAGE_START";
export const DELETE_LANGUAGE_SUCCESS = "DELETE_LANGUAGE_SUCCESS";
export const DELETE_LANGUAGE_FAIL = "DELETE_LANGUAGE_FAIL";
export const DELETE_LANGUAGE_CLEANUP = "DELETE_LANGUAGE_CLEANUP";

export const UPDATE_LANGUAGE_START = "UPDATE_LANGUAGE_START";
export const UPDATE_LANGUAGE_SUCCESS = "UPDATE_LANGUAGE_SUCCESS";
export const UPDATE_LANGUAGE_FAIL = "UPDATE_LANGUAGE_FAIL";
export const UPDATE_LANGUAGE_CLEANUP = "UPDATE_LANGUAGE_CLEANUP";

export const GET_SINGLE_LANGUAGE_START = "GET_SINGLE_LANGUAGE_START";
export const GET_SINGLE_LANGUAGE_SUCCESS = "GET_SINGLE_LANGUAGE_SUCCESS";
export const GET_SINGLE_LANGUAGE_FAIL = "GET_SINGLE_LANGUAGE_FAIL";
export const GET_SINGLE_LANGUAGE_CLEANUP = "GET_SINGLE_LANGUAGE_CLEANUP";

//
export const ADD_CONSUMER_START = "ADD_CONSUMER_START";
export const ADD_CONSUMER_SUCCESS = "ADD_CONSUMER_SUCCESS";
export const ADD_CONSUMER_FAIL = "ADD_CONSUMER_FAIL";
export const ADD_CONSUMER_CLEANUP = "ADD_CONSUMER_CLEANUP";

export const GET_ALL_CONSUMERS_START = "GET_ALL_CONSUMERS_START";
export const GET_ALL_CONSUMERS_SUCCESS = "GET_ALL_CONSUMERS_SUCCESS";
export const GET_ALL_CONSUMERS_FAIL = "GET_ALL_CONSUMERS_FAIL";
export const GET_ALL_CONSUMERS_CLEANUP = "GET_ALL_CONSUMERS_CLEANUP";

export const DELETE_CONSUMER_START = "DELETE_CONSUMER_START";
export const DELETE_CONSUMER_SUCCESS = "DELETE_CONSUMER_SUCCESS";
export const DELETE_CONSUMER_FAIL = "DELETE_CONSUMER_FAIL";
export const DELETE_CONSUMER_CLEANUP = "DELETE_CONSUMER_CLEANUP";

export const UPDATE_CONSUMER_START = "UPDATE_CONSUMER_START";
export const UPDATE_CONSUMER_SUCCESS = "UPDATE_CONSUMER_SUCCESS";
export const UPDATE_CONSUMER_FAIL = "UPDATE_CONSUMER_FAIL";
export const UPDATE_CONSUMER_CLEANUP = "UPDATE_CONSUMER_CLEANUP";

export const GET_SINGLE_CONSUMER_START = "GET_SINGLE_CONSUMER_START";
export const GET_SINGLE_CONSUMER_SUCCESS = "GET_SINGLE_CONSUMER_SUCCESS";
export const GET_SINGLE_CONSUMER_FAIL = "GET_SINGLE_CONSUMER_FAIL";
export const GET_SINGLE_CONSUMER_CLEANUP = "GET_SINGLE_CONSUMER_CLEANUP";

export const ADD_EMERGENCY_CONTACT_START = "ADD_EMERGENCY_CONTACT_START";
export const ADD_EMERGENCY_CONTACT_SUCCESS = "ADD_EMERGENCY_CONTACT_SUCCESS";
export const ADD_EMERGENCY_CONTACT_FAIL = "ADD_EMERGENCY_CONTACT_FAIL";
export const ADD_EMERGENCY_CONTACT_CLEANUP = "ADD_EMERGENCY_CONTACT_CLEANUP";

export const GET_ALL_EMERGENCY_CONTACTS_START =
  "GET_ALL_EMERGENCY_CONTACTS_START";
export const GET_ALL_EMERGENCY_CONTACTS_SUCCESS =
  "GET_ALL_EMERGENCY_CONTACTS_SUCCESS";
export const GET_ALL_EMERGENCY_CONTACTS_FAIL =
  "GET_ALL_EMERGENCY_CONTACTS_FAIL";
export const GET_ALL_EMERGENCY_CONTACTS_CLEANUP =
  "GET_ALL_EMERGENCY_CONTACTS_CLEANUP";

export const DELETE_EMERGENCY_CONTACT_START = "DELETE_EMERGENCY_CONTACT_START";
export const DELETE_EMERGENCY_CONTACT_SUCCESS =
  "DELETE_EMERGENCY_CONTACT_SUCCESS";
export const DELETE_EMERGENCY_CONTACT_FAIL = "DELETE_EMERGENCY_CONTACT_FAIL";
export const DELETE_EMERGENCY_CONTACT_CLEANUP =
  "DELETE_EMERGENCY_CONTACT_CLEANUP";

export const UPDATE_EMERGENCY_CONTACT_START = "UPDATE_EMERGENCY_CONTACT_START";
export const UPDATE_EMERGENCY_CONTACT_SUCCESS =
  "UPDATE_EMERGENCY_CONTACT_SUCCESS";
export const UPDATE_EMERGENCY_CONTACT_FAIL = "UPDATE_EMERGENCY_CONTACT_FAIL";
export const UPDATE_EMERGENCY_CONTACT_CLEANUP =
  "UPDATE_EMERGENCY_CONTACT_CLEANUP";

export const GET_SINGLE_EMERGENCY_CONTACT_START =
  "GET_SINGLE_EMERGENCY_CONTACT_START";
export const GET_SINGLE_EMERGENCY_CONTACT_SUCCESS =
  "GET_SINGLE_EMERGENCY_CONTACT_SUCCESS";
export const GET_SINGLE_EMERGENCY_CONTACT_FAIL =
  "GET_SINGLE_EMERGENCY_CONTACT_FAIL";
export const GET_SINGLE_EMERGENCY_CONTACT_CLEANUP =
  "GET_SINGLE_EMERGENCY_CONTACT_CLEANUP";

export const GET_CAREGIVER_APPLICATION_DATA_START = "GET_CAREGIVER_APPLICATION_DATA_START";
export const GET_CAREGIVER_APPLICATION_DATA_SUCCESS = "GET_CAREGIVER_APPLICATION_DATA_SUCCESS";
export const GET_CAREGIVER_APPLICATION_DATA_FAIL = "GET_CAREGIVER_APPLICATION_DATA_FAIL";
export const GET_CAREGIVER_APPLICATION_DATA_CLEANUP = "GET_CAREGIVER_APPLICATION_DATA_CLEANUP";

export const APPLY_FOR_CAREGIVER_START = "APPLY_FOR_CAREGIVER_START";
export const APPLY_FOR_CAREGIVER_SUCCESS = "APPLY_FOR_CAREGIVER_SUCCESS";
export const APPLY_FOR_CAREGIVER_FAIL = "APPLY_FOR_CAREGIVER_FAIL";
export const APPLY_FOR_CAREGIVER_CLEANUP = "APPLY_FOR_CAREGIVER_CLEANUP";

export const UPLOAD_FILE_START = "UPLOAD_FILE_START";
export const UPLOAD_FILE_SUCCESS = "UPLOAD_FILE_SUCCESS";
export const UPLOAD_FILE_FAIL = "UPLOAD_FILE_FAIL";
export const UPLOAD_FILE_CLEANUP = "UPLOAD_FILE_CLEANUP";

export const GET_ALL_REQUIRED_DOCUMENTS_START =
  "GET_ALL_REQUIRED_DOCUMENTS_START";
export const GET_ALL_REQUIRED_DOCUMENTS_SUCCESS =
  "GET_ALL_REQUIRED_DOCUMENTS_SUCCESS";
export const GET_ALL_REQUIRED_DOCUMENTS_FAIL =
  "GET_ALL_REQUIRED_DOCUMENTS_FAIL";
export const GET_ALL_REQUIRED_DOCUMENTS_CLEANUP =
  "GET_ALL_REQUIRED_DOCUMENTS_CLEANUP";

export const GET_SINGLE_REQUIRED_DOCUMENTS_START =
  "GET_SINGLE_REQUIRED_DOCUMENTS_START";
export const GET_SINGLE_REQUIRED_DOCUMENTS_SUCCESS =
  "GET_SINGLE_REQUIRED_DOCUMENTS_SUCCESS";
export const GET_SINGLE_REQUIRED_DOCUMENTS_FAIL =
  "GET_SINGLE_REQUIRED_DOCUMENTS_FAIL";
export const GET_SINGLE_REQUIRED_DOCUMENTS_CLEANUP =
  "GET_SINGLE_REQUIRED_DOCUMENTS_CLEANUP";

export const GET_SINGLE_UPLOAD_START = "GET_SINGLE_UPLOAD_START";
export const GET_SINGLE_UPLOAD_SUCCESS = "GET_SINGLE_UPLOAD_SUCCESS";
export const GET_SINGLE_UPLOAD_FAIL = "GET_SINGLE_UPLOAD_FAIL";
export const GET_SINGLE_UPLOAD_CLEANUP = "GET_SINGLE_UPLOAD_CLEANUP";

export const GET_ALL_NAV_MENUS = "GET_ALL_NAV_MENUS";

// ------------ Test-application action-types --------------
export const GET_QUESTION_START = "GET_QUESTION_START";
export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS";
export const GET_QUESTION_FAIL = "GET_QUESTION_FAIL";
export const GET_QUESTION_CLEANUP = "GET_QUESTION_CLEANUP";
export const SELECT_QUESTION_ANSWER = "SELECT_QUESTION_ANSWER";
export const SUBMIT_QUESTIONS_ANSWER = "SUBMIT_QUESTIONS_ANSWER";

// ----------- Get applicant application data ----------------
export const GET_APPLICANT_APPLICATION_START = "GET_APPLICANT_APPLICATION_START";
export const GET_APPLICANT_APPLICATION_SUCCESS = "GET_APPLICANT_APPLICATION_SUCCESS";
export const GET_APPLICANT_APPLICATION_FAIL = "GET_APPLICANT_APPLICATION_FAIL";
export const GET_APPLICANT_APPLICATION_CLEANUP = "GET_APPLICANT_APPLICATION_CLEANUP";
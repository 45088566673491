import * as types from '../../action-types';
import {getAllNavMenus as initialState } from "../../initialState";

const getAllNavMenus = (state=initialState, action) => {
  switch (action.type) {
    case types.GET_ALL_NAV_MENUS:
      return {
        ...state,
        data: action.payload,
      };
    default:
      return state;
  }
}

export default getAllNavMenus;

import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const addEmploymentStart = () => ({
  type: types.ADD_EMPLOYMENT_START
});

export const addEmploymentSuccess = payload => ({
  type: types.ADD_EMPLOYMENT_SUCCESS,
  payload
});

export const addEmploymentFail = payload => ({
  type: types.ADD_EMPLOYMENT_FAIL,
  payload
});

export const addEmploymentCleanup = () =>({
  type: types.ADD_EMPLOYMENT_CLEANUP
});

export const addEmployment = payload => async dispatch => {
  try {

    dispatch(addEmploymentStart());
    const requestObj = {
      path: "experiences",
      method: "POST",
      data: payload,
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(addEmploymentSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(addEmploymentFail(error));
  }
}

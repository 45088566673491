import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const addConsumerStart = () => ({
  type: types.ADD_CONSUMER_START
});

export const addConsumerSuccess = payload => ({
  type: types.ADD_CONSUMER_SUCCESS,
  payload
});

export const addConsumerFail = payload => ({
  type: types.ADD_CONSUMER_FAIL,
  payload
});

export const addConsumerCleanup = () =>({
  type: types.ADD_CONSUMER_CLEANUP
});

export const addConsumer = payload => async dispatch => {
  try {

    dispatch(addConsumerStart());
    const requestObj = {
      path: "consumers",
      method: "POST",
      data: payload,
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(addConsumerSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(addConsumerFail(error));
  }
}

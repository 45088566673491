import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const language = payload => ({
  type: types.SET_LANGUAGE,
  payload
});

export const updateLanguageInSERVER = payload => async ()=>{
  //alert('language update start')
  try{
    const requestObj = {
      path: "users/",
      method: "PATCH",
      data: payload,
    }
    console.log(requestObj.payload);
    const  res  = await AxiosCall(requestObj);
   // console.log('result from udating language in server is :', res);
   // alert(res.data.status)
  }catch(err){
    const error = ErrorHandler(err);
    console.log(err);
  }
}

import Axios from 'axios';

const SERVER_HOST = process.env.SERVER_HOST ? process.env.SERVER_HOST:(process.env.REACT_APP_SERVER_HOST ? process.env.REACT_APP_SERVER_HOST:'');
let baseURL;
if(SERVER_HOST){
  baseURL = `${SERVER_HOST}/api/`;
}else{
   // const host = "http://localhost:4000";
  const host='https://stagingroyalcare.baobabtesting.com';

  baseURL = `${host}/api/`;
}

const AxiosCall = async requestObj => {
  const { path, method, data, contentType,  version = 'v1' } = requestObj;
  console.log(data);
  const token = localStorage.getItem('authToken');

  let headers = {
    Authorization: `Bearer `+ token,
    'Content-Type': contentType || 'application/json'
  };

  const url = `${baseURL}${version}/${path}`;
  try {
    const response = await Axios({ method, url, data, headers, json: true });
    const result = response && response.data;
    return result;
  } catch (error) {

    if(error.response.status === 401){
      localStorage.setItem('authToken', '');
      window.location.href = `/login?lastPath=${window.location.pathname}`;

    }
    throw error;
  }
};

export default AxiosCall;
import { Form, Input, Button, Row, Col, Collapse } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import applicationLayoutStyles from "components/Layouts/AuthLayout/application.module.css";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";

//components
import ModalComponent from "../../../../components/Modal";

const { Panel } = Collapse;

const ConsumerForm = ({
  formType,
  disabled,
  data,
  deleteSingleConsumer,
  deleteConsumerState,
  UpdatedForm,
  save,
  formName,
  getSingleConsumerDataFunc,
  updateConsumer,
  closeReturnData,
  openReturnData,
  defaultActiveKey,
  PhoneNumberValidation,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl()
  const onFinish = (values) => {
    dispatch(updateConsumer(values.Id, values));
  };
  const [Name, setName] = useState(null);
  const [Consumer_Number__c, setConsumer_Number__c] = useState(null);
  const [Street__c, setStreet__c] = useState(null);
  const [City__c, setCity__c] = useState(null);
  const [State__c, setState__c] = useState(null);
  const [Zip__c, setZip__c] = useState(null);

  const [fromCloseData, setFromCloseData] = useState({
    data: null,
    error: null,
    isLoading: false,
    isSuccessful: false,
  });
  const handleSingleConsumerGet = (id) => {
    getSingleConsumerDataFunc(id);
  };

  useEffect(() => {
    setFromCloseData(closeReturnData);
  }, [closeReturnData]);

  console.log('Consumer Number',Consumer_Number__c)

  return (
    <Collapse
      defaultActiveKey={1}
    >
      <Panel collapsible={true} header={data.Name} key={1}>
        <Form
          className={applicationLayoutStyles.updateForm}
          form={formType}
          name={formName}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            style={{ display: "none", visibility: "hidden" }}
            name="Id"
          ></Form.Item>
          <div style={{ height: "38px" }}></div>

          <Row gutter={32}>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                label={<FormattedMessage id="consumer.name" />}
                name="Name"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="validation.contactNameIsRequired" />,
                  },
                ]}
              >
                <Input
                  placeholder={data.Name}
                  disabled={disabled}
                  value={Name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                label={<FormattedMessage id="emergencycontact.phoneNumber" />}
                name="Consumer_Number__c"
                rules={[
                  {
                    required: true,
                    message:<FormattedMessage id="validation.phoneNumberIsRequired" />,
                  },
                  {
                    validator: PhoneNumberValidation,
                  },
                ]}
                // initialValue={{
                //   short: "us",
                // }}
              >
                <Input
                  placeholder={data.Consumer_Number__c}
                  disabled={disabled}
                  value={Consumer_Number__c}
                  onChange={(e) => {
                    setConsumer_Number__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12} xs={24} lg={12}>
              <Form.Item label={<FormattedMessage id="address.streetAddress" />} name="Street__c">
                <Input
                  rules={[
                    {
                      required: true,
                      message: <FormattedMessage id="validation.streetAddressIsRequired" />,
                    },
                  ]}
                  placeholder={data.Street__c}
                  disabled={disabled}
                  value={Street__c}
                  onChange={(e) => {
                    setStreet__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
               rules={[
                {
                  required: true,
                  message: <FormattedMessage id="validation.mailingCityIsRequired" />,
                },
              ]}
              label={<FormattedMessage id="address.city" />} name="City__c">
                <Input
                 
                  placeholder={data.City__c}
                  disabled={disabled}
                  value={City__c}
                  onChange={(e) => {
                    setCity__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
              
              rules={[
                {
                  required: true,
                  message:<FormattedMessage id="validation.stateIsRequired" />,
                },
              ]}
              label={<FormattedMessage id="address.state" />} name="State__c">
                <Input
                  
                  placeholder={data.State__c}
                  disabled={disabled}
                  value={State__c}
                  onChange={(e) => {
                    setState__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
               rules={[
                {
                  required: true,
                  message: <FormattedMessage id="validation.zipCodeIsRequired" />,
                },
              ]}
              label="Zipcode" name="Zip__c">
                <Input
                 
                  placeholder={data.Zip__c}
                  disabled={disabled}
                  value={Zip__c}
                  onChange={(e) => {
                    setZip__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>

          {deleteConsumerState === null ? null : (
            <div
              className={applicationLayoutStyles.addNewItemWrapper}
              style={{ width: "100%", position: "relative" }}
            >
              <Button
                loading={deleteConsumerState.isLoading}
                type="button"
                onClick={() => deleteSingleConsumer(data.Id)}
                style={{ position: "absolute", right: 0 }}
              >
                <DeleteFilled /> Delete{" "}
              </Button>
            </div>
          )}
          {save === true ? (
            <ModalComponent
              id={data.Id}
              getFunc={handleSingleConsumerGet}
              title={"Update Consumer"}
              buttonText={<><EditFilled/>  Edit </>}
              closeReturnData={fromCloseData}
              openReturnData={openReturnData}
            >
              {UpdatedForm}
            </ModalComponent>
          ) : (
            <Button
              htmlType="submit"
              disabled={closeReturnData.isLoading}
              loading={closeReturnData.isLoading}
            >
              <FormattedMessage id="nav.save"/>
            </Button>
          )}
        </Form>
      </Panel>
    </Collapse>
  );
};

export default ConsumerForm;

import * as types from '../../action-types'
import AxiosCall from 'utils/axios'
import ErrorHandler from 'utils/error-handler'
import { Axios } from 'axios'

export const verify_otp_start = ()=>({
    type: types.VERIFY_OTP_START
})

export const verify_otp_success = (payload)=>({
    type: types.VERIFY_OTP_SUCCESS,
    payload
})

export const verify_otp_fail = (payload)=>({
    type: types.VERIFY_OTP_FAIL,
    payload
})

export const verify_otp_cleanUp = ()=>({
    type: types.VERIFY_OTP_CLEANUP
})

export const verify_otp = payload=> async dispatch=> {
    //console.log(payload);
    try{
        dispatch(verify_otp_start())
        const requestBody = {
            path: "auth/verify_otp",
            method: "POST",
            data: payload,
        }

        const response = await AxiosCall(requestBody)
        localStorage.setItem('authToken', response.data.token)
        dispatch(verify_otp_success(response.data))
        console.log(response.data)
    }catch(err){
        console.log(err.response?.data);
        const error = ErrorHandler(err)
        console.log(error)
        dispatch(verify_otp_fail(error))
        // console.log(error);
        
        
    }
}
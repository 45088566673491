import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getSingleLanguageStart = () => ({
  type: types.GET_SINGLE_LANGUAGE_START
});

export const getSingleLanguageSuccess = payload => ({
  type: types.GET_SINGLE_LANGUAGE_SUCCESS,
  payload
});

export const getSingleLanguageFail = payload => ({
  type: types.GET_SINGLE_LANGUAGE_FAIL,
  payload
});

export const getSingleLanguageCleanup = () =>({
  type: types.GET_SINGLE_LANGUAGE_CLEANUP
});

export const getSingleLanguage = languageId => async dispatch => {
  try {

    dispatch(getSingleLanguageStart());
    const requestObj = {
      path: `language/${languageId}`,
      method: "GET",
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(getSingleLanguageSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getSingleLanguageFail(error));
  }
}

import { Form, Input, Button, Select, Row, Col, Collapse, DatePicker } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import applicationLayoutStyles from "components/Layouts/AuthLayout/application.module.css";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import moment from "moment";
//components
import ModalComponent from "../../../../components/Modal";
import { FormattedMessage, useIntl } from "react-intl";
const { Panel } = Collapse;
const { Option } = Select;

const EducationForm = ({
  formType,
  disabled,
  data,
  grade,
  deleteSingleEducation,
  deleteEducationState,
  UpdatedForm,
  save,
  formName,
  getSingleEducationDataFunc,
  updateEducation,
  closeReturnData,
  openReturnData,
  grouped,
  defaultActiveKey
}) => {
  const dispatch = useDispatch();
  const intl = useIntl()
  const onFinish = (values) => {
    values.Graduation_Year__c = new Date(
      values.Graduation_Year__c
    ).getFullYear();
    dispatch(updateEducation(values.Id, values));
  };
  const [Type__c, setType__c] = useState(null);
  const [School_Name__c, setSchool_Name__c] = useState(null);
  const [Graduation_Year__c, setGraduation_Year__c] = useState(null);
  const [Describe_Course_of_Study__c, setDescribe_Course_of_Study__c] =
    useState(null);
  const [
    Describe_Specialized_Training__c,
    setDescribe_Specialized_Training__c,
  ] = useState(null);
  const [Diploma_Degree__c, setDiploma_Degree__c] = useState(null);
  const [fromCloseData, setFromCloseData] = useState({
    data: null,
    error: null,
    isLoading: false,
    isSuccessful: false,
  });
  const handleSingleEducationGet = (id) => {
    getSingleEducationDataFunc(id);
  };

  useEffect(() => {
    setFromCloseData(closeReturnData);
  }, [closeReturnData]);

  return (
    <Collapse defaultActiveKey={"1"} accordion>
    <Panel collapsible={true} header={data.School_Name__c} key={"1"}>

    <Form className={applicationLayoutStyles.updateForm} form={formType} name={formName} layout="vertical" onFinish={onFinish}>
      <Form.Item
        style={{ display: "none", visibility: "hidden" }}
        name="Id"
      ></Form.Item>
      <div style={{height:"38px"}}></div>
      <Row gutter={32}>
        <Col span={12}  xs={24} lg={12}>
          <Form.Item
            label={<FormattedMessage id="educationHistory.levelCompleted"/>}
            name="Type__c"
            rules={[
              {
                required: true,
                message: <FormattedMessage id="validation.levelOfEducation" />,
              },
            ]}
          >
            {grouped === true ? (
              <Input placeholder={data.Type__c} disabled={disabled} />
            ) : (
              <Select
                placeholder={data.Type__c}
                disabled={disabled}
                value={Type__c}
                onChange={(e) => setType__c(e)}
              >
                <Option value="Graduate/Professional">
                  <FormattedMessage id="choices.educationLevelCompleted.graduateProfessional" />
                </Option>
                <Option value="College/University"> <FormattedMessage id="choices.educationLevelCompleted.collegeUniversity" /></Option>
                <Option value="High School"> <FormattedMessage id="choices.educationLevelCompleted.highschool" /></Option>
              </Select>
            )}
          </Form.Item>
        </Col>
        <Col span={12}  xs={24} lg={12}>
          <Form.Item
            label={<FormattedMessage id="educationHistory.schoolName" /> }
            name="School_Name__c"
            rules={[
              {
                required: true,
                message:<FormattedMessage id="validation.schoolNameIsRequired" />,
              },
            ]}
          >
            <Input
              placeholder={data.School_Name__c}
              disabled={disabled}
              value={School_Name__c}
              onChange={(e) => setSchool_Name__c(e.target.value)}
            />
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={32}>
        <Col span={12}  xs={24} lg={12}>
          <Form.Item label={<FormattedMessage id="educationHistory.graduationYear" />} name="Graduation_Year__c">
            {grouped === true ? (
              <Input placeholder={data.Graduation_Year__c} disabled={disabled} />
            ) : (
              <DatePicker
                 disabled={disabled}
                placeholder={data.Graduation_Year__c}
                allowClear
                value={ new Date(Graduation_Year__c).getFullYear() === 0 ? null : new Date(Graduation_Year__c).getFullYear()}
                defaultValue={   Graduation_Year__c === null
                  ? moment(new Date(), "YYYY")
                  : moment(Graduation_Year__c , "YYYY")}
              onChange={(e)=>setGraduation_Year__c(new Date(e).getFullYear())} 
              picker="year" />

            )}
          </Form.Item>
        </Col>
        <Col span={12}  xs={24} lg={12}>
          <Form.Item label={<FormattedMessage id="educationHistory.courseOfStudy" />} name="Describe_Course_of_Study__c">
            <Input
              placeholder={data.Describe_Course_of_Study__c}
              disabled={disabled}
              value={Describe_Course_of_Study__c}
              onChange={(e) => {
                setDescribe_Course_of_Study__c(e.target.value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item label={<FormattedMessage id="educationHistory.diploma" />} name="Diploma_Degree__c">
            <Input
              placeholder={data.Diploma_Degree__c}
              disabled={disabled}
              value={Diploma_Degree__c}
              onChange={(e) => {
                setDiploma_Degree__c(e.target.value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>

      <Row>
        <Col span={24}>
          <Form.Item
            label={<FormattedMessage id="educationHistory.specializedTrainingPrompt" />}
            name="Describe_Specialized_Training__c"
          >
            <Input.TextArea
              placeholder={data.Describe_Specialized_Training__c}
              disabled={disabled}
              value={Describe_Specialized_Training__c}
              onChange={(e) => {
                setDescribe_Specialized_Training__c(e.target.value);
              }}
            />
          </Form.Item>
        </Col>
      </Row>
      {deleteEducationState === null ? null : (
        <div
          className={applicationLayoutStyles.addNewItemWrapper}
          style={{ width: "100%", position: "relative" }}
        >
          <Button
            loading={deleteEducationState.isLoading}
            type="button"
            onClick={() => deleteSingleEducation(data.Id)}
            style={{ position: "absolute", right: 0 }}
          >
                <DeleteFilled /> Delete{" "}
          </Button>
        </div>
      )}
      {save === true ? (
        <ModalComponent
          id={data.Id}
          getFunc={handleSingleEducationGet}
          title={"Update Education"}
          buttonText={<><EditFilled/>  Edit</>}
          closeReturnData={fromCloseData}
          openReturnData={openReturnData}
        >
          {UpdatedForm}
        </ModalComponent>
      ) : (
        <Button
          htmlType="submit"
          disabled={closeReturnData.isLoading}
          loading={closeReturnData.isLoading}
        >
          <FormattedMessage id="nav.save" />
        </Button>
      )}
    </Form>
    </Panel>
    </Collapse>
  );
};

export default EducationForm;

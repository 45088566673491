import React from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";
import { PrivateRoute, AuthRoute, PublicRoute } from "./routes-config/index";
import Error404 from "../views/Error404";
import Logout from "../utils/logout/index.js";
import Home from "views/Home";
import Login from "views/Auth/login.js";
import Register from "views/Auth/register.js";
import Language from "views/Language"

//-- Register sublinks --//
import RegisterPersonalInfo from "views/Auth/register/personal.js";
import RegisterEducationalInfo from "views/Auth/register/education/index.js";
import RegisterEmploymentHistory from "views/Auth/register/employment";
import RegisterConsumer from "views/Auth/register/consumer";
import RegisterEmergency from "views/Auth/register/emergency/index";
import RegisterCaregiverApplication from "views/Auth/register/caregiver";
import RegisterDocuments from "views/Auth/register/documents";
import PreviewDocument from "views/Auth/register/documents/preview";
import TestApplication from "views/Auth/register/applicant-test/testApplication";
import LanguagePage from "views/Auth/register/language";
import ResetPassword from "views/Auth/reset.js";
import ForgotPassword from "views/Auth/forgot.js";
import VerifyEmail from "views/Auth/verify.js";
import OTPVerification from "views/Auth/otp";

// ---------- import unauthenticated pages ----------

const AllRoutes = () => (
  <BrowserRouter>
    <Routes>
      <Route path="/404" element={<Error404 />} />

      <Route path="/logout" element={<Logout />} />
      <Route
        path="/login"
        element={
          <PublicRoute>
            {" "}
            <Login />
          </PublicRoute>
        }
      />
      <Route
        path="/"
        element={
          <PublicRoute>
            {" "}
            <Language />
          </PublicRoute>
        }
      />
      <Route
        path="/register"
        element={
          <AuthRoute>
            <Register />
          </AuthRoute>
        }
      />
      <Route
        path="/hha"
        element={<Navigate to="/register?type=hha" replace />}
      />
      <Route
        path="/cdpap"
        element={<Navigate to="/register?type=cdpap" replace />}
      />
      <Route
        path="/forgot-password"
        element={
          <AuthRoute>
            <ForgotPassword />
          </AuthRoute>
        }
      />
      <Route
        path="/reset-password"
        element={
          <AuthRoute>
            <ResetPassword />
          </AuthRoute>
        }
      />
      <Route
        path="/register/personal"
        element={
          <PrivateRoute>
            {" "}
            <RegisterPersonalInfo />
          </PrivateRoute>
        }
      />
      <Route
        path="/register/education"
        element={
          <PrivateRoute>
            {" "}
            <RegisterEducationalInfo />
          </PrivateRoute>
        }
      />
      <Route
        path="/register/employment"
        element={
          <PrivateRoute>
            {" "}
            <RegisterEmploymentHistory />
          </PrivateRoute>
        }
      />
      <Route path="/register/emergency" element={<RegisterEmergency />} />
      <Route path="/register/language" element={<LanguagePage />} />
      <Route path="/register/consumer" element={<RegisterConsumer />} />
      <Route path="/register/caregiver" element={<RegisterCaregiverApplication />} />
      <Route path="/register/documents" element={<RegisterDocuments />} />
      <Route path="/register/preview/:id" element={<PreviewDocument />} />
      <Route path="/register/test" element={<TestApplication />} />
      <Route path="/verify-email" element={<VerifyEmail />} />
      <Route path="/verify-otp" element={<OTPVerification />} />
      {/* <Route path="/applicant-test" element={<TestApplication />} /> */}
      <Route
        path="/dashboard"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route path="*" element={<Error404 />} />
    </Routes>
  </BrowserRouter>
);

export default AllRoutes;

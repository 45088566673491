import React, { useEffect, useState, useRef } from "react";
import ApplicationLayout from "components/Layouts/AuthLayout/application";
import applicationLayoutStyles from "components/Layouts/AuthLayout/application.module.css";
import CountryPhoneInput from "antd-country-phone-input";
import { Form, Input, Button, Row, Col, message } from "antd";
import { SaveOutlined, SyncOutlined } from "@ant-design/icons";
import { PhoneNumberValidation } from "../../../../utils/registration-form-validators/index";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

//actions
import {
  addConsumer,
  addConsumerCleanup,
} from "../../../../store/actions/add-consumer";
import {
  deleteConsumer,
  deleteConsumerCleanup,
} from "../../../../store/actions/delete-consumer";
import {
  getAllConsumers,
  getAllConsumersCleanup,
} from "../../../../store/actions/get-all-consumers";
import {
  updateConsumer,
  updateConsumerCleanup,
} from "../../../../store/actions/update-consumer";
import {
  getSingleConsumer,
  getSingleConsumerCleanup,
} from "../../../../store/actions/get-single-consumer";

//utils
import Notification from "../../../../utils/notification";
import Loader from "../../../../components/Loader";
import { loadScript, handleScriptLoad } from "../../../../utils/helpers";

import { navDict } from "../../../../utils/helpers/data";

//components
import ConsumerForm from "./Form.js";
let autoComplete;

const RegisterConsumeralInfo = () => {
  //hooks
  const location = useLocation();
  const appType = location.state?.applicantType ?? "CDPAP";
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [newForm] = Form.useForm();
  const addConsumerState = useSelector((s) => s.addConsumer);
  const getAllConsumerState = useSelector((s) => s.getAllConsumers);
  const deleteConsumerState = useSelector((s) => s.deleteConsumer);
  const updateConsumerState = useSelector((s) => s.updateConsumer);
  const getSingleConsumerState = useSelector((s) => s.getSingleConsumer);
  const [allConsumers, setAllConsumers] = useState([]);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [Street__c, setStreet__c] = useState("");
  const [City__c, setCity__c] = useState("");
  const [State__c, setState__c] = useState("");
  const [Country__c, setCountry__c] = useState("");
  const [Zip__c, setZip__c] = useState("");

  const [query, setQuery] = useState("");
  const autoCompleteRef = useRef(null);
  const [disabledButton, setDisabledButton] = useState(false);
  const intl = useIntl()
  /**
   * Handle a
   * @param {string} addess
   */
  const updateAddressField = (address) => {
    setQuery(address);
    form.setFieldsValue({ address: address });
  };

  //functions for api calls
  const createConsumer = (values) => {
    values.Consumer_Number__c = `+${values.Consumer_Number__c.code}${values.Consumer_Number__c.phone}`.replace(/\+/g,""); //TODO : better handle this;;
    values.Street__c = Street__c;
    values.City__c = City__c;
    values.State__c = State__c;
    values.Country__c = Country__c;
    values.Zip__c = Zip__c;
    dispatch(addConsumer(values));
  };
  const deleteSingleConsumer = (id) => {
    dispatch(deleteConsumer(id));
  };
  const getSingleConsumerData = (id) => {
    dispatch(getSingleConsumer(id));
  };

  //useEffect for api calls
  useEffect(() => {
    document.title =  `${intl.formatMessage({id:"web.websiteTitle"})}-${intl.formatMessage({id:'caregiverApp.pageHeader'})}`
    window.scrollTo(0,0)
    dispatch(getAllConsumers());
    // ----------- handle google place autocomplete feture on mount ----------
    loadScript(
      `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_API}&libraries=places`,
      () =>
        handleScriptLoad(
          autoComplete,
          updateAddressField,
          autoCompleteRef.current.input
        )
    );
  }, [intl]);

  useEffect(() => {
    if (updateConsumerState.isLoading === true) {
      message.loading({
        content: intl.formatMessage({id:'ui.state.updating'}),
        key: "updatable",
        duration: 1,
      });
      dispatch(updateConsumerCleanup());
    }
    if (updateConsumerState.isSuccessful) {
      dispatch(getAllConsumers());
      dispatch(updateConsumerCleanup());
    } else if (updateConsumerState.error) {
      if (Array.isArray(updateConsumerState.error)) {
        Notification(intl.formatMessage({id:'notification.failed'}), "error", updateConsumerState.error[0].msg);
      } else Notification("Faild", "error", "Failed to update Consumer");
      dispatch(updateConsumerCleanup());
    }
  }, [updateConsumerState]);

  useEffect(() => {
    if (deleteConsumerState.isSuccessful) {
      dispatch(getAllConsumers());
      dispatch(deleteConsumerCleanup());
    } else if (deleteConsumerState.error) {
      if (Array.isArray(deleteConsumerState.error)) {
        Notification(intl.formatMessage({id:'notification.failed'}), "error", deleteConsumerState.error[0].msg);
      } else Notification("Faild", "error", "Failed to delete Consumer");
      dispatch(deleteConsumerCleanup());
    }
  }, [deleteConsumerState]);

  useEffect(() => {
    if (getAllConsumerState.isSuccessful) {
      let consumers = getAllConsumerState.data.map((item) => {
        return {
          ...item,
          address:
            (item?.PersonMailingAddress?.Street__c === undefined
              ? ""
              : item?.Street__c + ", ") +
            (item?.City__c === undefined ? "" : item?.City__c + ", ") +
            (item?.State__c === undefined ? "" : item?.State__c + " ") +
            (item?.Zip__c === undefined ? "" : item?.Zip__c + " ") +
            (item?.Country__c === undefined ? "" : item?.Country__c),
        };
      });
      setAllConsumers(consumers.reverse());
      dispatch(getAllConsumersCleanup());
    } else if (getAllConsumerState.error) {
      dispatch(getAllConsumersCleanup());
    }
  }, [getAllConsumerState]);
  useEffect(() => {
    let address = query.split(", ");

    setStreet__c(address[address.length - 4]);
    setCity__c(address[address.length - 3]);
    setCountry__c(address[address.length - 1]);
    setState__c(address[address.length - 2]?.split(" ")[0]);
    setZip__c(address[address.length - 2]?.split(" ")[1]);
  }, [query]);

  useEffect(() => {
    if (addConsumerState.isSuccessful) {
      Notification(intl.formatMessage({id:'notification.success'}), "success", "Consumer was successfully added");
      form.resetFields();
      Reset();
      dispatch(getAllConsumers());
      dispatch(addConsumerCleanup());
    } else if (addConsumerState.error) {
      if (Array.isArray(addConsumerState.error)) {
        Notification(intl.formatMessage({id:'notification.failed'}), "error", addConsumerState.error[0].msg);
      } else Notification(intl.formatMessage({id:'notification.failed'}), "error", "Failed to add Consumer");

      dispatch(addConsumerCleanup());
    }
  }, [addConsumerState]);

  useEffect(() => {
    if (getSingleConsumerState.isSuccessful) {
      newForm.setFieldsValue({
        Name: getSingleConsumerState.data.Name,
        Consumer_Number__c: getSingleConsumerState.data.Consumer_Number__c,
        Street__c: getSingleConsumerState.data.Street__c,
        City__c: getSingleConsumerState.data.City__c,
        State__c: getSingleConsumerState.data.State__c,
        Zip__c: getSingleConsumerState.data.Zip__c,
        Id: getSingleConsumerState.data.Id,
        address:
          (getSingleConsumerState?.data?.PersonMailingAddress?.Street__c ===
          undefined
            ? ""
            : getSingleConsumerState?.data?.Street__c + ", ") +
          (getSingleConsumerState?.data?.City__c === undefined
            ? ""
            : getSingleConsumerState?.data?.City__c + ", ") +
          (getSingleConsumerState?.data?.State__c === undefined
            ? ""
            : getSingleConsumerState?.data?.State__c + " ") +
          (getSingleConsumerState?.data?.Zip__c === undefined
            ? ""
            : getSingleConsumerState?.data?.Zip__c + ", ") +
          (getSingleConsumerState?.data?.Country__c === undefined
            ? ""
            : getSingleConsumerState?.data?.Country__c),
      });
      dispatch(getSingleConsumerCleanup());
    } else if (getSingleConsumerState.error) {
      if (Array.isArray(getSingleConsumerState.error)) {
        Notification(intl.formatMessage({id:'notification.failed'}), "error", getSingleConsumerState.error[0].msg);
      } else Notification(intl.formatMessage({id:'notification.failed'}), "error", intl.formatMessage({id:'notification.consumer.get.failed'}));
      dispatch(getSingleConsumerCleanup());
    }
  }, [getSingleConsumerState]);

  const Reset = () => {
    form.resetFields();
    setDisabledButton(false);
  };

  const onChange = () => {
    const formValues = form.getFieldsValue();
    const { Name, Consumer_Number__c, address } = formValues;

    if (
      Name?.length > 0 ||
      Consumer_Number__c?.length > 0 ||
      address?.length > 0
    ) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  };
  return (
    <div>
      <ApplicationLayout>
        <div className={applicationLayoutStyles.mainContent}>
          <span><FormattedMessage id="nav.stepLabelPrefix" /> {navDict[appType]?.['steps']?.indexOf(location.pathname) + 1} <FormattedMessage id="std.of" /> {navDict[appType]?.['totalStepsCount']}: <FormattedMessage id="consumer.pageHeader" /></span>
          <h2><FormattedMessage id="consumer.pagePrompt" /></h2>
          <Loader isLoading={addConsumerState.isLoading} text={<FormattedMessage id="ui.loading" />}>
            <Form
              form={form}
              name={"consumer"}
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
              onFinish={createConsumer}
              onChange={onChange}
            >
              <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="consumer.name" />}
                    name="Name"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="validation.contactNameIsRequired" />,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="consumer.phoneNumber" />}
                    name="Consumer_Number__c"
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="validation.phoneNumberIsRequired" />,
                      },
                      {
                        validator: PhoneNumberValidation,
                      },
                    ]}
                    initialValue={{
                      short: "us",
                    }}
                  >
                    <CountryPhoneInput
                      onChange={(p) => {
                        setPhoneNumber(`+${p.code}${p.phone}`);
                      }}
                    />
                  </Form.Item>
                </Col>
              </Row>

              <Row>
                <Col span={24}>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: <FormattedMessage id="validation.addressIsRequired" />,
                      },
                    ]}
                    label={<FormattedMessage id="consumer.address" />}
                    name="address"
                  >
                    <Input
                      placeholder="Enter Address"
                      ref={autoCompleteRef}
                      onChange={(event) => setQuery(event.target.value)}
                    />
                  </Form.Item>
                </Col>
              </Row>

              {disabledButton === true ? (
                <div className={applicationLayoutStyles.addNewItemWrapper}>
                  <Button onClick={Reset} danger size="large" style={{ fontSize: '120%'}}>
                    <SyncOutlined  />{<FormattedMessage id="nav.reset" />}
                  </Button>
                  <Button type="primary" htmlType="submit" size="large" style={{ fontSize: '120%'}}>
                    <SaveOutlined  />{<FormattedMessage id="nav.save" />}
                  </Button>
                </div>
              ) : null}

              <div className={applicationLayoutStyles.formFooter}></div>
            </Form>
          </Loader>
          <Loader isLoading={getAllConsumerState.isLoading} text={<FormattedMessage id="ui.loading" />}>
            {allConsumers.map((a, i) => (
              <ConsumerForm
                UpdatedForm={
                  <ConsumerForm
                    data={{
                      Name: a.Name,
                      Consumer_Number__c: a.Consumer_Number__c,
                      Street__c: a.Street__c,
                      City__c: a.City__c,
                      State__c: a.State__c,
                      Zip__c: a.Zip__c,
                      Id: a.Id,
                    }}
                    disabled={false}
                    formType={newForm}
                    deleteSingleConsumer={null}
                    deleteConsumerState={null}
                    save={false}
                    formName={"updateConsumer"}
                    dispatch={dispatch}
                    updateConsumer={updateConsumer}
                    closeReturnData={{
                      data: null,
                      error: null,
                      isLoading: false,
                      isSuccessful: false,
                    }}
                    openReturnData={null}
                    grouped={false}
                    defaultActiveKey={true}
                  />
                }
                updateForm={null}
                updateConsumer={null}
                formName={"allConsumer"}
                key={i}
                data={a}
                disabled={true}
                deleteSingleConsumer={deleteSingleConsumer}
                deleteConsumerState={deleteConsumerState}
                applicationLayoutStyles={applicationLayoutStyles}
                save={true}
                getSingleConsumerDataFunc={getSingleConsumerData}
                openReturnData={getSingleConsumerState}
                closeReturnData={updateConsumerState}
                grouped={true}
                defaultActiveKey={false}
                PhoneNumberValidation={PhoneNumberValidation}
                CountryPhoneInput={CountryPhoneInput}
                setPhoneNumber={setPhoneNumber}
              />
            ))}{" "}
          </Loader>
          {disabledButton === false ? (
            <div className={applicationLayoutStyles.formFooter}>
              <div className={applicationLayoutStyles.formPrevious}>
                <Button
                  onClick={() => navigate( navDict[appType]['steps'][navDict[appType]['steps'].indexOf(location.pathname) - 1],{state:{ applicantType : appType }})}
                  type="primary"
                >
                  {<FormattedMessage id="nav.previous" />}
                </Button>
              </div>
              <div className={applicationLayoutStyles.formSave}>
                <Button type="primary" 
                onClick={() => navigate("/dashboard")}
                >
                  {<FormattedMessage id="nav.continueLater" />}
                </Button>
                <Button
                  onClick={() => navigate( navDict[appType]['steps'][navDict[appType]['steps'].indexOf(location.pathname) + 1],{state:{ applicantType : appType }})}
                  type="primary"
                >
                  {<FormattedMessage id="nav.next" />}
                </Button>
              </div>
            </div>
          ) : null}
        </div>
      </ApplicationLayout>
    </div>
  );
};

export default RegisterConsumeralInfo;

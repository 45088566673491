import * as types from '../../action-types'
import { resend_otp as initialState } from 'store/initialState'

const resend_otp = (state=initialState, action)=>{
    switch (action.type){

        case types.RESEND_OTP_START:
            console.log('resend otp start')
            return {
                ...state,
                isLoading: true
            };
        case types.RESEND_OTP_SUCCESS:
            console.log('resend otp success')
            return {
                ...state,
                isLoading: false,
                isSuccessful: true,
                data: action.payload
            };
        case types.RESEND_OTP_FAIL:
            console.log('resend otp fail')
            return {
                ...state,
                isLoading: false,
                error: action.payload
            };
        case types.RESEND_OTP_CLEANUP:
            return {
                ...state,
                isLoading: false,
                isSuccessful: false,
                error: null
            };
        default:
            return state;
    }
}

export default resend_otp
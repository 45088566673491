import React, { useEffect, useState } from "react";
import ApplicationLayout from "components/Layouts/AuthLayout/application";
import applicationLayoutStyles from "components/Layouts/AuthLayout/application.module.css";
import {
  Form,
  Input,
  Button,
  Row,
  Select,
  Col,
  message,
  DatePicker,
} from "antd";
import { SaveOutlined , SyncOutlined  } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";

//actions
import {
  addEducation,
  addEducationCleanup,
} from "../../../../store/actions/add-education";
import {
  deleteEducation,
  deleteEducationCleanup,
} from "../../../../store/actions/delete-education";
import {
  getAllEducation,
  getAllEducationCleanup,
} from "../../../../store/actions/get-all-education/index";
import {
  updateEducation,
  updateEducationCleanup,
} from "../../../../store/actions/update-education/index";
import {
  getSingleEducation,
  getSingleEducationCleanup,
} from "../../../../store/actions/get-single-education/index";

//utils
import Notification from "../../../../utils/notification";
import Loader from "../../../../components/Loader";

import { navDict } from "../../../../utils/helpers/data";

//components
import EducationForm from "./Form.js";

const { Option } = Select;

const RegisterEducationalInfo = () => {
  const location = useLocation();
  const appType = location.state?.applicantType ?? "HHA";

  //hooks
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const intl = useIntl()
  const [form] = Form.useForm();
  const [newForm] = Form.useForm();
  const user = useSelector((s) => s.getPersonalData.data);
  const addEducationState = useSelector((s) => s.addEducation);
  const getAllEducationState = useSelector((s) => s.getAllEducations);
  const deleteEducationState = useSelector((s) => s.deleteEducation);
  const updateEducationState = useSelector((s) => s.updateEducation);
  const getSingleEducationState = useSelector((s) => s.getSingleEducation);
  const [allEducations, setAllEducations] = useState([]);
  const [grade, setGrade] = useState([]);
  const [disabledButton, setDisabledButton] = useState(false);

  //functions for api calls
  const createEducation = (values) => {
    values.Graduation_Year__c = new Date(
      values.Graduation_Year__c
    ).getFullYear();
    dispatch(addEducation(values));
  };
  const deleteSingleEducation = (id) => {
    dispatch(deleteEducation(id));
  };
  const getSingleEducationData = (id) => {
    dispatch(getSingleEducation(id));
  };

  //useEffect for api calls
  useEffect(() => {
    if(user?.Can_Go_To_Related_Profile_Info__c===false){
      Notification(
        'Access denied',
        "warning",
        `You are not yet allowed to access this section. Please make sure to complete all previous sections`
      );
      return navigate('/')
    }
    document.title = `${intl.formatMessage({id:"web.websiteTitle"})}-${intl.formatMessage({id:'menu.educationInformation'})}`
    window.scrollTo(0,0)
    let nums = [];
    for (let x = 1; x <= 12; x++) {
      nums.push(x);
    }
    setGrade(nums);
    dispatch(getAllEducation());
  }, [intl, user]);

  useEffect(() => {
    if (updateEducationState.isLoading === true) {
      message.loading({
        content: intl.formatMessage({id:'ui.state.updating'}),
        key: "updatable",
        duration: 1,
      });
      dispatch(updateEducationCleanup());
    }
    if (updateEducationState.isSuccessful) {
      dispatch(getAllEducation());
      dispatch(updateEducationCleanup());
    } else if (updateEducationState.error) {
      if (Array.isArray(updateEducationState.error)) {
        Notification(intl.formatMessage({id:'notification.failed'}), "error", updateEducationState.error[0].msg);
      } else Notification(intl.formatMessage({id:'notification.failed'}), "error", intl.formatMessage({id:'notification.educationHistory.update.failed'}));
      dispatch(updateEducationCleanup());
    }
  }, [updateEducationState]);

  useEffect(() => {
    if (deleteEducationState.isSuccessful) {
      dispatch(getAllEducation());
      dispatch(deleteEducationCleanup());
    } else if (deleteEducationState.error) {
      if (Array.isArray(deleteEducationState.error)) {
        Notification(intl.formatMessage({id:'notification.failed'}), "error", deleteEducationState.error[0].msg);
      } else Notification(intl.formatMessage({id:'notification.failed'}), "error", intl.formatMessage({id:'notification.educationHistory.delete.failed'}));
      dispatch(deleteEducationCleanup());
    }
  }, [deleteEducationState]);

  useEffect(() => {
    if (getAllEducationState.isSuccessful) {
      setAllEducations(getAllEducationState.data.reverse());
      dispatch(getAllEducationCleanup());
    } else if (getAllEducationState.error) {
      dispatch(getAllEducationCleanup());
    }
  }, [getAllEducationState]);

  useEffect(() => {
    if (addEducationState.isSuccessful) {
      Notification(intl.formatMessage({id:'notification.success'}), "success",  intl.formatMessage({id:'notification.educationHistory.add.success'}));
      form.resetFields();
      Reset()
      dispatch(getAllEducation());
      dispatch(addEducationCleanup());
    } else if (addEducationState.error) {
      if (Array.isArray(addEducationState.error)) {
        Notification(intl.formatMessage({id:'notification.failed'}), "error", addEducationState.error[0].msg);
      } else Notification(intl.formatMessage({id:'notification.failed'}), "error", intl.formatMessage({id:'notification.educationHistory.add.failed'}));

      dispatch(addEducationCleanup());
    }
  }, [addEducationState]);

  useEffect(() => {
    if (getSingleEducationState.isSuccessful) {
      newForm.setFieldsValue({
        Type__c: getSingleEducationState.data.Type__c,
        School_Name__c: getSingleEducationState.data.School_Name__c,
        Diploma_Degree__c: getSingleEducationState.data.Diploma_Degree__c,
        Describe_Course_of_Study__c:
          getSingleEducationState.data.Describe_Course_of_Study__c,
        Describe_Specialized_Training__c:
          getSingleEducationState.data.Describe_Specialized_Training__c,
        Id: getSingleEducationState.data.Id,
      });
      dispatch(getSingleEducationCleanup());
    } else if (getSingleEducationState.error) {
      if (Array.isArray(getSingleEducationState.error)) {
        Notification(intl.formatMessage({id:'notification.failed'}), "error", getSingleEducationState.error[0].msg);
      } else Notification(intl.formatMessage({id:'notification.failed'}), "error", intl.formatMessage({id:'notification.educationHistory.get.failed'}));
      dispatch(getSingleEducationCleanup());
    }
  }, [getSingleEducationState]);
  const Reset = () => {
    form.resetFields();
    setDisabledButton(false);
  };


  const onChange = () => {
    const formValues = form.getFieldsValue();
    const {
      Type__c,
      School_Name__c,
      Diploma_Degree__c,
      Describe_Course_of_Study__c,
      Describe_Specialized_Training__c,
      Graduation_Year__c,

    } = formValues;

    if (
      Type__c?.length > 0 ||
      School_Name__c?.length > 0 ||
      Diploma_Degree__c?.length > 0 ||
      Describe_Course_of_Study__c?.length > 0 ||
      Describe_Specialized_Training__c?.length > 0 ||
      Graduation_Year__c?.length > 0
    ) {
      setDisabledButton(true);
    } else {
      setDisabledButton(false);
    }
  };

  let stepNum = navDict[appType]?.['steps']?.indexOf(location.pathname) + 1;
  let totalStepsCount = navDict[appType]?.['totalStepsCount'];

  return (
    <div>
      <ApplicationLayout>
        <div className={applicationLayoutStyles.mainContent}>
          {appType ? <span>
              <FormattedMessage id="nav.step" values={{stepNum,totalStepsCount}} /> : 
            </span>
            : null } <span>{<FormattedMessage id="profile.pageHeader" />}
          </span>
          <h2>{<FormattedMessage id="educationHistory.pagePrompt" />}</h2>
          <Loader isLoading={addEducationState.isLoading} text={<FormattedMessage id="ui.loading" />}>
            {" "}
            <Form
              form={form}
              name={"education"}
              layout="vertical"
              initialValues={{
                remember: true,
              }}
              autoComplete="off"
              onFinish={createEducation}
              onChange={onChange}
            >
              <Row gutter={32}>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="educationHistory.levelCompleted" />}
                    name="Type__c"
                    rules={[
                      {
                        required: false,
                        message: "Level of Education is required",
                      },
                    ]}
                  >
                    <Select placeholder={intl.formatMessage({id:'educationHistory.levelCompletedPrompt'})} allowClear>
                      <Option value="Graduate/Professional">
                        <FormattedMessage id="choices.educationLevelCompleted.graduateProfessional" />
                      </Option>
                      <Option value="College/University">
                      <FormattedMessage id="choices.educationLevelCompleted.collegeUniversity" />
                      </Option>
                      <Option value="High School"><FormattedMessage id="choices.educationLevelCompleted.highschool" /></Option>
                    </Select>
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} lg={12}>
                  <Form.Item
                    label={<FormattedMessage id="educationHistory.schoolName" />}
                    name="School_Name__c"
                    rules={[
                      {
                        required: false,
                        message: intl.formatMessage({id:'validation.schoolNameIsRequired'}),
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>
              <Row  gutter={32}>
                <Col  span={12} xs={24} lg={12}>
                  <Form.Item label={<FormattedMessage id="educationHistory.diploma" />}name="Diploma_Degree__c">
                    <Input />
                  </Form.Item>
                </Col>
                <Col span={12} xs={24} lg={12} >
                  <Form.Item
                    label={<FormattedMessage id="educationHistory.graduationYear" />}
                    name={`Graduation_Year__c`}
                  >
                    <DatePicker
                      placeholder="Graduation Year"
                      allowClear
                      picker="year"
                    />
                  </Form.Item>
                </Col>
              </Row>
              <Row>

                <Col span={24} >
                  <Form.Item
                    label={<FormattedMessage id="educationHistory.courseOfStudy" />}
                    name="Describe_Course_of_Study__c"
                  >
                    <Input />
                  </Form.Item>
                </Col>
              </Row>



              <Row>
                <Col span={24}>
                  <Form.Item
                    label={<FormattedMessage id="educationHistory.specializedTrainingPrompt" />}
                    name="Describe_Specialized_Training__c"
                  >
                    <Input.TextArea />
                  </Form.Item>
                </Col>
              </Row>
              {disabledButton === true ? (
                <div className={applicationLayoutStyles.addNewItemWrapper}>
                  <Button onClick={Reset} danger size="large" style={{ fontSize: '120%'}}>
                    <SyncOutlined  />{<FormattedMessage id="nav.reset" />}
                  </Button>
                  <Button type="primary" htmlType="submit" size="large" style={{ fontSize: '120%'}}>
                    <SaveOutlined  />{<FormattedMessage id="nav.save" />}
                  </Button>
                </div>
              ) : null}
            </Form>
          </Loader>
          <Loader isLoading={getAllEducationState.isLoading} text={<FormattedMessage id="ui.loading" />}>
            {allEducations.map((a, i) => (
              <EducationForm
                UpdatedForm={
                  <EducationForm
                    data={{
                      Type__c: a.Type__c,
                      School_Name__c: a.School_Name__c,
                      Diploma_Degree__c: a.Diploma_Degree__c,
                      Describe_Course_of_Study__c:
                        a.Describe_Course_of_Study__c,
                      Describe_Specialized_Training__c:
                        a.Describe_Specialized_Training__c,
                      Id: a.Id,
                    }}
                    disabled={false}
                    formType={newForm}
                    grade={grade}
                    deleteSingleEducation={null}
                    deleteEducationState={null}
                    save={false}
                    formName={"updateEducation"}
                    dispatch={dispatch}
                    updateEducation={updateEducation}
                    closeReturnData={{
                      data: null,
                      error: null,
                      isLoading: false,
                      isSuccessful: false,
                    }}
                    openReturnData={null}
                    grouped={false}
                    defaultActiveKey={true}
                  />
                }
                updateForm={null}
                updateEducation={null}
                formName={"allEducation"}
                key={i}
                data={a}
                disabled={true}
                grade={grade}
                deleteSingleEducation={deleteSingleEducation}
                deleteEducationState={deleteEducationState}
                applicationLayoutStyles={applicationLayoutStyles}
                save={true}
                getSingleEducationDataFunc={getSingleEducationData}
                openReturnData={getSingleEducationState}
                closeReturnData={updateEducationState}
                grouped={true}
                defaultActiveKey={false}
              />
            ))}{" "}
          </Loader>

          {disabledButton === false ? ( <div className={applicationLayoutStyles.formFooter}>
            <div className={applicationLayoutStyles.formPrevious}>
              <Button
                onClick={() => navigate( navDict[appType]['steps'][navDict[appType]['steps'].indexOf(location.pathname) - 1],{state:{ applicantType : appType }})}
                type="primary"
                htmlType="submit"
              >
                {<FormattedMessage id="nav.previous" />}
              </Button>
            </div>
            <div className={applicationLayoutStyles.formSave}>
              <Button type="primary"
              onClick={() => navigate("/dashboard")}
              >
                {<FormattedMessage id="nav.continueLater" />}
              </Button>
              <Button
                onClick={() => navigate( navDict[appType]['steps'][navDict[appType]['steps'].indexOf(location.pathname) + 1],{state:{ applicantType : appType }})}
                type="primary"
                htmlType="submit"
              >
                {<FormattedMessage id="nav.next" />}
              </Button>
            </div>
          </div>): null}
        </div>
      </ApplicationLayout>
    </div>
  );
};

export default RegisterEducationalInfo;

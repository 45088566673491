import React, { useState } from "react";
import { Modal, Button } from "antd";
import { useEffect } from "react";
const ModalComponent = ({
  children,
  buttonText,
  title,
  getFunc,
  openReturnData,
  closeReturnData,
  id,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    getFunc(id);
  };

  useEffect(() => {
    if (openReturnData.isSuccessful && openReturnData.data?.Id === id) {
      setIsModalVisible(true);
    }
  }, [openReturnData]);

  useEffect(() => {
    if (closeReturnData && closeReturnData.data?.id === id) {
      setIsModalVisible(false);
    }
  }, [closeReturnData]);

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Button
        disabled={openReturnData.isLoading}
        loading={openReturnData.isLoading}
        onClick={showModal}
      >
        {buttonText}
      </Button>
      <Modal
        title={title}
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={null}
      >
        {children}
      </Modal>
    </>
  );
};

export default ModalComponent;

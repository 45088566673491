import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const updatePersonalDataStart = () => ({
  type: types.UPDATE_PERSONAL_DATA_START
});

export const updatePersonalDataSuccess = payload => ({
  type: types.UPDATE_PERSONAL_DATA_SUCCESS,
  payload
});

export const updatePersonalDataFail = payload => ({
  type: types.UPDATE_PERSONAL_DATA_FAIL,
  payload
});

export const updatePersonalDataCleanup = () => ({
  type: types.UPDATE_PERSONAL_DATA_CLEANUP
});

export const updatePersonalData = payload => async dispatch => {

  try {
    dispatch(updatePersonalDataStart());
    const requestObj = {
      path: "users",
      method: "PATCH",
      data: payload,
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(updatePersonalDataSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(updatePersonalDataFail(error));
  }
}

import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const deleteEmploymentStart = () => ({
  type: types.DELETE_EMPLOYMENT_START
});

export const deleteEmploymentSuccess = payload => ({
  type: types.DELETE_EMPLOYMENT_SUCCESS,
  payload
});

export const deleteEmploymentFail = payload => ({
  type: types.DELETE_EMPLOYMENT_FAIL,
  payload
});

export const deleteEmploymentCleanup = () =>({
  type: types.DELETE_EMPLOYMENT_CLEANUP
});

export const deleteEmployment = employmentId => async dispatch => {
  try {

    dispatch(deleteEmploymentStart());
    const requestObj = {
      path: `experiences/${employmentId}`,
      method: "DELETE",
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(deleteEmploymentSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(deleteEmploymentFail(error));
  }
}

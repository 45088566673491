import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const addEmergencyContactStart = () => ({
  type: types.ADD_EMERGENCY_CONTACT_START
});

export const addEmergencyContactSuccess = payload => ({
  type: types.ADD_EMERGENCY_CONTACT_SUCCESS,
  payload
});

export const addEmergencyContactFail = payload => ({
  type: types.ADD_EMERGENCY_CONTACT_FAIL,
  payload
});

export const addEmergencyContactCleanup = () =>({
  type: types.ADD_EMERGENCY_CONTACT_CLEANUP
});

export const addEmergencyContact = payload => async dispatch => {
  try {

    dispatch(addEmergencyContactStart());
    const requestObj = {
      path: "emergencycontacts",
      method: "POST",
      data: payload,
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(addEmergencyContactSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(addEmergencyContactFail(error));
  }
}

import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getCaregiverApplicationDataStart = () => ({
  type: types.GET_CAREGIVER_APPLICATION_DATA_START
});

export const getCaregiverApplicationDataSuccess = payload => ({
  type: types.GET_CAREGIVER_APPLICATION_DATA_SUCCESS,
  payload
});

export const getCaregiverApplicationDataFail = payload => ({
  type: types.GET_CAREGIVER_APPLICATION_DATA_FAIL,
  payload
});

export const getCaregiverApplicationDataCleanup = () => ({
  type: types.GET_CAREGIVER_APPLICATION_DATA_CLEANUP
});

export const getCaregiverApplicationData = ()=> async dispatch => {

  try {
    dispatch(getCaregiverApplicationDataStart());
    const requestObj = {
      path: "caregiver",
      method: "GET",
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(getCaregiverApplicationDataSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getCaregiverApplicationDataFail(error));
  }
}

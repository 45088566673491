import * as types from '../../action-types';
import {language as initialState } from "../../initialState";

const language = (state=initialState, action) => {
  switch (action.type) {
    case types.SET_LANGUAGE:
      //alert('Language setting start');
      return {
        ...state,
        language: action.payload,
        isSuccessfull:true
      };
    
    default:
      return state;
  }
}

export default language;

// ----------- import external dependencies -----------
import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const geUserApplicationsStart = () => ({
  type: types.GET_APPLICANT_APPLICATION_START
})

export const getApplicationSuccess = (payload) => ({
  type: types.GET_APPLICANT_APPLICATION_SUCCESS,
  payload
})

export const getApplicationFail = (payload) => ({
  type: types.GET_APPLICANT_APPLICATION_FAIL,
  payload,
})

export const getApplicationCleanup = () => ({
  type: types.GET_APPLICANT_APPLICATION_CLEANUP
})

/**
 * Get user application stage
 * @param {string} id
 * @returns
 */
export const getUserAplicationStatus = (id) => async (dispatch) => {
  try {

    const reqObj = {
      path: `application/${id}`,
      method: "GET"
    }
    const res = await AxiosCall(reqObj)

    console.log(res)

    dispatch(getApplicationSuccess(res.data))
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getApplicationFail());
  }
}

import { Form, Input, Button, Select, Row, Col, Collapse } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import applicationLayoutStyles from "components/Layouts/AuthLayout/application.module.css";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import { FormattedMessage, useIntl } from "react-intl";
//components
import ModalComponent from "../../../../components/Modal";



const { Panel } = Collapse;

const EmergencyContactForm = ({
  formType,
  disabled,
  data,
  deleteSingleEmergencyContact,
  deleteEmergencyContactState,
  UpdatedForm,
  save,
  formName,
  getSingleEmergencyContactDataFunc,
  updateEmergencyContact,
  closeReturnData,
  openReturnData,
  defaultActiveKey,
  PhoneNumberValidation,
}) => {
  const dispatch = useDispatch();
  const intl = useIntl()
  const onFinish = (values) => {
    dispatch(updateEmergencyContact(values.Id, values));
  };
  const [Name, setName] = useState(null);
  const [RelatioshipNew__c, setRelatioshipNew__c] = useState(null);
  const [Emp_LastName__c, setEmp_LastName__c] = useState(null);
  const [Email__c, setEmail__c] = useState(null);
  const [HomePhone__c, setHomePhone__c] = useState(null);
  const [CellPhone__c, setCellPhone__c] = useState(null);
  const [Zip__c, setZip__c] = useState(null);
  const [State__c, setState__c] = useState(null);
  const [City__c, setCity__c] = useState(null);
  const [Street__c, setStreet__c] = useState(null);

  const [fromCloseData, setFromCloseData] = useState({
    data: null,
    error: null,
    isLoading: false,
    isSuccessful: false,
  });
  const handleSingleEmergencyContactGet = (id) => {
    getSingleEmergencyContactDataFunc(id);
  };

  useEffect(() => {
    setFromCloseData(closeReturnData);
  }, [closeReturnData]);

  return (
    <Collapse
      defaultActiveKey={1}
      accordion
    >
      <Panel 
        collapsible={true} 
        header={data.Name + " " + data.Emp_LastName__c} 
        key={1}>
        <Form
          className={applicationLayoutStyles.updateForm}
          form={formType}
          name={formName}
          layout="vertical"
          onFinish={onFinish}
        >
          <Form.Item
            style={{ display: "none", visibility: "hidden" }}
            name="Id"
          ></Form.Item>

          <Row gutter={32}>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                label={<FormattedMessage id="emergencycontact.firstName" />}
                name="Name"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="register.validation.firstNameIsRequired" />,
                  },
                ]}
              >
                <Input
                  placeholder={data.Name}
                  disabled={disabled}
                  value={Name}
                  onChange={(e) => {
                    setName(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>

            <Col span={12} xs={24} lg={12}>
              <Form.Item
                label={<FormattedMessage id="emergencycontact.lastName" />}
                name="Emp_LastName__c"
                rules={[
                  {
                    required: true,
                    message:<FormattedMessage id="register.validation.lastNameIsRequired" />,
                  },
                ]}
              >
                <Input
                  placeholder={data.Emp_LastName__c}
                  disabled={disabled}
                  value={Emp_LastName__c}
                  onChange={(e) => {
                    setEmp_LastName__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                label={<FormattedMessage id="emergencycontact.relationship" />}
                name="RelatioshipNew__c"
                rules={[
                  {
                    required: true,
                    message: <FormattedMessage id="validation.relationshipIsRequired" />,
                  },
                ]}
              >
                <Input
                  placeholder={data.RelatioshipNew__c}
                  disabled={disabled}
                  value={RelatioshipNew__c}
                  onChange={(e) => {
                    setRelatioshipNew__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                label={<FormattedMessage id="emergencycontact.email" />}
                name="Email__c"
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="register.validation.emailIsRequired" />,
                  },
                ]}
              >
                <Input
                  placeholder={data.Email__c}
                  disabled={disabled}
                  value={Email__c}
                  onChange={(e) => {
                    setEmail__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
          <Col span={12} xs={24} lg={12}>
              <Form.Item
                label={<FormattedMessage id="profile.mobile" />}   
                name="CellPhone__c"
                rules={[
                  {
                    required: true,
                  },
                  {
                    validator: PhoneNumberValidation,
                  },
                ]}
              >
                <Input
                  placeholder={data.CellPhone__c}
                  disabled={disabled}
                  value={CellPhone__c}
                  onChange={(e) => {
                    setCellPhone__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                label={<FormattedMessage id="emergencycontact.homePhone" />}
                name="HomePhone__c"
              >
                <Input
                  placeholder={data.HomePhone__c}
                  disabled={disabled}
                  value={HomePhone__c}
                  onChange={(e) => {
                    setHomePhone__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="validation.streetAddressIsRequired" />,
                  },
                ]}
              label={<FormattedMessage id="address.streetAddress" />} name="Street__c">
                <Input
                  placeholder={data.Street__c}
                  disabled={disabled}
                  value={Street__c}
                  onChange={(e) => {
                    setStreet__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} lg={12}>
              <Form.Item 
                rules={[
                  {
                    required: false,
                    message: "City is required",
                  },
                ]}
              label={<FormattedMessage id="address.city" />} name="City__c">
                <Input
                  placeholder={data.City__c}
                  disabled={disabled}
                  value={City__c}
                  onChange={(e) => {
                    setCity__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={32}>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: false,
                    message: <FormattedMessage id="validation.stateIsRequired" />,
                  },
                ]}
              label={<FormattedMessage id='address.state' />} name="State__c">
                <Input
                  placeholder={data.State__c}
                  disabled={disabled}
                  value={State__c}
                  onChange={(e) => {
                    setState__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
            <Col span={12} xs={24} lg={12}>
              <Form.Item
                rules={[
                  {
                    required: false,
                    message: "Zip Code is required",
                  },
                ]} label="Zipcode" name="Zip__c">
                <Input
                  placeholder={data.Zip__c}
                  disabled={disabled}
                  value={Zip__c}
                  onChange={(e) => {
                    setZip__c(e.target.value);
                  }}
                />
              </Form.Item>
            </Col>
          </Row>
          {deleteEmergencyContactState === null ? null : (
            <div
              className={applicationLayoutStyles.addNewItemWrapper}
              style={{ width: "100%", position: "relative" }}
            >
              <Button
                loading={deleteEmergencyContactState.isLoading}
                type="button"
                onClick={() => deleteSingleEmergencyContact(data.Id)}
                style={{ position: "absolute", right: 0 }}
              >
                <DeleteFilled /> Delete{" "}
              </Button>
            </div>
          )}
          {save === true ? (
            <ModalComponent
              id={data.Id}
              getFunc={handleSingleEmergencyContactGet}
              title={"Update EmergencyContact"}
              buttonText={<><EditFilled/>  Edit</>}
              closeReturnData={fromCloseData}
              openReturnData={openReturnData}
            >
              {UpdatedForm}
            </ModalComponent>
          ) : (
            <Button
              htmlType="submit"
              disabled={closeReturnData.isLoading}
              loading={closeReturnData.isLoading}
            >
              <FormattedMessage id="nav.save" />
            </Button>
          )}
        </Form>
      </Panel>
    </Collapse>
  );
};

export default EmergencyContactForm;

// --------------- import external dependencies ----------------
import React from 'react'
import { Link } from 'react-router-dom'

// ------------- import internal dependencies -------------------
import './index.scoped.css'

const Error404 = () => {
  return (
    <main className="main">
     <article>
      <h1 className="heading">404</h1>
      <span className="not-found">Page not found</span>
      <p className="not-found-details">Oops! The page you are looking for does not exist. It might have been moved or deleted.</p>
     </article>
     <Link to="/" className="return-btn">BACK TO HOME</Link>
    </main>
  )
}

export default Error404

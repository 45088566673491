import React from 'react'
import {Modal} from 'antd'
import appStyles from './helpModal.module.css'

const TermsAndConditionsModal = ({isModalVisible, handleOk, handleCancel})=>{
    return(
        <Modal 
          visible={isModalVisible} 
          footer={null} 
          closable={true} 
          width={700} 
          maskStyle={{background: "#1B2C33D4"}}
          onOk={handleOk} 
          onCancel={handleCancel}
      >
         <div className={appStyles.termsModal} style={{whiteSpace:'pre-line'}}>
            <div style={{marginBottom:'20px'}}>
              <b>TERMS AND CONDITIONS</b> <br/>
              <span>Last Updated on 08/17/2022</span>
            </div>
            <p>
              <i>
              NOTICE: Please read the terms and conditions set forth below, which are legally binding.  By visiting, viewing or using this website and/or by using any program, product, course or service from us, you agree to be bound by 
              these Terms and Conditions and our Privacy Policy and Disclaimer.
              </i>
            </p>
            <p>PLEASE READ THE SECTIONS TITLED “BINDING ARBITRATION” AND “CLASS ACTION WAIVER” AS THEY AFFECT YOUR LEGAL RIGHTS.</p>
            <p>
              Royal Care Portal, https://portal.royalcare.nyc (“website”), which is operated by The Royal Care Inc. (“Company”, “we”, “us”, or “our”) provides visitors information on the website subject to the following terms and conditions (“Terms and Conditions”).  The term “you” refers to any visitor, viewer or user of the website and/or any user of any free or paid program, product, course or service of the Company (each, a “Product”).
            </p>
            <p>
              By viewing, visiting or using the website and/or a Product, you indicate your acceptance and agreement to be bound by these Terms and Conditions and our Privacy Policy and Disclaimer, which are hereby incorporated by reference (collectively, this “Agreement”). 
              If you do not accept the terms and conditions of this Agreement, then please do not use the website or any Products.  These Terms and Conditions were created with the help of the <a href=' https://plugandlaw.com/terms-conditions-generator'>Plug and Law</a> and <a href='https://privacypolicysolutions.com'>Privacy Policy Solutions.</a>
            </p>

            <p>
              We reserve the right to amend this Agreement at any time without notice to you.  We will alert you to any changes by posting the effective date of the latest version at the top of this page, at which point any changes will become immediately effective.  It is your responsibility to check for updates, as your continued use of the website or any Products after this Agreement is amended will constitute your acceptance and agreement to continue to be bound by this Agreement, as amended.
            </p>

            <b>The United States AND OVER 18 USE ONLY</b>
            <p>The website is intended only for individuals over the age of 18 residing in The United States.  We do not make any representations that this website is appropriate or available for use outside of The United States.  If you access the website or any of our Products from outside of The United States, you do so at your own risk and on your own initiative.  It is solely your responsibility to ensure compliance with applicable laws in your specific jurisdiction.</p>

            <b>GUIDELINES FOR USE</b>
            <p>
              <ul>
                <li>You will comply with all applicable law;</li>
                <li>You will not upload, post, send, email, or otherwise make available any information or content which in any way infringes any copyright, trade secret, trademark, right of publicity, privacy, property or other intellectual property or proprietary rights, or any information or content which you do not have the right to make available, through any law, contractual or fiduciary relationship or otherwise;</li>
                <li>You will not act in any way that is fraudulent, false, misleading, deceitful or deceptive, such as by impersonating another individual or falsifying your association with an individual or entity;</li>
                <li>You will not upload, post, send, email, or otherwise make available any material or behave in any manner which could be perceived as harassing, demeaning, threatening, “stalking”, defamatory, sexually explicit, abusive, vulgar, hate speech, obscene, indecent or otherwise objectionable;</li>
                <li>You will not upload, post, send, email, or otherwise make available any material which would reveal the personal information of another individual;</li>
                <li>You will not behave in any manner which could limit or otherwise impact any other person’s use or enjoyment of the website and/or any Product;</li>
                <li>You will not engage in any unsolicited or unauthorized advertising nor will you send any spam;</li>
                <li>You will not attempt to gain unauthorized access to any portion of the website or any of the Products;</li>
                <li>You will not engage in or encourage others to engage in any activity which would violate any law, constitute a criminal offense, give rise to civil liability, or infringe on the rights of any third party;</li>
                <li>You will not send any materials which contain viruses, devices, information collection or transmission mechanisms, trojan horses, worms, time-bombs, adware, keystroke loggers or any other programs or code which would be harmful to, interfere with or attempt to interfere with our systems;</li>
                <li>You will not engage in market research or any research intended to help a competitor; </li>
                <li>You will not deploy any automated query program, such as a bot or spider, at any time or for any purpose without our express written consent; </li>
                <li>You will not block or cover any advertisements on the website; </li>
                <li>With the exception of any personal information you share (which is covered under our Privacy Policy), once you upload, post, send, email, or otherwise make available any material, we have the right to display, repurpose or otherwise use such material in any way; and</li>
                <li>You will notify us through the contact information provided below if you know or have reason to know that a violation of any of our Guidelines has occurred.</li>
              </ul>
            </p>

            <p>It is in our sole discretion to give written consent for you to reproduce, resell, distribute, publicly perform, create derivative works, translate, transmit, post, republish, copy or otherwise use our Intellectual Property.  If you have any questions, please contact us using the contact information provided below.</p>
            <b>NO WARRANTIES</b>
            <p>Your use of this website and any Products is entirely at your risk, as the website and our Products are provided on an “as is” and “as available” basis.  We do not make any express or implied warranties or representations relating to the website, its content and our Products, including but not limited to warranties of title, merchantability, fitness for a particular purpose and non-infringement of third parties’ rights.  We also do not make any express or implied warranties or representations that the website will operate without error or that the website, the servers relied on, our Products and any content is free from computer viruses or other potentially harmful or destructive features.  Some jurisdictions do not permit the exclusion of certain warranties.  As such, some of the exclusions referenced in this section may not apply to you.</p>

            <b>LIMITATION OF LIABILITY</b>
            <p>To the fullest extent permissible by law, the Company and our directors, contractors, successors, joint venture partners, shareholders, agents, affiliates, officers, employees, assignees and licensees, as applicable, shall not be liable for any direct, indirect, special, incidental, consequential, exemplary or other loss or damage, including but not limited to damages for loss of profits, goodwill, business interruption, use or loss of data or other intangible losses, which may directly or indirectly arise out of or be related to your use of or inability to access this website or any Products or your reliance on any advice, opinion, information, representation or omission contained on, or received through this website or any Products, even if we have been advised of the possibility of such damages occurring.  </p>
            <p>This limitation of liability applies whether such liability arises from tort, negligence, breach of contract or any other legal theory of liability.</p>
           
            <b>INDEMNIFICATION</b>
            <p>You shall indemnify, defend and hold harmless the Company and our affiliates, and our respective directors, contractors, successors, joint venture partners, shareholders, agents, affiliates, officers, employees, assignees and licensees from and against any and all damages, liabilities, losses, costs and expenses resulting from any suits, proceedings, judgments, demands, causes of action and claims (collectively, the “Claims”), including legal and accounting fees and expenses, whether or not involving a third party claim, to the extent arising out of, relating to or resulting from: (i) your use or misuse of this website or any of our Products, (ii) your breach of this Agreement, or (iii) your violation of any third party rights, including without limitation any copyright, trade secret, trademark, right of publicity, privacy, property or other intellectual property or proprietary rights.  We will provide you with notice of any Claims, and may in our sole discretion assist you, at your expense, in defending such Claims.  We reserve the right to assume exclusive control of defending any such Claim, at your expense, including choice of legal counsel.  You agree to cooperate and assist us in defending any such Claim.</p>

            <b>LIMITED LICENSE</b>
            <p>Notwithstanding any other provisions in this Agreement, if you purchase any Products or download any of our free Products, we grant you a limited, non-sublicensable, non-transferable, non-exclusive, revocable license (“License”) to use or access the Products for your personal and non-commercial use.  You may not reproduce, resell, distribute, create derivative works, translate, transmit, post, republish, exploit, copy or otherwise use our Products for any commercial or non-personal use.  </p>
            <p>Your License is for individual use.  You have no right to assign any of your rights or transfer any of your obligations under this Agreement.   If we discover that any violation of the terms and conditions in this Agreement has occurred, including violation of the License granted to you, we reserve the right to terminate your access to the Products and invoice you for any damages.</p>

            <b>CONFIDENTIAL INFORMATION</b>
            <p>Please do not upload, post, send, email, or otherwise make available any material that contains any confidential information.</p>

            <b>FEES</b>
            <p>When you purchase any Product through this website, you are responsible for all applicable fees and taxes.  It is your responsibility to provide complete, accurate and up-to-date billing and credit card information.  If you are on a payment plan or recurring payment, it is your responsibility to keep an up-to-date payment method with us.  If your payment method is declined or expired, you hereby agree to pay all applicable fees and taxes due upon demand.  You also agree to pay all costs of collection, including but not limited to attorney’s fees, on any outstanding balance.</p>

            <b>WEBSITE AVAILABILITY</b>
            <p>Your access to the website or Products may be occasionally restricted, such as when we need to make repairs or are introducing new features.  Your access to the website or Products may also become permanently disabled, such as if we decide to terminate the operation of the website or Products.  We cannot guarantee that you will have continuous access to the website or Products.</p>

            <b>NO REFUNDS</b>
            <p>We reserve the right to change our refund policy at any time.  We do not offer refunds for any of our Products under any circumstances.  We believe we provide products and services that are high-quality and at a reasonable price point.</p>

            <b>GOVERNING LAW</b>
            <p>All matters relating to or arising out of this Agreement shall be governed by and construed and interpreted under the laws of the Stateof New York, United States of America, without regard to conflicts of laws principles that would require the application of any other law. </p>

            <b>BINDING ARBITRATION</b>
            <p>In the event that either party asserts that there is a dispute arising out of or relating to this Agreement, such party shall first notify the other party in writing, specifying the nature of the dispute and scheduling a meeting to attempt to resolve the dispute.  </p>
            <p>If no resolution is reached within thirty (30) calendar days of the delivery of the written notice, either party may then elect to exclusively and finally resolve the dispute by binding arbitration by filing a written notice of arbitration in accordance with this section under the subheading “Binding Arbitration” and the Rules of Conciliation and Arbitration of the International Chamber of Commerce applicable at the time of submission of the dispute to arbitration (the “ICC Rules”).  </p>
            <p>Notwithstanding anything to the contrary in the ICC Rules, the following terms and conditions shall apply.</p>
            <p>The arbitration shall be conducted by a panel of three (3) arbitrators.  Each party shall have the right to appoint one (1) arbitrator within fifteen (15) days following the delivery of the written notice of arbitration, and a third arbitrator shall be selected by those two (2) party-appointed arbitrators within thirty (30) days of the appointment of the two (2) party-appointed arbitrators.</p>
            <p>The arbitration proceedings shall be held in Brooklyn, New York, United States of America, in the English language.</p>
            <p>The parties shall equally bear the costs and fees of the arbitration, and each party shall bear its own cost for its own legal expenses.  The arbitrators shall apply the substantive law set forth in the section of this Agreement under the subheading “Governing Law”.  Any arbitration proceeding shall be conducted on a confidential basis.</p>
            <p>The arbitrators shall specify the reason and basis for their decision, the reason for any damages awarded and the breakdown for such damages awarded, and the basis for any other remedy authorized under this Agreement, including but not limited to injunctive relief or specific performance.  For the avoidance of doubt, nothing in this Agreement shall prevent us from seeking injunctive or other equitable relief. </p>
            <p>The decision of the arbitrators shall be considered as a final and binding resolution of the dispute, shall be final and binding on the parties, and shall not be subject to appeal or reexamination.  The award of the arbitrators may be entered as a judgment and enforced by any court of competent jurisdiction.</p>
            <p>TO THE EXTENT PERMITTED BY APPLICABLE LAW, YOU AGREE TO WAIVE YOUR RIGHT TO TRIAL BY JURY IN ANY PROCEEDINGS, ACTION OR COUNTERCLAIM.</p>


            <b>INJUNCTIVE OR OTHER EQUITABLE RELIEF</b>
            <p>We may seek injunctive or other equitable relief that is necessary to protect our rights and intellectual property.  All actions or proceedings related to this Agreement that are not subject to binding arbitration will be brought solely in the state or federal courts of the State of New York, United States of America. You hereby unconditionally and irrevocably consent to the personal and subject matter jurisdiction of those courts for purposes of any such action.</p>

            <b>CLASS ACTION WAIVER</b>
            <p>You agree that any dispute arising out of or relating to this Agreement shall be solely between you and the Company.  </p>
            <p>YOU AGREE TO WAIVE THE RIGHT TO LITIGATE IN COURT OR ARBITRATE ANY CLAIM OR DISPUTE AS A CLASS ACTION.  YOU AGREE THAT YOU WILL ONLY MAKE CLAIMS AGAINST US IN YOUR INDIVIDUAL CAPACITY, AND CANNOT MAKE CLAIMS AGAINST US AS A MEMBER OF A CLASS OR AS A REPRESENTATIVE.</p>
            
            <b>ENTIRE AGREEMENT</b>
            <p>This Agreement, the Privacy Policy and Disclaimer contain the entire agreement between you and the Company with respect to the subject matter hereof and thereof and supersede all prior agreements and undertakings, both written and oral, with respect thereto.</p>

            <b>TERMINATION OF AGREEMENT</b>
            <p>We reserve the right, in our sole discretion, to terminate this Agreement and to terminate, restrict, deny, or suspend your access to the website and all Products at any time and for any purpose without prior notice.  We also reserve the right to discontinue any or all of the website or Products at any time and for any purpose without prior notice.</p>

            <b>SEVERABILITY</b>
            <p>If any term or other provision of this Agreement is held to be invalid, prohibited or unenforceable under applicable law, the other provisions of this Agreement will remain in full force and effect. </p>

            <b>MISCELLANEOUS</b>
            <p>Our failure to act on or delay in exercising any privilege, power or right under this Agreement will not operate as a waiver of such privilege, power or right, and no single or partial exercise of any such privilege, power or right will preclude any other or further exercise of such privilege, power or right or the exercise of any other privilege, power or right. </p>
            <p>Subheadings in this Agreement are used for convenience of reference only and in no way define, describe, limit or extend the scope of this Agreement or the intent of any of its provisions.  They shall not be considered in construing or interpreting this Agreement.</p>
            <p>The terms and conditions of this Agreement shall inure to the benefit of and be binding upon the successors, heirs, executors, administrators, legal representatives and assigns of the Company.  Nothing in this Agreement, express or implied, is intended to confer upon any party other than the Company or our successors, heirs, executors, administrators, legal representatives and assigns, any rights, remedies, obligations or liabilities under this Agreement.  You may not assign any of your rights or transfer any obligations under this Agreement to any other person.</p>
            <p>If any action at law or in equity is necessary to enforce or interpret the terms of this Agreement, the prevailing party shall be entitled to reasonable attorneys’ fees, costs and disbursements in addition to any other relief to which such party may be entitled.</p>

            <b>HOW TO CONTACT US</b>
            <p>If you have any questions, please contact us using the information below.</p>

            <ul>
              <li>By email: <a href='mailto:hr@royalcare.nyc'>hr@royalcare.nyc.</a></li>
            </ul>
         </div>


      </Modal>
    )
}

export default TermsAndConditionsModal;
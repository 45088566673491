import React, {useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
// import DefaultAuthLayout from 'components/Layouts/AuthLayout/default.js';
import defaultLayoutStyles from 'components/Layouts/AuthLayout/default.module.css';
import languageStyles from './language.module.css'
import AuthNavbar from "components/Navbars/AuthNavbar.js";
import { useDispatch, useSelector } from "react-redux";
import { Button} from 'antd';
import { DownOutlined, CheckOutlined } from '@ant-design/icons';

//actions
import { language } from "../../store/actions/language";

const languages = [
    {
        label:'English'
    },
    {
        label:'Spanish'
    },
    {
        label:'Chinese'
    },
    {
        label:'Russian'
    },
    {
        label:'Creole'
    }
    
]
const Language = () => {
    const navigate = useNavigate()
    const dispatch = useDispatch();
    const languageState = useSelector((s) => s.language);
    const [showDropDown, setShowDropDown] = useState(false)
    const [selectedLanguage, setSelectedLanguage] = useState('Select Language')

    useEffect(()=>{
        const language = localStorage.getItem('language')
        if(language){
            navigate('/login')   
        }
    },[])

    const handleSelection = (item, index)=>{
        setSelectedLanguage(item.label)
        languages.map(item=>item.selected=false)
        languages[index].selected = true
        localStorage.removeItem('language')
        localStorage.setItem('language', item.label)

        setShowDropDown(false)
        dispatch(language({ language:item.label }));
        setTimeout(()=>{
            navigate('/login')
        },1000 )
        
    }
    useEffect(()=>{
        console.log(languageState);
    },[languageState])

    return ( 
        <>
        <div className={defaultLayoutStyles.defaultLayout}>
            <div className={defaultLayoutStyles.defaultLayoutWrapper}>
                  <AuthNavbar />
                  <div className={languageStyles.selectTab}>
                        <Button className={languageStyles.selectBtn} onClick={()=>setShowDropDown(!showDropDown)}>{selectedLanguage} <DownOutlined className={languageStyles.floatRight} /> </Button>
                        {showDropDown? 
                        <div className={languageStyles.dropDown}>
                            {
                                languages.map((item, i)=>
                                    <div key={i} onClick={()=>handleSelection(item, i)} className={languageStyles.dropDownItem}> 
                                        <span className={languageStyles.dropDownText}>{item.label}</span> 
                                        {
                                            item.selected?<span className={languageStyles.floatRight} > <CheckOutlined className={languageStyles.mark} /> </span>:''
                                        }
                                    </div>
                                )
                            }
                            
                        </div> :''}
                  </div>
            </div>
        </div>
      </>
     );
}
 
export default Language;
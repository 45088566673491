import "antd/dist/antd.css";
import "antd-country-phone-input/dist/index.css";
import React, {useEffect} from "react";
import ReactDOM from "react-dom";
import "./tailwind.css";
import AllRoutes from "./App";
import reportWebVitals from "./reportWebVitals";
import { ConfigProvider } from "antd-country-phone-input";

//import en from "world_countries_lists/data/en/world.json";




ReactDOM.render(
  <React.StrictMode>
 
      <ConfigProvider locale={[]}>
        <AllRoutes />
      </ConfigProvider>
   
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

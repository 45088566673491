// ----------- import external dependencies ------------
import React, { useEffect, useState } from "react";
import moment from "moment";
import {
  Form,
  Input,
  Button,
  Row,
  Select,
  Checkbox,
  Col,
  DatePicker,
  Tooltip,
  TimePicker
} from "antd";
import { useDispatch, useSelector } from "react-redux";
import { InfoCircleFilled } from "@ant-design/icons";

// ------------ import internal dependencies -----------
import ApplicationLayout from "components/Layouts/AuthLayout/application";
import applicationLayoutStyles from "components/Layouts/AuthLayout/application.module.css";
import { useNavigate, useLocation } from "react-router-dom";
//actions
import {
  getCaregiverApplicationData,
  getCaregiverApplicationDataCleanup,
} from "../../../../store/actions/get-caregiver-application-data";

import {
  updateCaregiverApplicationData,
  updateCaregiverApplicationDataCleanup,
} from "../../../../store/actions/update-caregiver-application";
//utils
import Notification from "../../../../utils/notification";
import {
  aboutUsList,
  workAvailabilityList,
  desiredShiftList,
  workingDays,
  timeOptions,
  navDict,
} from "utils/helpers/data";
import "./caregiver.scoped.css"
import {FormattedMessage, useIntl} from 'react-intl';

const RegisterCaregiverApplication = () => {
  const location = useLocation();
  const appType = location.state?.applicantType ?? "HHA";
  const intl = useIntl()
  const { Option } = Select;
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector((s) => s.getPersonalData.data);
  const navAccessState = useSelector(s=>s.navAccess)
  const getCaregiverApplicationDataState = useSelector((s) => s.getCaregiverApplicationData);
  const updateCaregiverApplicationState = useSelector((s) => s.updateCaregiverApplicationData);
  const dateFormat = "MM/DD/YYYY";
  const timeFormat = 'HH:mm';

  const [previouslyAppliedHere, setPreviouslyAppliedHere] = useState(null);
  const [previouslyEmployedHere, setPreviouslyEmployedHere] = useState(null);
  const [felonyConviction, setFelonyConviction] = useState(null);

  const [varyingOutsideHours, setVaryingOutsideHours] = useState(null);
  const [employedByOtherLicensedAgency, setEmployedByOtherLicensedAgency] = useState(null);
  const [employedByUnlicensedOrg, setEmployedByUnlicensedOrg] = useState(null);

  useEffect(() => {
    if(user?.Can_Go_To_Application_For_Employment__c===false  && navAccessState?.Can_Go_To_Application_For_Employment__c===false){
      Notification(
        'Access denied',
        "warning",
        `You are not yet allowed to access this section. Please make sure to complete all previous sections`
      );
      return navigate('/')
    }
    document.title = `${intl.formatMessage({id:"web.websiteTitle"})}-${intl.formatMessage({id:'caregiverApp.pageHeader'})}`
    window.scrollTo(0,0)
    dispatch(getCaregiverApplicationData());
  }, [intl, user]);

  useEffect(() => {
    console.log("Debuging")
    if (getCaregiverApplicationDataState.isSuccessful) {

      const {data} = getCaregiverApplicationDataState
      console.log(data);

      if(data?.Availability_for_Work__c && data?.Availability_for_Work__c!= '') {
        form.setFieldsValue({
          Availability_for_Work__c: data?.Availability_for_Work__c.split(";"),
        })
      }

      if(data?.Desired_shift__c && data?.Desired_shift__c!= '') {
        form.setFieldsValue({
          Desired_shift__c: data?.Desired_shift__c.split(";"),
        })
      }

      if(data?.Days_working_elsewhere__c && data?.Days_working_elsewhere__c!= '') {
        form.setFieldsValue({
          Days_working_elsewhere__c: data?.Days_working_elsewhere__c.split(";")
        })
      }

      //TODO : this should use data from the application and not from the Applicant
      form.setFieldsValue({
        /** These fields do not need to be displayed - If displayed, they definitely should be Read-Only*/

        // Language__c: getCaregiverApplicationDataState.data.Language__c,
        // Employment_Stage__c: getCaregiverApplicationDataState.data.Employment_Stage__c,
        // Date_of_Application__c:
        //    getCaregiverApplicationDataState.data.Date_of_Application__c,
        // Test_Score__c: getCaregiverApplicationDataState.data.Test_Score__c,

        // ------------- end line -----------

        //Kinda OK
        How_did_you_hear_about_us__c: data?.How_did_you_hear_about_us__c,
        //TODO : Identified a bug when this is populated
        
        // Desired_shift__c, Avalibility__c, Days_working_elsewhere__c causing JSON_PARSER_ERROR
        // Desired_shift__c: getCaregiverApplicationDataState.data.Desired_shift__c ?? [],
        // Avalibility__c: getCaregiverApplicationDataState.data.Avalibility__c ?? [],

        //Kinda OK - Needs to be a checkbox
        Employed_by_other_Licensed_Agency_New__c: data?.Employed_by_other_Licensed_Agency_New__c,
        Employed_by_Unlicensed_organization_New__c: data?.Employed_by_Unlicensed_organization_New__c,
         //Kinda OK - Needs to be a checkbox
        // Employed_by_Unlicensed_organization__c: data.Employed_by_Unlicensed_organization__c,
        // Days_working_elsewhere__c:
        //   getCaregiverApplicationDataState.data.Days_working_elsewhere__c ?? [],

        //Kinda OK - Needs to be a checkbox
        
        Work_Permit_If_Under_18__c: data?.Work_Permit_If_Under_18__c,
        Have_You_Filled_application_here_before__c: data?.Have_You_Filled_application_here_before__c,
        Previously_filed_application_date__c: data?.Previously_filed_application_date__c ? moment(data?.Previously_filed_application_date__c) : "",
        Have_you_ever_been_employed_here_before__c: data?.Have_you_ever_been_employed_here_before__c,
        Previously_employed_date__c: data?.Previously_employed_date__c ? moment(data?.Previously_employed_date__c) : "",
        Immigration_Status__c : data?.Immigration_Status__c,
        Felony_Conviction_within_7_years__c: data?.Felony_Conviction_within_7_years__c,
        Conviction_Explanation__c : data?.Conviction_Explanation__c,

        Date_available_for_work__c: data?.Date_available_for_work__c ? moment(data?.Date_available_for_work__c) : "",
        // Availability_for_Work__c: data.Availability_for_Work__c?.split(";") ?? null,
        // Desired_shift__c: data.Desired_shift__c?.split(";") ?? null,
        
        //Employed_by_other_Licensed_Agency__c: data.Employed_by_other_Licensed_Agency_New__c ?? (data.Employed_by_other_Licensed_Agency__c ? "Yes":""),  
        // Employed_by_Unlicensed_organization__c: data.Employed_by_Unlicensed_organization_New__c ?? (data.Employed_by_Unlicensed_organization__c ? "Yes":""),
        Currently_Not_Employed__c: data?.Currently_Not_emp_New__c ?? (data?.Currently_Not_Employed__c ? "Yes":""),
        
        // Days_working_elsewhere__c: data?.Days_working_elsewhere__c?.split(";") ?? null,
        Varying_Outside_hours_of_emp_New__c: data?.Varying_Outside_hours_of_emp_New__c, //?? (data?.Varying_outside_hours_of_employement__c ? "Yes":""),
        Start_hour_at_other_org__c: data?.Start_hour_at_other_org__c,
        End_hour_at_other_Org__c: data?.End_hour_at_other_Org__c,
        applicationId : data?.Id,
        // ------ TODO : Notice - added the address property
      });

      setPreviouslyAppliedHere(data?.Have_You_Filled_application_here_before__c)
      setPreviouslyEmployedHere(data?.Have_you_ever_been_employed_here_before__c)
      setFelonyConviction(data?.Felony_Conviction_within_7_years__c)
      setEmployedByUnlicensedOrg(data?.Employed_by_Unlicensed_organization_New__c);
      setEmployedByOtherLicensedAgency(data?.Employed_by_other_Licensed_Agency_New__c);
      dispatch(getCaregiverApplicationDataCleanup());
    } else if (getCaregiverApplicationDataState.error) {
      if (Array.isArray(getCaregiverApplicationDataState.error)) {
        Notification(intl.formatMessage({id:'notification.failed'}), "error", getCaregiverApplicationDataState.error[0].msg);
      } else Notification(intl.formatMessage({id:'notification.failed'}), "error", intl.formatMessage({id:'notification.caregiverApp.get.failed'}));
      dispatch(getCaregiverApplicationDataCleanup());
    }
    console.log("FORM")
    console.log(form.fields)
  }, [getCaregiverApplicationDataState]);

  useEffect(() => {
    if (updateCaregiverApplicationState.isSuccessful) {
      Notification(intl.formatMessage({id:'notification.success'}), "success", intl.formatMessage({id:'notification.caregiverApp.update.success'}));
      navigate(`/register/documents`);
      dispatch(updateCaregiverApplicationDataCleanup());
    } else if (updateCaregiverApplicationState.error) {
      if (Array.isArray(updateCaregiverApplicationState.error)) {
        Notification(intl.formatMessage({id:'notification.failed'}), "error", updateCaregiverApplicationState.error[0].msg);
      } else Notification(intl.formatMessage({id:'notification.failed'}), "error",  intl.formatMessage({id:'notification.caregiverApp.update.failed'}));
      dispatch(updateCaregiverApplicationDataCleanup());
    }
  }, [updateCaregiverApplicationState]);

  const onFinish = (values) => {
    
    values.Availability_for_Work__c = values.Availability_for_Work__c ? values.Availability_for_Work__c.join(";") : ""
    values.Desired_shift__c = values.Desired_shift__c ? values.Desired_shift__c.join(";") : "";
    values.Days_working_elsewhere__c = values.Days_working_elsewhere__c ? values.Days_working_elsewhere__c.join(";") : "";
    // values.Varying_outside_hours_of_employement__c = values.Varying_outside_hours_of_employement__c == "Yes";
    // values.Employed_by_Unlicensed_organization__c = values.Employed_by_Unlicensed_organization__c == "Yes";
    // values.Employed_by_other_Licensed_Agency__c = values.Employed_by_other_Licensed_Agency__c == "Yes";
    // values.Currently_Not_Employed__c = values.Currently_Not_Employed__c == "Yes";
    console.log(values);
    dispatch(updateCaregiverApplicationData(values));
  };

  let stepNum = navDict[appType]?.['steps']?.indexOf(location.pathname) + 1;
  let totalStepsCount = navDict[appType]?.['totalStepsCount'];

  return (
    <div>
      <ApplicationLayout>
        <div className={applicationLayoutStyles.mainContent}>
          {appType ? <span>
              <FormattedMessage id="nav.step" values={{stepNum,totalStepsCount}} /> : 
            </span>
            : null } <span>{<FormattedMessage id="profile.pageHeader" />}
          </span>
          <h2>{<FormattedMessage id="caregiverApp.pagePrompt" />}</h2>

          <Form
            name="basic"
            layout="vertical"
            initialValues={{
              remember: true,
            }}
            autoComplete="off"
            form={form}
            onFinish={onFinish}
          >
            <Row gutter={32}>
              <Col span={12} xs={24} lg={12}>
                <Form.Item 
                  label={<FormattedMessage id="caregiverApp.referrerType"/>}
                  name="How_did_you_hear_about_us__c"
                  rules={[
                          {
                            required: false,
                            message: intl.formatMessage({
                              id:'validation.genericFieldIsRequired'
                            }),
                          },
                        ]}
                  >
                  <Select>
                  {aboutUsList.map((e, i) => (
                    <Option key={i} value={e.sfVal}>
                      {<FormattedMessage id={e.displayStringId} />}
                    </Option>
                  ))}  
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            
            <Row gutter={32}>
              <Col span={12} xs={24} lg={12}>
                <Form.Item 
                  label={<FormattedMessage id="caregiverApp.workPermitUnder18"/>}
                  name="Work_Permit_If_Under_18__c"
                  rules={[
                          {
                            required: true,
                            message: intl.formatMessage({
                              id:'validation.genericFieldIsRequired'
                            }),
                          },
                        ]}
                  >
                  <Select>
                    <Option value="Yes">{<FormattedMessage id="choices.yes" />}</Option>
                    <Option value="No">{<FormattedMessage id="choices.no" />}</Option>
                    <Option value="Not Applicable">{<FormattedMessage id="choices.notApplicable" />}</Option>  
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            {/* <Row gutter={32}>
              <Col span={12} xs={24} lg={12}>
                <Form.Item label={<>
                  <div style={{display: "flex", gap: "5px", alignItems: "center"}}>
                    <span>{<FormattedMessage id="caregiverApp.alienWorkPrevented"/>}</span>
                    <Tooltip title={intl.formatMessage({id:'caregiverApp.pocWarning'})} color="#FBA834BF">
                    <InfoCircleFilled style={{color: "#FBA834"}}/>
                    </Tooltip>

                  </div>
                </>} 
                name="Immigration_Status__c"
                rules={[
                          {
                            required: true,
                            message: intl.formatMessage({
                              id:'validation.genericFieldIsRequired'
                            }),
                          },
                        ]}

                >
                  <Select >
                    <Option value="Yes">{<FormattedMessage id="choices.yes" />}</Option>
                    <Option value="No">{<FormattedMessage id="choices.no" />}</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row> */}
            <Row gutter={32}>
              <Col span={12} xs={24} lg={12}>
                <Form.Item 
                  label={<FormattedMessage id="caregiverApp.didFillPreviousApplicationHere"/>} 
                  name="Have_You_Filled_application_here_before__c">
                  <Select onSelect={setPreviouslyAppliedHere}>
                    <Option value="Yes">{<FormattedMessage id="choices.yes" />}</Option>
                    <Option value="No">{<FormattedMessage id="choices.no" />}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} xs={24} lg={12}>
                <Form.Item
                  label={<FormattedMessage id="caregiverApp.previousApplicationDate"/>} 
                  name="Previously_filed_application_date__c"
                  hidden={previouslyAppliedHere=="No"}
                >
                  <DatePicker format={dateFormat} disabled={previouslyAppliedHere=="No"}/>
                </Form.Item>
              </Col>
              </Row>
            <Row gutter={32}>
              <Col span={12} xs={24} lg={12}>
                <Form.Item 
                  label={<FormattedMessage id="caregiverApp.wasPreviouslyEmployedHere"/>}  
                  name="Have_you_ever_been_employed_here_before__c">
                  <Select onSelect={setPreviouslyEmployedHere}>
                    <Option value="Yes">{<FormattedMessage id="choices.yes" />}</Option>
                    <Option value="No">{<FormattedMessage id="choices.no" />}</Option>
                  </Select>
                </Form.Item>
              </Col>
            
              <Col span={12} xs={24} lg={12}>
                <Form.Item
                  label={<FormattedMessage id="caregiverApp.previousEmploymentDate"/>} 
                  name="Previously_employed_date__c"
                  hidden={previouslyEmployedHere=="No"}
                >
                  <DatePicker format={dateFormat} disabled={previouslyEmployedHere=="No"}/>
                </Form.Item>
              </Col>
              </Row>
            {/* <Row gutter={32}>
              <Col span={12} xs={24} lg={12}>
               <Form.Item 
                label={<FormattedMessage id="caregiverApp.hasBeenConvictedFelony"/>} 
                name="Felony_Conviction_within_7_years__c"
                rules={[
                          {
                            required: true,
                            message: intl.formatMessage({
                              id:'validation.genericFieldIsRequired'
                            }),
                          },
                        ]}
                >
                  <Select onSelect={setFelonyConviction}>
                    <Option value="Yes">{<FormattedMessage id="choices.yes" />}</Option>
                    <Option value="No">{<FormattedMessage id="choices.no" />}</Option>
                  </Select>
               </Form.Item>
              </Col>

              <Col span={12} xs={24} lg={12}>
                <Form.Item
                  label={<FormattedMessage id="caregiverApp.felonyConvictionExplaination"/>} 
                  name="Conviction_Explanation__c"
                  rules={[
                          {
                            required: (felonyConviction=="Yes"),
                            message: intl.formatMessage({
                              id:'validation.genericFieldIsRequired'
                            }),
                          },
                        ]}
                >
                  <Input disabled={(felonyConviction=="No")}/>
                </Form.Item>
              </Col>
              
            </Row> */}

            <Row gutter={32}>
              <Col span={12} xs={24} lg={12}>
                <Form.Item 
                  label={<FormattedMessage id="caregiverApp.dateAvailableToStart"/>}  
                  name="Date_available_for_work__c"
                  rules={[
                          {
                            required: true,
                            message: intl.formatMessage({
                              id:'validation.genericFieldIsRequired'
                            }),
                          },
                        ]}
                  >
                  <DatePicker format={dateFormat} />
                </Form.Item>
              </Col>
              
            </Row>

            <Row gutter={32}>
              <Col span={12} xs={24} lg={12}>
                <Form.Item
                  label={<FormattedMessage id="caregiverApp.workAvailableFor"/>} 
                  name="Availability_for_Work__c"
                  rules={[
                          {
                            required: true,
                            message: intl.formatMessage({
                              id:'validation.genericFieldIsRequired'
                            }),
                          },
                        ]}
                >
                  <Select 
                    mode="multiple"
                    allowClear
                    placeholder={intl.formatMessage({id:'caregiverApp.typesSelectionPrompt'})}
                    value={undefined}
                  >
                  {workAvailabilityList.map((e, i) => (
                    <Option key={i} value={e.sfVal}>
                      {<FormattedMessage id={e.displayStringId} />}
                    </Option>
                  ))}
                  </Select>
                </Form.Item>
              </Col>
              
            {/*</Row>

            <Row>*/}
              <Col span={12} xs={24} lg={12}>
                <Form.Item
                  label={<FormattedMessage id="caregiverApp.desiredShift"/>} 
                  name="Desired_shift__c"
                  rules={[
                          {
                            required: true,
                            message: intl.formatMessage({
                              id:'validation.genericFieldIsRequired'
                            }),
                          },
                        ]}
                >
                  <Select 
                    mode="multiple"
                    allowClear
                    placeholder={intl.formatMessage({id:'caregiverApp.shiftsSelectionPrompt'})}
                    value={undefined}
                  >
                  {desiredShiftList.map((e, i) => (
                    <Option key={i} value={e.sfVal}>
                      {<FormattedMessage id={e.displayStringId} />}
                    </Option>
                  ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            {/* ------------ Job information section ----------- */}
            <div className="section-desc">
               <span className="section-desc__heading">{<FormattedMessage id="caregiverApp.jobInformationPrompt"/>} </span>
               <span className="section-desc__info">{<FormattedMessage id="caregiverApp.pleaseCompleteAllFields"/>} </span>
            </div>

            <Row gutter={32}>
              <Col span={12} xs={24} lg={18}>
                <Form.Item label={<>
                  <div className="label-tooltip">
                    <span>{<FormattedMessage id="caregiverApp.currentlyEmployedOtherAgency"/>} </span>
                    <Tooltip title="" color="#FBA834BF">
                    <InfoCircleFilled style={{color: "#FBA834"}}/>
                    </Tooltip>

                  </div>
                </>} name="Employed_by_other_Licensed_Agency_New__c"
                    rules={[
                          {
                            required: true,
                            message: intl.formatMessage({
                              id:'validation.genericFieldIsRequired'
                            }),
                          },
                        ]}
                >
                  {/*<Select placeholder={<FormattedMessage id="choices.yesNoPrompt" />}>*/}
                  <Select onSelect={setEmployedByOtherLicensedAgency}>
                    <Option value="Yes">{<FormattedMessage id="choices.yes" />}</Option>
                    <Option value="No">{<FormattedMessage id="choices.no" />}</Option>
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} xs={24} lg={18}>
                <Form.Item label={<>
                  <div className="label-tooltip">
                    {<FormattedMessage id="caregiverApp.currentlyEmployedUnlicensedOrg"/>} &nbsp;
                    <Tooltip title={intl.formatMessage({id:'caregiverApp.currentlyEmployedByOtherOrg'})} color="#FBA834BF">
                    <InfoCircleFilled style={{color: "#FBA834"}}/>
                    </Tooltip>

                  </div>
                </>} name="Employed_by_Unlicensed_organization_New__c"
                  rules={[
                          {
                            required: true,
                            message: intl.formatMessage({
                              id:'validation.genericFieldIsRequired'
                            }),
                          },
                        ]}
                >
                 <Select onSelect={setEmployedByUnlicensedOrg}>
                    <Option value="Yes">{<FormattedMessage id="choices.yes" />}</Option>
                    <Option value="No">{<FormattedMessage id="choices.no" />}</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <div hidden={!((employedByOtherLicensedAgency=="Yes") || (employedByUnlicensedOrg=="Yes"))}>
            <Row gutter={32}>
              <Col span={12} xs={24} lg={12}>
                <Form.Item
                  label={<FormattedMessage id="caregiverApp.daysWorkingElsewhere"/>}
                  name="Days_working_elsewhere__c"
                  rules={[
                          {
                            required: ((employedByOtherLicensedAgency=="Yes") || (employedByUnlicensedOrg=="Yes")),
                            message: intl.formatMessage({
                              id:'validation.genericFieldIsRequired'
                            }),
                          },
                        ]}
                >
                  <Select
                    mode="multiple"
                    allowClear
                    placeholder={intl.formatMessage({id:'caregiverApp.daysSelectionPrompt'})}
                    value={undefined}
                  >
                    {workingDays.map((e, i) => (
                      <Option key={i} value={e.sfVal}>
                        {<FormattedMessage id={e.displayStringId} />}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} xs={24} lg={12}>
                <Form.Item
                  label={<>
                  <div className="label-tooltip">
                    {<FormattedMessage id="caregiverApp.varyingOutsideHours"/>} &nbsp;
                    <Tooltip title={intl.formatMessage({id:'caregiverApp.varyingHoursOfEmployment'})} color="#FBA834BF">
                    <InfoCircleFilled style={{color: "#FBA834"}}/>
                    </Tooltip>
                  </div>
                </>}
                  name="Varying_Outside_hours_of_emp_New__c"
                  rules={[
                          {
                            required: false,
                            message: intl.formatMessage({
                              id:'validation.genericFieldIsRequired'
                            }),
                          },
                        ]}
                >
                  <Select onSelect={setVaryingOutsideHours}>
                    <Option value="Yes">{<FormattedMessage id="choices.yes" />}</Option>
                    <Option value="No">{<FormattedMessage id="choices.no" />}</Option>
                  </Select>
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={32}>
              <Col span={12} xs={24} lg={12}>
                <Form.Item
                  label={<FormattedMessage id="caregiverApp.startHourAtOtherOrg"/>}
                  name="Start_hour_at_other_org__c"
                  hidden={!(varyingOutsideHours=="No")}
                  rules={[
                          {
                            required: (varyingOutsideHours=="No"),
                            message: intl.formatMessage({
                              id:'validation.genericFieldIsRequired'
                            }),
                          },
                        ]}
                >
                  <Select
                    allowClear
                    placeholder={intl.formatMessage({id:'caregiverApp.startHourAtOtherOrgPrompt'})}
                    value={undefined}
                  >
                    {timeOptions.map((e, i) => (
                      <Option value={e} key={i}>
                        {e}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              <Col span={12} xs={24} lg={12}>
                <Form.Item
                  label={<FormattedMessage id="caregiverApp.endHourAtOtherOrg"/>} 
                  name="End_hour_at_other_Org__c"
                  hidden={!(varyingOutsideHours=="No")}
                  rules={[
                          {
                            required: (varyingOutsideHours=="No"),
                            message: intl.formatMessage({
                              id:'validation.genericFieldIsRequired'
                            }),
                          },
                        ]}
                >
                  <Select
                    allowClear
                    placeholder={intl.formatMessage({id:'caregiverApp.endHourAtOtherOrgPrompt'})}
                    value={undefined}
                  >
                    {timeOptions.map((e, i) => (
                      <Option value={e} key={i}>
                        {e}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            </div>
            <div className={applicationLayoutStyles.formFooter}>
              <div className={applicationLayoutStyles.formPrevious}>
                <Button type="primary" onClick={()=>navigate("/dashboard")}>
                  {<FormattedMessage id="nav.previous" />}
                </Button>
              </div>
              <div className={applicationLayoutStyles.formSave}>
                <Button type="primary" htmlType="submit">
                  {<FormattedMessage id="nav.continueLater" />}
                </Button>
                <Button
                  disabled={updateCaregiverApplicationState.isLoading}
                  loading={updateCaregiverApplicationState.isLoading}
                  // onClick={() => navigate( navDict[appType]['steps'][navDict[appType]['steps'].indexOf(location.pathname) + 1],{state:{ applicantType : appType }})}
                  type="primary"
                  htmlType="submit"
                >
                  {<FormattedMessage id="nav.next" />}
                </Button>
              </div>
            </div>
          </Form>
        </div>
      </ApplicationLayout>
    </div>
  );
};

export default RegisterCaregiverApplication;

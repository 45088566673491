import * as types from '../../action-types';
import {getSingleRequiredDocument as initialState } from "../../initialState";

const getSingleRequiredDocument = (state=initialState, action) => {
  switch (action.type) {
    case types.GET_SINGLE_REQUIRED_DOCUMENTS_START:
      return {
        ...state,
        isLoading: true
      };
    case types.GET_SINGLE_REQUIRED_DOCUMENTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isSuccessful: true,
        data: action.payload
      };
    case types.GET_SINGLE_REQUIRED_DOCUMENTS_FAIL:
      return {
        ...state,
        isLoading: false,
        error: action.payload
      };
    case types.GET_SINGLE_REQUIRED_DOCUMENTS_CLEANUP:
      return {
        ...state,
        isLoading: false,
        isSuccessful: false,
        error: null
      };
    default:
      return state;
  }
}

export default getSingleRequiredDocument;

import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const updateCaregiverApplicationDataStart = () => ({
  type: types.APPLY_FOR_CAREGIVER_START
});

export const updateCaregiverApplicationDataSuccess = payload => ({
  type: types.APPLY_FOR_CAREGIVER_SUCCESS,
  payload
});

export const updateCaregiverApplicationDataFail = payload => ({
  type: types.APPLY_FOR_CAREGIVER_FAIL,
  payload
});

export const updateCaregiverApplicationDataCleanup = () => ({
  type: types.APPLY_FOR_CAREGIVER_CLEANUP
});

export const updateCaregiverApplicationData = payload => async dispatch => {

  try {
    dispatch(updateCaregiverApplicationDataStart());
    const requestObj = {
      path: "caregiver",
      method: "PATCH",
      data: payload,
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(updateCaregiverApplicationDataSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(updateCaregiverApplicationDataFail(error));
  }
}

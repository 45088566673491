import * as types from '../../action-types';
import {navAccess as initialState } from "../../initialState";

const modifyNavAccess = (state=initialState, action) => {
  switch (action.type) {
    case types.MODIFY_NAV_ACCESS:
        console.log('action payload for nav ccess', action.payload)
      return {
        ...state,
        ...action.payload
      };
    
    default:
      return state;
  }
}

export default modifyNavAccess;

import React, {useState, useEffect} from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Button, Checkbox } from "antd";
import { NavLink, useNavigate } from 'react-router-dom';
import DefaultAuthLayout from 'components/Layouts/AuthLayout/default.js';
import defaultLayoutStyles from 'components/Layouts/AuthLayout/default.module.css';
import useQuery from '../../utils/useQuery/index';
import { getPersonalData } from "../../store/actions/get-personal-data";
import { verify_otp, verify_otp_cleanUp } from "../../store/actions/verify-otp";
import { resend_otp, resend_otp_cleanUp } from 'store/actions/resend_otp';
import Notification from "../../utils/notification";
import { loginCleanup } from 'store/actions/login';
import { FormattedMessage, useIntl } from 'react-intl';
import HelpModal from 'components/Modal/helpModal';

const OTPVerification = () => {

    const verify_otp_state = useSelector((s)=>s.verify_otp)
    const resend_otp_state = useSelector((s)=>s.resend_otp)
    const intl = useIntl()
    const [email, phone] = useQuery()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const [form] = Form.useForm();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const[mail, setMail]=useState("")
   
    const [OTPvalue, setOTPvalue] = useState("")

    const verifyOTPFunc = ()=>{
        const userDetails = JSON.parse(localStorage.getItem('userDetails'))
        console.log(userDetails)
       
    
        dispatch(verify_otp({
            OTP:OTPvalue, 
            PersonEmail:mail, 
            phone:phone?phone[1]:'',
            SSN:(mail==userDetails?.email) ? userDetails?.SSN:'', 
            HCR: (mail==userDetails?.email)? userDetails?.HCR : ''
        }))
    }

    const resendOTPFunc = ()=>{
        dispatch(resend_otp())
    }

    const verticalTextStyle = {
        textAlign: 'center',
        marginTop:'20px'
    }
    const buttonStyle = {
        width: '60%',
    }

    const helpLinkStyle = {
        marginTop: '14px'
    }
    const resendLinkStyle={
        cursor:'pointer'
    }

    useEffect(()=>{
        setMail(email[1])
        
    },[])


   
    useEffect(()=>{
        if(verify_otp_state.isSuccessful){
            Notification(intl.formatMessage({id:'alert.loginSuccessful'}), "success", intl.formatMessage({id:'alert.loginWasSuccessful'}));
            localStorage.removeItem('userDetails')
            navigate('/register/personal')
            dispatch(getPersonalData())
            dispatch(verify_otp_cleanUp())
        }else if (verify_otp_state.error){
            // console.log("otp verification failed")
            // console.log(verify_otp_state);
            if (verify_otp_state.error.msg) {
                 Notification(intl.formatMessage({id:'alert.loginFailed'}), "error", verify_otp_state.error.msg);
            } else Notification(intl.formatMessage({id:'alert.loginFailed'}), "error", "Invalid Login Credentials");
              
           dispatch(verify_otp_cleanUp());
        }


       
    }, [verify_otp_state])

    useEffect(()=>{
        if(resend_otp_state.isSuccessful){
            Notification(intl.formatMessage({id:'alert.otpSent'}), "success", intl.formatMessage({id:'alert.otpResentSuccessfully'}));
        }else if(resend_otp_state.error){
            Notification(intl.formatMessage({id:'alert.failedToResend'}), "error", intl.formatMessage({id:'alert.failedToResend'}));
        }
    },[ resend_otp_state])

      const showModal = () => {
        setIsModalVisible(true);
      };
    
      const handleOk = () => {
        setIsModalVisible(false);
      };
    
      const handleCancel = () => {
        setIsModalVisible(false);
      };


    return ( 
        <div>
            <HelpModal 
                handleCancel={handleCancel}
                isModalVisible={isModalVisible}
                handleOk={handleOk}
            />
            <DefaultAuthLayout>
                <h5 className={defaultLayoutStyles.cardHeader}> <FormattedMessage id="register.verifyEmail" /> </h5>
                <h6 className={defaultLayoutStyles.cardDescription} style={verticalTextStyle}>
                    <p><FormattedMessage id="auth.otpPromptPrefix" /> {mail}.</p>
                </h6>
                <Form
                    layout="vertical"
                    name="basic"
                    initialValues={{
                    remember: true,
                    }}
                    autoComplete="off"
                    onFinish={verifyOTPFunc}
                    form={form}
                    className="ant-advanced-search-form"
                >
                    <Form.Item
                        
                        name="OTP"
                        rules={[
                            {
                            required: true,
                            message:<FormattedMessage id="auth.validation.otpIsRequired" />,
                            }
                        ]}
                    >
                        <Input value={OTPvalue} onChange={(e)=>setOTPvalue(e.target.value)}  />  
                    </Form.Item>
                    <h6 className={defaultLayoutStyles.cardDescription} >
                            <p><FormattedMessage id="auth.didntReceiveOTP" /><b style={resendLinkStyle} onClick={()=>resendOTPFunc()} > {resend_otp_state.isLoading?<FormattedMessage id="auth.resending" />:<FormattedMessage id="auth.clickTo Resend" />} </b></p>
                        </h6>
                        
                        <Form.Item style={{textAlign:'center'}}>
                            <Button  
                                style={buttonStyle} 
                                className='auth-btn--custom-auth' 
                                type="primary" 
                                htmlType="submit"
                                loading={verify_otp_state.isLoading}
                            >
                                <FormattedMessage id="auth.verifyOTP" />
                            </Button>
                            <div style={helpLinkStyle}>
                                <h6 className={defaultLayoutStyles.cardDescription}  style={{cursor:'pointer', textAlign:'center'}} onClick={()=>showModal()}>
                                    <span ><FormattedMessage id="auth.needHelp" /> </span>
                                    {/*<NavLink to={'/register'}>*/}
                                        <b><FormattedMessage id="auth.contactUs" /> </b>
                                    {/*</NavLink>*/}
                                </h6>
                            </div>
                        </Form.Item>
                        
                </Form>
            </DefaultAuthLayout>
        </div>
     );
}
 
export default OTPVerification;
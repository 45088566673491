import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const getAllEmploymentsStart = () => ({
  type: types.GET_ALL_EMPLOYMENTS_START
});

export const getAllEmploymentsSuccess = payload => ({
  type: types.GET_ALL_EMPLOYMENTS_SUCCESS,
  payload
});

export const getAllEmploymentsFail = payload => ({
  type: types.GET_ALL_EMPLOYMENTS_FAIL,
  payload
});

export const getAllEmploymentsCleanup = () =>({
  type: types.GET_ALL_EMPLOYMENTS_CLEANUP
});

export const getAllEmployments = () => async dispatch => {
  try {

    dispatch(getAllEmploymentsStart());
    const requestObj = {
      path: "experiences",
      method: "GET",
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(getAllEmploymentsSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(getAllEmploymentsFail(error));
  }
}

import React, { useEffect, useState } from "react";
import DefaultAuthLayout from "components/Layouts/AuthLayout/default.js";
import defaultLayoutStyles from 'components/Layouts/AuthLayout/default.module.css';
import { Form, Input, Button, Alert } from "antd";
import { NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";

import { FormattedMessage, useIntl } from "react-intl";

//actions
import {
  resetPassword,
  resetPasswordCleanup,
} from "../../store/actions/reset/index";

//utils
import Notification from "../../utils/notification";

const ForgotPassword = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [error, setError] = useState(false);
  const resetPasswordState = useSelector((r) => r.resetPassword);
  const intl = useIntl()
  const verticalFormStyle = {
    form: {
      width: "100%",
      marginTop: "0px",
    },
    alert: {
      width: "100%",
      paddingBottom: "10px",
    },
  };
  const onFinish = (values) => {
    dispatch(resetPassword(values));
  };

  useEffect(() => {

    if (resetPasswordState.isSuccessful) {
      navigate("/login");
      Notification(
        intl.formatMessage({id:'alert.emailSent'}),
        "success",
        intl.formatMessage({id:'alert.kindlyCheckYourEmail'})
      );
      dispatch(resetPasswordCleanup());
    } else if (resetPasswordState.error) {
      setError(true);
      dispatch(resetPasswordCleanup());
    }
  }, [resetPasswordState]);
  return (
    <div>
      <DefaultAuthLayout>
        <h5 className={defaultLayoutStyles.cardHeader} ><FormattedMessage id="login.forgotPassword" /> </h5>
        <h6 className={defaultLayoutStyles.cardDescription} >
        <FormattedMessage id="auth.passwordResetEmailPrompt" /> 
        </h6>
        <div className="pb-5" style={verticalFormStyle.alert}>
          {error === true ? (
            <Alert
              style={verticalFormStyle.form}
              type="warning"
              message={
                Array.isArray(resetPasswordState.error)
                  ? resetPasswordState.error[0].msg
                  : intl.formatMessage({id:'alert.errorTryingToResetPassword'})
              }
            />
          ) : null}
        </div>
        <Form
          form={form}
          layout="vertical"
          style={verticalFormStyle.form}
          name="basic"
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
          onFinish={onFinish}
        >
          <Form.Item
            name="Email"
            label= {<FormattedMessage id="register.email"/>}
            rules={[
              {
                required: true,
                message: <FormattedMessage id="register.validation.emailIsRequired" />,
              },
              {
                type: "email",
                message:<FormattedMessage id="register.validation.emailIsNotValid" />,
              },
            ]}
          >
            <Input />
          </Form.Item>

          <Form.Item>
            <Button
              loading={resetPasswordState.isLoading}
              className="auth-btn--custom-auth"
              type="primary"
              htmlType="submit"
            >
              <FormattedMessage id="auth.resetPassword" /> 
            </Button>
          </Form.Item>

          <div>
            <span>
              <FormattedMessage id="auth.dontHaveAnAccount" /> {" "}
              <NavLink to={"/register"}><FormattedMessage id="auth.register" /> </NavLink>
            </span>
          </div>
        </Form>
      </DefaultAuthLayout>
    </div>
  );
};

export default ForgotPassword;

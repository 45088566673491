import * as types from '../../action-types';
import AxiosCall from '../../../utils/axios';
import ErrorHandler from '../../../utils/error-handler';

export const uploadFileStart = () => ({
  type: types.UPLOAD_FILE_START
});

export const uploadFileSuccess = payload => ({
  type: types.UPLOAD_FILE_SUCCESS,
  payload
});

export const uploadFileFail = payload => ({
  type: types.UPLOAD_FILE_FAIL,
  payload
});

export const uploadFileCleanup = () =>({
  type: types.UPLOAD_FILE_CLEANUP
});

export const uploadFile = (payload, docId) => async dispatch => {
  try {

    dispatch(uploadFileStart());
    const requestObj = {
      path: `upload/${docId}`,
      method: "POST",
      data: payload,
      contentType: "multipart/form-data"
    }
    const  res  = await AxiosCall(requestObj);
    dispatch(uploadFileSuccess(res.data));
  } catch (err) {
    const error = ErrorHandler(err);
    dispatch(uploadFileFail(error));
  }
}

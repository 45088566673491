// --------------- import external dependencies -------------
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { CaretDownOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Checkbox } from "antd";
import PropTypes from "prop-types";

import { language } from "../../store/actions/language";

import { FormattedMessage, useIntl } from "react-intl";

// ----------- import internal dependencies ----------
import "./rightsidebar.css";
import { ReactComponent as Notification } from "../../assets/svgs/bell.svg";

function RightSideBar({ data, close }) {
  // ------------- application state managers ------------
  const [mobile, setMobile] = useState(false);

  const intl = useIntl();

  const [rightBarItems, setRightBarItems] = useState([
    {
      label: <FormattedMessage id="dashboard.employmentStage.collectingDocuments" />,//intl.formatMessage({id:"dashboard.employmentStage.collectingDocuments"}),
      checked:false,
      display:true,
      name:"Collecting Documents"
    },
    {
      label:<FormattedMessage id="dashboard.employmentStage.allDocumentsCollected"/>,
      checked:false,
      display:true,
      name:"All documents collected"
    },
    {
      label:<FormattedMessage id="dashboard.employmentStage.readyForReview"/>,
      checked:false,
      display:true,
      name:"Ready For Review"
    },
    {
      label: <FormattedMessage id="dashboard.employmentStage.readyForOrientation"/>,
      checked:false,
      display:data&&data.Applicant_Type__c=="HHA"?true:false,
      name:"Ready for Orientation"
    },
    {
      label: <FormattedMessage id="dashboard.employmentStage.orientationScheduled"/>,
      checked:false,
      display:data&&data.Applicant_Type__c=="HHA"?true:false,
      name:"Orientation Scheduled"
    },
    {
      label: <FormattedMessage id="dashboard.employmentStage.orientationComplete"/>,
      checked:false,
      display:data&&data.Applicant_Type__c=="HHA"?true:false,
      name:"Orientation Complete"
    },
    {
      label: <FormattedMessage id="dashboard.employmentStage.readyToWork"/>,
      checked:false,
      display:data&&data.Applicant_Type__c=="HHA"?true:false,
      name:"Ready to Work"
    },
    {
      label:'PA active',
      checked:false,
      display:data&&data.Applicant_Type__c!=="HHA"?true:false,
      name:"PA active"
    },
  ])

  //const currentItem = "Ready for review"
   const currentItem = data?.Application_Stage__c ?? ""

  useEffect(() => {
    console.log("right sidebar", data);
    const availableSize = window.innerWidth;
    if (availableSize <= 428) {
      setMobile(true);
    }


    if(currentItem == "Application Sent") return


    const current = rightBarItems.find(item=>item.name.toLowerCase()==currentItem.toLowerCase())
    console.log(current)
    const index = rightBarItems.indexOf(current)
    //console.log(index);
    const clone = [...rightBarItems]
    for(let i=0; i<=index; i++){
      //console.log("here")
      console.log(clone[i])
      clone[i].checked = true
      setRightBarItems(clone)
    }

  }, [data.Applicant_Type__c, data]); //[data.Applicant_Type__c, data, rightBarItems]);






  return (
    <>
      <aside className={mobile ? "right-sidebar-mobile" : "right-sidebar"}>
        {/* <Dropdown overlay={menu} >
            <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
              {currentLanguage || 'Language'} <CaretDownOutlined />
            </a>
        </Dropdown> */}
        <span className="close-nav close-nav-right" onClick={() => close(false)}>
          &times;
        </span>
        <div className="icons-bar">
          <div className="notify-icon">

            <Notification />
            <span className="indicator"></span>
          </div>
          {/* <Message /> */}
          {/* <Setting /> */}
        </div>

        {/* ----------- user profile section ------------- */}
        <div className="user-profile">
          <div className="img-temp"></div>
          <span className="user-profile__name">{data.Name}</span>
          <span className="user-profile__ID">ID: {data.HCR_Number__c ?? data.SSN__c}</span>
        </div>

        {/* ---------- onboarding process section ----------- */}
        <div className="onboarding-process">
          <span className="onboarding-process__heading">
            <FormattedMessage id="dashboard.onboardingProgress" />
          </span>
          <div className="onboarding-progress">
            <span className="onboarding-progress__percent">
              {data.Onboarding_Progress__c}%
            </span>
            <div className="progress-wrap">
              <span className="onboarding-bar"></span>
              <span
                className="onboarding-bar-active"
                style={{ width: `${data.Onboarding_Progress__c}%` }}
              ></span>
            </div>
          </div>
          <ul className="onboarding-list">
            {rightBarItems.map((item, i)=>
                <li key={i} style={{display:item.display ? '':'none', fontWeight:item.checked?'600':''}}> <Checkbox checked={item.checked} className="onboarding-list-checkbox" /> {item.label} </li>
            )}
            {/* <li> <Checkbox checked={false} className="onboarding-list-checkbox" /> Collecting Documents</li>
            <li> <Checkbox checked={false} className="onboarding-list-checkbox" />  All Documents Collected</li>
            <li> <Checkbox checked={false} className="onboarding-list-checkbox" /> Ready for review</li>
            {/*<li>Ready for HHAeX</li>*/}
            {/* {data&&data.Applicant_Type__c=="HHA" ?
              <div>
                <li> <Checkbox checked={false} className="onboarding-list-checkbox" /> Ready for Orientation</li>
                <li> <Checkbox checked={false} className="onboarding-list-checkbox" /> Orientation Scheduled</li>
                <li> <Checkbox checked={false} className="onboarding-list-checkbox" /> Orientation Complete</li>
                <li> <Checkbox checked={false} className="onboarding-list-checkbox" /> Ready to Work</li>
              </div>
              : <li> <Checkbox checked={false} className="onboarding-list-checkbox" /> PA Active</li>}  */}
            {/**/}

          </ul>
        </div>
      </aside>
    </>
  );
}

export default RightSideBar;

RightSideBar.propTypes = {
  data: PropTypes.object,
};

import applicationLayoutStyles from './application.module.css';
import ApplicationNavbarMobile from "components/Navbars/ApplicationNavbarMobile";
import React from 'react'
import { LeftOutlined } from '@ant-design/icons';
import {useNavigate} from 'react-router-dom'

function ApplicationLayout({children}) {
  const navigate = useNavigate()

  return (
    <>
      <div className={applicationLayoutStyles.appLayout}>
          <ApplicationNavbarMobile />
          <div className={applicationLayoutStyles.appLayoutWrapper}>
              <div className={applicationLayoutStyles.appContent}>
                <div style={{cursor:"pointer"}} onClick={()=>navigate("/dashboard")} className={applicationLayoutStyles.appSidebarBackLink}>
                  <span><LeftOutlined /></span>
                  <b>Go to Dashboard</b>
                </div>
                {children}
              </div>
          </div>

      </div>
    </>
  )
}

export default ApplicationLayout

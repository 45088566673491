import * as types from '../../action-types';
import {addLanguage as initialState } from "../../initialState";

const addLanguage = (state=initialState, action) => {
    switch (action.type) {
      case types.ADD_LANGUAGE_START:
        return {
          ...state,
          isLoading: true
        };
      case types.ADD_LANGUAGE_SUCCESS:
        return {
          ...state,
          isLoading: false,
          isSuccessful: true,
          data: action.payload
        };
      case types.ADD_LANGUAGE_FAIL:
        return {
          ...state,
          isLoading: false,
          error: action.payload
        };
      case types.ADD_LANGUAGE_CLEANUP:
        return {
          ...state,
          isLoading: false,
          isSuccessful: false,
          error: null
        };
      default:
        return state;
    }
  }
  
  export default addLanguage;
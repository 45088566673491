import React, { useState, useEffect } from "react";
import { Form, Input, Button, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {FormattedMessage, useIntl} from 'react-intl';


//components
import AuthLayout from "components/Layouts/AuthLayout";

//actions
import { login, loginCleanup } from "../../store/actions/login";
import {
  getPersonalData,
  // getPersonalDataCleanup,
} from "../../store/actions/get-personal-data";

//utils
import Notification from "../../utils/notification";
import { NavLink } from "react-router-dom";
import useQuery from "../../utils/useQuery/index";

const Login = () => {
  const formListStyle = {
    display: "flex",
    justifyContent: "space-between",
  };
  const [lastPath] = useQuery();
  const intl = useIntl()
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginState = useSelector((s) => s.login);
  // const getPersonalDataState = useSelector((s) => s.getPersonalData);
  const [Email, setEmail] = useState("");
  const [path, setPath] = useState(undefined);
  const [Password, setPassword] = useState("");

  const loginFunc = () => {
    dispatch(login({ Email, Password }));
    //const response = axios.post(`url`, {})
  };
  useEffect(() => {
    if (lastPath !== undefined) {
      setPath(lastPath[1]);
    }
  }, []);
  useEffect(() => {
    if (loginState.isSuccessful) {
      console.log(loginState);
      if (loginState.data.Name) {
        Notification(intl.formatMessage({id:'alert.loginSuccessful'}), "success", intl.formatMessage({id:'alert.loginWasSuccessful'}));
        if (path === undefined) {
          navigate("/register/personal");
        } else navigate(path);
        dispatch(getPersonalData());
        dispatch(loginCleanup());
      } else { //
        navigate(`/verify-otp?email=${encodeURIComponent(loginState.data.PersonEmail)}`);
      }
      
    } else if (loginState.error) {
      console.log(loginState.error);
      if (Array.isArray(loginState.error)) {
        if (loginState.error[0].msg === "Please verify your email address") {
          navigate(`/verify-email?email=${encodeURIComponent(loginState.error[0].value)}`);
          Notification(intl.formatMessage({id:'alert.loginFailed'}), "error", intl.formatMessage({id:'register.emailVerificationPrompt'}));
        } else Notification(
                intl.formatMessage({id:'alert.loginFailed'}), 
                "error", 
                loginState.error[0].msg
              );
      } else if (loginState.error.displayStringId) {
          Notification(
            intl.formatMessage({id:'alert.loginFailed'}), 
            "error", 
            intl.formatMessage({id:loginState.error.displayStringId})
            );
      } else Notification(
              intl.formatMessage({id:'alert.loginFailed'}), 
              "error", 
              typeof loginState.error === 'string' ? 
                loginState.error : "An unknown error occured. Please try again or seek assistance from a staff member"
              );
      dispatch(loginCleanup());
    }
  }, [loginState]);

  return (
    <div>
      <AuthLayout>
        <Form
          name="basic"
          layout="vertical"
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
          onFinish={loginFunc}
        >
          <Form.Item
            label={<FormattedMessage id="auth.email"/>}
            name="Email"
            rules={[
              {
                required: true,
                message: <FormattedMessage id="register.validation.emailIsRequired"/>,
              },
              {
                type: "email",
                message: <FormattedMessage id="register.validation.emailIsNotValid" />,
              },
            ]}
          >
            <Input value={Email} onChange={(e) => setEmail(e.target.value)} />
          </Form.Item>
          <Form.Item
            label={<FormattedMessage id="login.password"/>}
            name={"Password"}
            rules={[
              {
                required: true,
                message: <FormattedMessage id="register.validation.passwordIsRequired" />,
              },
            ]}
          >
            <Input.Password
              value={Password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </Form.Item>

          <div style={formListStyle}>
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>{<FormattedMessage id="login.rememberMe"/>}</Checkbox>
            </Form.Item>

            <NavLink to={"/forgot-password"}>{<FormattedMessage id="login.forgotPassword"/>}</NavLink>
          </div>

          <Form.Item>
            <Button
              className="auth-btn--custom-auth"
              type="primary"
              htmlType="submit"
              placeholder="Enter your password"
              loading={loginState.isLoading}
              disabled={
                (Email.trim().length < 5) | (Password.trim().length < 8)
                  ? true
                  : false
              }
              // onClick={loginFunc}
            >
              {<FormattedMessage id="auth.login"/>}
            </Button>
          </Form.Item>
          <div>
            <span>
            {<FormattedMessage id="login.dontHaveAccount"/>}{" "}
              <NavLink to={"/register"}>{<FormattedMessage id="auth.register"/>}.</NavLink>
            </span>
          </div>
        </Form>
      </AuthLayout>
    </div>
  );
};

export default Login;

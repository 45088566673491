import { FormattedMessage } from "react-intl";

const PhoneValidator = (rule, value, callback, type) => {
  if (typeof value !== "undefined") {
    let pattern = new RegExp(/^[0-9\b]+$/);

    if (value.phone && !pattern.test(value.phone)) {
      callback("Please enter only numbers.");
    } else if (
      (value.phone && value.phone.length < 9) ||
      (value.phone && value.phone.length > 11)
    ) {
      callback(`Please enter valid ${type}.`);
    } else if (value.phone === undefined || value.phone === null) {
      callback( <FormattedMessage id="register.validation.phoneNumberIsRequired" />);
    } else if (value.phone && value.phone.length < 1) {
      callback(<FormattedMessage id="register.validation.phoneNumberIsRequired" />);
    } else if (value.code === undefined) {
      value.code = "1";
    }
    callback();
  }
};

export default PhoneValidator;

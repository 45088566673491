import { FormattedMessage } from "react-intl";

/** Available races option */
export const raceList = [
  {sfVal : "White", displayStringId : "choices.race.white"},
  {sfVal : "Latin American", displayStringId : "choices.race.latinAmerican"},
  {sfVal : "African American", displayStringId : "choices.race.africanAmerican"},
  {sfVal : "Asian", displayStringId : "choices.race.asian"},
  {sfVal : "Native American", displayStringId : "choices.race.nativeAmerican"},
  {sfVal : "Pacific Islander", displayStringId : "choices.race.pacificIslander"},
  {sfVal : "Other", displayStringId : "choices.race.other"},
];

/** Available PGP option */
export const pgpList = [
  {sfVal : "He/Him/His", displayStringId : "choices.genderId.heHimHis"},
  {sfVal : "She/Her/Hers", displayStringId : "choices.genderId.sheHerHers"},
  {sfVal : "They/Them/Theirs", displayStringId : "choices.genderId.theyThemTheirs"},
];

/**
 * Application height selection list
 */
export const heightList = [
  "4'0",
  "4'1",
  "4'2",
  "4'3",
  "4'4",
  "4'5",
  "4'6",
  "4'7",
  "4'8",
  "4'9",
  "4'10",
  "4'11",
  "4'12",
  "5'0",
  "5'1",
  "5'2",
  "5'3",
  "5'4",
  "5'5",
  "5'6",
  "5'7",
  "5'8",
  "5'9",
  "5'10",
  "5'11",
  "5'12",
  "6'0",
  "6'1",
  "6'2",
  "6'3",
  "6'4",
];

/**
 * Application relationship list
 */
export const relationshipList = [
  {sfVal : "Father", displayStringId : "choices.relationship.father"},
  {sfVal : "Mother", displayStringId : "choices.relationship.mother"},
  {sfVal : "Son", displayStringId : "choices.relationship.son"},
  {sfVal : "Daughter", displayStringId : "choices.relationship.daughter"},
  {sfVal : "Spouse", displayStringId : "choices.relationship.spouse"},
  {sfVal : "Husband", displayStringId : "choices.relationship.husband"},
  {sfVal : "Wife", displayStringId : "choices.relationship.wife"},
  {sfVal : "Grandchild", displayStringId : "choices.relationship.grandchild"},
  {sfVal : "Grandparent", displayStringId : "choices.relationship.grandparent"},
  {sfVal : "Friend", displayStringId : "choices.relationship.friend"},
  {sfVal : "Aunt", displayStringId : "choices.relationship.aunt"},
  {sfVal : "Uncle", displayStringId : "choices.relationship.uncle"},
  {sfVal : "Boyfriend", displayStringId : "choices.relationship.boyfriend"},
  {sfVal : "Girlfriend", displayStringId : "choices.relationship.girlfriend"},
  {sfVal : "Roommate", displayStringId : "choices.relationship.roommate"},
  {sfVal : "Neighbor", displayStringId : "choices.relationship.neighbor"},
  {sfVal : "Family member", displayStringId : "choices.relationship.familyMember"},
  {sfVal : "Other", displayStringId : "choices.relationship.other"},
];

/**
 * How did you hear about us list
 */
export const aboutUsList = [
{"displayStringId":"choices.referrerType.employee","sfVal":"Employee"},
{"displayStringId":"choices.referrerType.flyer","sfVal":"Flyer"},
{"displayStringId":"choices.referrerType.friend","sfVal":"Friend"},
{"displayStringId":"choices.referrerType.online","sfVal":"Online"},
{"displayStringId":"choices.referrerType.recruiter","sfVal":"Recruiter"},
{"displayStringId":"choices.referrerType.school","sfVal":"School"},
{"displayStringId":"choices.referrerType.royalcareWebsite","sfVal":"RoyalCare Website"},
{"displayStringId":"choices.referrerType.socialMedia","sfVal":"Social Media"},
{"displayStringId":"choices.referrerType.other","sfVal":"Other"}
];

/**
 * desired shift list
 */
export const desiredShiftList = [
  {sfVal : "Hourly", displayStringId : "choices.desiredShift.hourly"},
  {sfVal : "Live-in", displayStringId : "choices.desiredShift.liveIn"},
  {sfVal : "Weekends Only", displayStringId : "choices.desiredShift.weekendsOnly"},
];

/**
 * work Availability list
 */
export const workAvailabilityList = [
  {sfVal : "Full time", displayStringId : "choices.workAvailableFor.fullTime"},
  {sfVal : "Part time", displayStringId : "choices.workAvailableFor.partTime"},
  {sfVal : "Shift", displayStringId : "choices.workAvailableFor.shift"},
  {sfVal : "Temporary", displayStringId : "choices.workAvailableFor.temporary"},
];

/**
 * days working elsewhere
 */
export const workingDays = [
  {sfVal : "Monday", displayStringId : "choices.days.monday"},
  {sfVal : "Tuesday", displayStringId : "choices.days.tuesday"},
  {sfVal : "Wednesday", displayStringId : "choices.days.wednesday"},
  {sfVal : "Thursday", displayStringId : "choices.days.thursday"},
  {sfVal : "Friday", displayStringId : "choices.days.friday"},
  {sfVal : "Saturday", displayStringId : "choices.days.saturday"},
  {sfVal : "Sunday", displayStringId : "choices.days.sunday"},
];

/**
 * time options
 */
export const timeOptions = [
  "12:00 AM",
  "12:30 AM",
  "1:00 AM",
  "1:30 AM",
  "2:00 AM",
  "2:30 AM",
  "3:00 AM",
  "3:30 AM",
  "4:00 AM",
  "4:30 AM",
  "5:00 AM",
  "5:30 AM",
  "6:00 AM",
  "6:30 AM",
  "7:00 AM",
  "7:30 AM",
  "8:00 AM",
  "8:30 AM",
  "9:00 AM",
  "9:30 AM",
  "10:00 AM",
  "10:30 AM",
  "11:00 AM",
  "11:30 AM",
  "12:00 PM",
  "12:30 PM",
  "1:00 PM",
  "1:30 PM",
  "2:00 PM",
  "2:30 PM",
  "3:00 PM",
  "3:30 PM",
  "4:00 PM",
  "4:30 PM",
  "5:00 PM",
  "5:30 PM",
  "6:00 PM",
  "6:30 PM",
  "7:00 PM",
  "7:30 PM",
  "8:00 PM",
  "8:30 PM",
  "9:00 PM",
  "9:30 PM",
  "10:00 PM",
  "10:30 PM",
  "11:00 PM",
  "11:30 PM",
];

/**
 * eye color options
 */
export const eyeColorOptions = [
  {sfVal : "Black", displayStringId : "choices.color.black"},
  {sfVal : "Blue", displayStringId : "choices.color.blue"},
  {sfVal : "Brown", displayStringId : "choices.color.brown"},
  {sfVal : "Gray", displayStringId : "choices.color.gray"},
  {sfVal : "Green", displayStringId : "choices.color.green"},
  {sfVal : "Orange", displayStringId : "choices.color.hazel"},
  {sfVal : "Pink", displayStringId : "choices.color.maroon"},
  {sfVal : "Green", displayStringId : "choices.color.multicolor"},
  {sfVal : "Orange", displayStringId : "choices.color.pink"},
  {sfVal : "Pink", displayStringId : "choices.color.other"},
];

/**
 * hair color options
 */
export const hairColorOptions = [
  {sfVal : "Bald", displayStringId : "choices.color.bald"},
  {sfVal : "Black", displayStringId : "choices.color.black"},
  {sfVal : "Blonde", displayStringId : "choices.color.blonde"},
  {sfVal : "Blue", displayStringId : "choices.color.blue"},
  {sfVal : "Brown", displayStringId : "choices.color.brown"},
  {sfVal : "Gray", displayStringId : "choices.color.gray"},
  {sfVal : "Green", displayStringId : "choices.color.green"},
  {sfVal : "Orange", displayStringId : "choices.color.orange"},
  {sfVal : "Pink", displayStringId : "choices.color.pink"},
  {sfVal : "Purple", displayStringId : "choices.color.purple"},
  {sfVal : "Red or Auburn", displayStringId : "choices.color.redOrAuburn"},
  {sfVal : "Sandy", displayStringId : "choices.color.sandy"},
  {sfVal : "White", displayStringId : "choices.color.white"},
  {sfVal : "Other", displayStringId : "choices.color.other"},
];

/**
 * marital status options
 */
export const maritalStatusOptions = [
  {sfVal : "Single", displayStringId : "choices.maritalStatus.single"},
  {sfVal : "Married", displayStringId : "choices.maritalStatus.married"},
  {sfVal : "Divorced", displayStringId : "choices.maritalStatus.divorced"},
  {sfVal : "Widowed", displayStringId : "choices.maritalStatus.widowed"},
];

/**
 * Application Type Specific Navigation
 */
export const navDict = {
    "HHA" : {
      'totalStepsCount' : 8,
      'label' :  <FormattedMessage id="menu.hhaHeader" />,
      'prompt' : <FormattedMessage id="menu.hhaDescription" />,
      'steps' : [
        '/register/personal',
        '/register/emergency',
        '/register/education',
        '/register/employment',
        '/register/language',
        '/register/test',
        '/register/caregiver',
        '/register/documents'
      ]

  },
    "CDPAP" : {
      'totalStepsCount' : 4,
      'label' : <FormattedMessage id="menu.cdpapHeader" />,
      'prompt' : <FormattedMessage id="menu.cdpapDescription" />,
      'steps' : [
        '/register/personal',
        '/register/emergency',
        '/register/consumer',
        '/register/documents'
      ]
    },
    "Candidate" : {
      'totalStepsCount' : 5,
      'label' : 'Candidate Profile',
      'prompt' : <FormattedMessage id="menu.cdpapDescription" />,
      'steps' : [
        '/register/personal',
        '/register/emergency',
        '/register/education',
        '/register/employment',
        '/register/documents'
      ]
    }
  };



export const GlobalState = {}; //TODO : clean this up

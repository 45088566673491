import { notification } from "antd";

const Notification = (message, type, description) => {
    
  notification.open({
    message: message,
    type: type,
    description: description,
    className: 'custom-class',
    style: {
      zIndex:1000
    },
  });
};

export default Notification;
